import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../environments";

@Injectable({providedIn: 'root'})
export class MailService {
  baseURL = environment.API_URL + "/v2/mail";

  constructor(private http: HttpClient) {}

  /**
   * Report a list of tournament about a similiar data error
   *
   * @param tournamentIDs List of IDs of the reported tournaments
   * @param error What is the current error
   * @param additionalInfo Additional text info
   * @returns Pair of response text (Usually OK) & status code
   */
  reportTournament(
    tournamentIDs: number[],
    error: string,
    additionalInfo: string = ""
  ): Observable<[string, number]> {
    return this.http
      .post(
        this.baseURL + "/tournament-error-report",
        {
          tournament_ids: tournamentIDs,
          error_type: error,
          additional: additionalInfo,
        },
        {
          observe: "response",
          responseType: "text",
        }
      )
      .pipe(map((e) => [e.body, e.status]));
  }
}
