import { HttpErrorResponse } from "@angular/common/http";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { take } from "rxjs/operators";
import { MailService } from "../../services/mail.service";

@Component({
  selector: "app-bug-report",
  templateUrl: "./bug-report.component.html",
  styleUrls: ["./bug-report.component.css"],
})
export class BugReportComponent implements OnInit {
  @Input() checkedTorneios: Array<boolean> | Map<string, boolean>;
  @Output() handleSnackBar = new EventEmitter<any>();

  modalRef: BsModalRef;
  snackbarMessage = "";
  showSnackbar = false;
  bugReport = {
    type: "Hora início",
    ids: null,
    text: "",
  };

  constructor(
    private mailService: MailService,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {}

  onClickSubmitBugReport() {
    this.bugReport.ids = [];
    const tournamentsToReportBug = this.mapToArray();

    tournamentsToReportBug.forEach((ok, id) => {
      if (ok) {
        this.bugReport.ids.push(id);
      }
    });

    if (this.bugReport.type != "Outro") {
      this.bugReport.text = "";
    }
    this.mailService
      .reportTournament(
        this.bugReport.ids,
        this.bugReport.type,
        this.bugReport.text
      )
      .pipe(take(1))
      .subscribe(
        (data) => {
          if (data[1] == 200) {
            this.showSnackMessage("Bug report sent successfully.");
          } else {
            this.showSnackMessage("Something went wrong, try again later.");
          }
        },
        (error: HttpErrorResponse) => {
          if (error.statusText == "Too Many Requests") {
            this.showSnackMessage(
              `${error.statusText}. Try again in a few minutes`
            );
          } else {
            this.showSnackMessage(`${error.statusText}`);
          }
        }
      );
  }

  hasSomeChecked(): boolean {
    const checkedTournaments = this.mapToArray()
    const onlyChecked = checkedTournaments.filter((tor) => tor);
    return onlyChecked.length > 0;
  }

  showSnackMessage(msg: string) {
    this.handleSnackBar.emit(msg);
  }

  openModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template, { backdrop: "static" });
  }

  private mapToArray() {
    if(Array.isArray(this.checkedTorneios)){
      return this.checkedTorneios || []
    }

    const arrayMap: Array<boolean> = [];

    Object.keys(this.checkedTorneios)
      .filter((key) => this.checkedTorneios[key])
      .map((key) => {
        arrayMap[key] = this.checkedTorneios[key];
      });

    return arrayMap || [];
  }
}
