import { Location } from "@angular/common";
import { Component, Input, OnInit, ElementRef, ViewChild, Output, EventEmitter, TemplateRef } from "@angular/core";
// Moment
import * as moment from "moment-timezone";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { NgxSpinnerService } from "ngx-spinner";
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Site } from "../../app/interfaces/sites";
import { Filter, User } from "../../app/interfaces/user";
import { LicenseService } from "../../app/services/license.service";
import { SiteService } from "../../app/services/site.service";
import { CurrencyService } from "../../app/services/currency.service";
import { UserService } from "../../app/services/user.service";
import { TranslateService } from '@ngx-translate/core';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { KeyValue } from "@angular/common";
import { BsDropdownDirective } from "ngx-bootstrap/dropdown";
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import Cookies from "../../app/utils/cookies";
import { Currency } from "../../app/utils/currency";
import { SubSink } from "subsink";
import { createDetachedFrame } from "../../app/utils/functions.utils";
import _, { floor } from 'lodash';
import { Options } from '@angular-slider/ngx-slider';
import { TierService } from "../../app/services/tier.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import {
  Subject, timer
} from "rxjs";
import { debounce } from "rxjs/operators";

@Component({
  selector: "filter-tournaments",
  templateUrl: "./filter-tournaments.component.html",
  styleUrls: ["./filter-tournaments.component.scss"],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ left: '-600px'}),
            animate('0.2s ease-out', 
                    style({ left: '0'}))
          ]
        ),
        transition(
          ':leave',
          [
            style({ left: '0'}),
            animate('0.2s ease-in', 
                    style({ left: '-600px'}))
          ]
        )
      ]
    ),
  ]
})
export class FilterTournamentsComponent implements OnInit {

  @Input() accentColor = 'light'
  @Input() totalTournaments = 0;
  @Input() showFilters = ['site', 'game', 'buy_in', 'speed', 'type', 'players', 'variant', 'state', 'prize_pool', 'init_stacks', 'date', 'time', 'end_time', 'name', 'order', 'onlyNew', 'scheduled', 'maxLate', 'field', 'duration', 'priority', 'onlyFast', 'favorite', 'currency', 'week_day']
  @Input() details;
  @Input() order;
  @Input() mode = "tournaments"
  @Input() isAdmin = false;
  @Input() showHeader = false;
  @Input() isLoading = false;
  @Input() dropdownListTiers = [];
  @Output() filterList = new EventEmitter<object>();
  @Output() clickedCollapse = new EventEmitter<boolean>();
  @Output() clickedAutoScheduler = new EventEmitter<void>();

  @ViewChild("filterDropdown") filterDropdown: BsDropdownDirective;
  @ViewChild("savedFilters") savedFilters: TemplateRef<any>;
  @ViewChild("autoSchedule") autoSchedule: TemplateRef<any>;

  filterSubject = new Subject<any>();

  sendFilterList(list: any) {
    var filtersToSend = {...list}

    // \/ IGNORE TEMPORARILY REMOVED FILTERS
    filtersToSend.category = null;
    // /\ IGNORE TEMPORARILY REMOVED FILTERS

    // --------------------------------------------------------
    // || IF ANY CHANGE IS MADE HERE ABOUT THE FILTER DATA   ||
    // || MODEL AND/OR CONVERSION, TALK TO THE BACKEND TEAM  ||
    // --------------------------------------------------------

    //ADJUST FILTERS BEFORE SENDING

    //FROM TIME
    if (list.from_time && list.from_time != "") {
      const original = new Date()
      original.setHours(list.from_time.split(':')[0])
      original.setMinutes(list.from_time.split(':')[1])
      original.setSeconds(0, 0)
      filtersToSend.from_time = Math.floor(original.getTime() / 1000)

    } else {
      filtersToSend.from_time = null
    }

    //TO TIME
    if (list.to_time && list.to_time != "") {
      const original = new Date()
      original.setHours(list.to_time.split(':')[0])
      original.setMinutes(list.to_time.split(':')[1])
      original.setSeconds(0, 0)
      filtersToSend.to_time = Math.floor(original.getTime() / 1000)
    } else {
      filtersToSend.to_time = null
    }

    //FROM END TIME
    if (list.from_end_time && list.from_end_time != "") {
      const original = new Date()
      original.setHours(list.from_end_time.split(':')[0])
      original.setMinutes(list.from_end_time.split(':')[1])
      original.setSeconds(0, 0)
      filtersToSend.from_end_time = Math.floor(original.getTime() / 1000)
    } else {
      filtersToSend.from_end_time = null
    }

    //TO END TIME
    if (list.to_end_time && list.to_end_time != "") {
      const original = new Date()
      original.setHours(list.to_end_time.split(':')[0])
      original.setMinutes(list.to_end_time.split(':')[1])
      original.setSeconds(0, 0)
      filtersToSend.to_end_time = Math.floor(original.getTime() / 1000)
    } else {
      filtersToSend.to_end_time = null
    }

    //FROM REGISTRATION TIME
    if (list.from_register_time && list.from_register_time != "") {
      const original = new Date()
      original.setHours(list.from_register_time.split(':')[0])
      original.setMinutes(list.from_register_time.split(':')[1])
      original.setSeconds(0, 0)
      filtersToSend.from_register_time = original.toISOString()

    } else {
      filtersToSend.from_register_time = null
    }

    //TO REGISTRATION TIME
    if (list.to_register_time && list.to_register_time != "") {
      const original = new Date()
      original.setHours(list.to_register_time.split(':')[0])
      original.setMinutes(list.to_register_time.split(':')[1])
      original.setSeconds(0, 0)
      filtersToSend.to_register_time = original.toISOString()
    } else {
      filtersToSend.to_register_time = null
    }

    //FROM DATE
    if (list.from_date) {
      const original = new Date(list.from_date)
      filtersToSend.from_date = new Date(original.toISOString().slice(0, -1))
    }

    //TO DATE
    if (list.to_date) {
      const original = new Date(list.to_date)
      filtersToSend.to_date = new Date(original.toISOString().slice(0, -1))
    }

    // FROM DATETIME
    if (list.from_datetime && list.from_datetime != "") {
      const original = new Date()
      original.setHours(list.from_datetime.split(':')[0])
      original.setMinutes(list.from_datetime.split(':')[1])
      original.setSeconds(0, 0)
      filtersToSend.from_datetime = original.toISOString()
    }

    // TO DATETIME
    if (list.to_datetime && list.to_datetime != "") {
      const original = new Date()
      original.setHours(list.to_datetime.split(':')[0])
      original.setMinutes(list.to_datetime.split(':')[1])
      original.setSeconds(0, 0)
      filtersToSend.to_datetime = original.toISOString()
    }

    //ARRAYS
    filtersToSend.game2 = filtersToSend.game2 ? filtersToSend.game2.length ? filtersToSend.game2.map(e => { return e.item_id } ) : null : null
    filtersToSend.site = filtersToSend.site ? filtersToSend.site.length ? filtersToSend.site.map(e => { return e.item_id } ) : null : null
    filtersToSend.players = filtersToSend.players ? filtersToSend.players.length ? filtersToSend.players.map(e => { return e.item_text } ) : null : null
    filtersToSend.speed = filtersToSend.speed ? filtersToSend.speed.length ? filtersToSend.speed.map(e => { return e.item_text } ) : null : null
    filtersToSend.state = filtersToSend.state ? filtersToSend.state.length ? filtersToSend.state.map(e => { return e.item_text } ) : null : null
    filtersToSend.type = filtersToSend.type ? filtersToSend.type.length ? filtersToSend.type.map(e => { return e.item_text } ) : null : null
    filtersToSend.variant = filtersToSend.variant ? filtersToSend.variant.length ? filtersToSend.variant.map(e => { return e.item_text } ) : null : null
    filtersToSend.priority = filtersToSend.priority ? filtersToSend.priority.length ? filtersToSend.priority.map(e => { return e.item_id }) : null : null
    filtersToSend.category = filtersToSend.category ? filtersToSend.category.length ? filtersToSend.category.map(e => { return e.item_id }) : null : null
    filtersToSend.currency = filtersToSend.currency ? filtersToSend.currency.length ? filtersToSend.currency.map(e => { return e.item_id } ) : null : null
    filtersToSend.week_day = filtersToSend.week_day ? filtersToSend.week_day.length ? filtersToSend.week_day.map(e => { return e.item_id } ) : null : null

    // GAME
    if(filtersToSend.game2 == null) {
      filtersToSend.game2 = [1, 2, 3, 4, 5];
    }

    // BUY IN
    if (list.buy_in_min && filtersToSend.currency && filtersToSend.currency.length == 1) {
      filtersToSend.buy_in_min = Number(list.buy_in_min)
    }
    if (list.buy_in_max && filtersToSend.currency && filtersToSend.currency.length == 1) {
      filtersToSend.buy_in_max = Number(list.buy_in_max)
    }

    // PRIZE POOL
    if (list.prize_pool_min && filtersToSend.currency && filtersToSend.currency.length == 1) {
      filtersToSend.prize_pool_min = Number(list.prize_pool_min)
    }

    if (list.prize_pool_max && filtersToSend.currency && filtersToSend.currency.length == 1) {
      filtersToSend.prize_pool_max = Number(list.prize_pool_max)
    }

    // REBALANCE
    if(window.location.pathname != "/rebalance") {
      filtersToSend.auto_only = null;
      filtersToSend.manual_only = null;
      filtersToSend.deleted_filter_only = null;
      filtersToSend.unchecked_filter_only = null;
    }

    // MANAGE FILTERS
    if(window.location.pathname == "/manage-filters") {
      filtersToSend.tier = this.tierSearch;
    }

    // --------------------------------------------------------
    // || IF ANY CHANGE IS MADE HERE ABOUT THE FILTER DATA   ||
    // || MODEL AND/OR CONVERSION, TALK TO THE BACKEND TEAM  ||
    // --------------------------------------------------------

    this.filterList.emit(filtersToSend)
  }

  onClickAutoScheduler() {
    this.clickedAutoScheduler.emit();
  }

  @ViewChild('from_time') elementFromTime: ElementRef;
  @ViewChild('to_time') elementToTime: ElementRef;

  public openedSaved = false;
  public openedAuto = false;
  public loadingFilters = false;

  private subs = new SubSink();

  public isDarkTheme = Cookies.get('theme-ui') ? JSON.parse(Cookies.get('theme-ui')) : true;

  private readonly emptyFilter = {
    id: null,
    name: "New Filter",
    site: null,
    game2: null,
    buy_in_min: null,
    buy_in_max: null,
    speed: null,
    type: null,
    players: null,
    variant: null,
    state: null,
    prize_pool_min: null,
    prize_pool_max: null,
    init_stacks_min: null,
    init_stacks_max: null,
    from_date: null,
    to_date: null,
    from_time: null,
    to_time: null,
    search: "",
    order: window.location.pathname == "/rebalance" ? { start: 1 } : { state: 1 },
    onlyNew: false,
    scheduled: null,
    maxLate: false,
    field: null,
    from_duration: null,
    priority: null,
    to_duration: null,
    limit: null,
    include_closed: false,
    order_start: window.location.pathname == "/rebalance" ? 1 : 0,
    order_site: 0,
    order_game: 0,
    order_buy_in: 0,
    order_name: 0,
    order_prize_pool: 0,
    order_speed: 0,
    order_state: window.location.pathname == "/rebalance" ? 0 : 1,
    order_field: 0,
    order_duration: 0,
    order_register_time: 0,
    order_av_ability: 0,
    onlyFast: false,
    from_register_time: null,
    to_register_time: null,
    timezone: new Date().getTimezoneOffset(),
    exclude: "",
    flagged_only: null,
    auto_scheduled_only: null,
    show_exception: false,
    category: null,
    av_ability: null,
    only_favorites: false,
    currency: null,
    min_participants: null,
    week_day: null,
    from_end_time: null,
    to_end_time: null,
    order_grindstate: 0,
    order_priority: 0,
    auto_only: true,
    manual_only: false,
    deleted_filter_only: true,
    unchecked_filter_only: false,
  };

  public rangeFilters = {
    participants: [2, 1000],
    duration: [0, 24],
    prize_pool: [2, 1000000],
    ability: 50
  };

  public optionsParticipants: Options = {
    floor: 2,
    ceil: 1000,
    logScale: true,
    ticksArray: [2, 100, 500, 1000],
    getLegend: (value: number): string => {
      return value < 1000 ? value.toString() : '*';
    }
  }

  public optionsPrizePool: Options = {
    floor: 2,
    ceil: 1000000,
    logScale: true,
    ticksArray: [1, 100, 1000, 10000, 100000, 1000000],
    getLegend: (value: number): string => {
      return value < 1000000 ? value.toString() : '*';
    }
  }

  public currentUser: User = {
    id: null,
    team: "",
    role: "",
    schedules: [],
    profile: {
      sharkscope_nicks: [],
      grind_mode: {}
    },
  };

  public autoScheduleSelected: number = 0;
  public isEnableAutoSchedule: boolean = false;
  public isMassAutoSchedule: boolean = false;

  public isMaster = false;

  public extraFiltersVisible = false;

  public selectedFilterIndex: number = 0;

  public checkedFilters: { [key: number]: boolean } = {};

  public downloadJsonHref: SafeUrl;

  public filters: { [key: number]: Filter } = { 0: { ...this.emptyFilter, ...{ include_closed: window.location.pathname == "/rebalance" ? true : false} } };
  public filterIsAutoSchedule: { [key: number]: boolean } = { 0: false };
  public filterIsDeleted: { [key: number]: boolean } = { 0: false };
  public filterOwnerIsMaster: { [key: number]: boolean } = { 0: false };
  public filterMaster: { [key: number]: number } = {};
  public filterPriority: { [key: number]: number } = {};
  public filtersByMaster: { [key: number]: object } = {};
  public showFilterOptions = false;

  public currencyRates: { [key: string]: number } = {};
  public isoToSym = Currency.list

  public selectedCurrency = "$"

  // Snackbar Message
  public showSnackbar = false;
  public snackbarMessage = "";

  // Filters dropdown options
  public dropdownListSites = [];
  public dropdownListGame = [];
  public dropdownListGameAdmin = [];
  public dropdownListSpeed = [];
  public dropdownListType = [];
  public dropdownListPlayers = [];
  public dropdownListVariant = [];
  public dropdownListState = [];
  public dropdownListScheduled = [];
  public dropdownListAutoScheduled = [];
  public dropdownListAutoScheduledSearch = [];
  public dropdownListFlagged = [];
  public dropdownListPriority = [];
  public dropdownListCategory = [];
  public dropdownListCurrency = [];
  public dropdownListDay = [];
  public dropdownListMasters = [];

  public bloquedFunctions = false;
  public today = new Date();

  public myTimeZone = moment.tz.guess();
  public filterFromDate: string;
  public filterToDate: string;
  public filterScheduled = [];
  public filterAutoScheduled = [];
  public filterFlagged = [];
  public filterName = "";

  private waitForNextKey: any;

  public currentPag = 1;

  private sites: Site[];
  private sitesMap: { [key: number]: Site } = {};

  public priority: string = "1";

  public dropdownSettings: IDropdownSettings = {};
  public dropdownSettingsSingle: IDropdownSettings = {};
  public dropdownSettingsTeam: IDropdownSettings = {};

  public dateFromModel: IMyDateModel = null;
  public dateToModel: IMyDateModel = null;

  public searchModel = []
  public excludeModel = []

  public loadingAutoScheduler = false;
  public lockedAutoScheduler = false;

  public filterSearch = "";
  public masterSearch = [];
  public tierSearch = [];
  public autoScheduleSearch = [{ item_id: true, item_text: this.translateService.instant("auto_scheduled") }, { item_id: false, item_text: this.translateService.instant("not_auto_scheduled") }];

  public dateFromOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: this.translateService.currentLang == 'en-US' ? 'mm/dd/yyyy' : 'dd/mm/yyyy',
    disableSince: {year: 0, month: 0, day: 0},
    disableUntil: {year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() - 1}
  };

  public dateToOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: this.translateService.currentLang == 'en-US' ? 'mm/dd/yyyy' : 'dd/mm/yyyy',
    disableUntil: {year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() - 1}
  };

  public datePastOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: this.translateService.currentLang == 'en-US' ? 'mm/dd/yyyy' : 'dd/mm/yyyy'
  };

  constructor(
    private userService: UserService,
    private licenseService: LicenseService,
    private siteService: SiteService,
    private spinner: NgxSpinnerService,
    private location: Location,
    private translateService: TranslateService,
    private currencyService: CurrencyService,
    private sanitizer: DomSanitizer,
    private tierService: TierService,
    private modalService: BsModalService
  ) {}

  onDateChange(event: IMyDateModel, input: string): void {
    let newDate = event.singleDate.jsDate;
    if(newDate){
      const offset = newDate.getTimezoneOffset()
      newDate = new Date(newDate.getTime() - (offset*60*1000))
      input == 'from' ? this.filterFromDate = newDate.toISOString().split('T')[0] : this.filterToDate = newDate.toISOString().split('T')[0]
      let copy: IAngularMyDpOptions = this.getCopyOfOptions(input == 'from' ? 'to' : 'from');
      if(input == 'from'){
        copy.disableUntil = {year: newDate.getFullYear(),
          month: newDate.getMonth() + 1,
          day: newDate.getDate()};
        this.dateToOptions = copy;
      } else {
        copy.disableSince = {year: newDate.getFullYear(),
          month: newDate.getMonth() + 1,
          day: newDate.getDate()};
        this.dateFromOptions = copy;
      }
      this.onClickSubmitFilters()
    } else {
      input == 'from' ? this.filterFromDate = null : this.filterToDate = null
      this.resetDateFromUntil(input)
      this.onClickSubmitFilters()
    }
  }

  resetDateFromUntil(input: string){
    let copy: IAngularMyDpOptions = this.getCopyOfOptions(input == 'from' ? 'to' : 'from');
    if(input == 'from'){
      copy.disableUntil = {year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() - 1};
      this.dateToOptions = copy;
    } else {
      copy.disableSince = {year: 0, month: 0, day: 0};
      this.dateFromOptions = copy;
    }
  }

  getCopyOfOptions(input: string): IAngularMyDpOptions {
    return input == 'from' ? JSON.parse(JSON.stringify(this.dateFromOptions)) : JSON.parse(JSON.stringify(this.dateToOptions))
  }

  onClickSubmitFilters(order?: object, fromDeleteFilter?: boolean, searchAfter: boolean = true, withDebouncer: boolean = true) {
    
    if(this.searchModel){
      this.filters[this.selectedFilterIndex].search = this.searchModel.map((e) => {
        return e.label
      }).join('|')
    }

    if(this.excludeModel){
      this.filters[this.selectedFilterIndex].exclude = this.excludeModel.map((e) => {
        return e.label
      }).join('|')
    }

    if (this.filterFromDate) {
      this.filters[this.selectedFilterIndex].from_date = moment(
        this.filterFromDate
      ).format();
    } else {
      this.filters[this.selectedFilterIndex].from_date = null;
    }

    if (this.filterToDate) {
      this.filters[this.selectedFilterIndex].to_date = moment(
        this.filterToDate
      ).format();
    } else {
      this.filters[this.selectedFilterIndex].to_date = null;
    }

    if (this.filterScheduled[0]) {
      this.filters[this.selectedFilterIndex].scheduled = this.filterScheduled[0].item_id
    } else {
      this.filters[this.selectedFilterIndex].scheduled = null;
    }

    if (this.filterFlagged[0]) {
      this.filters[this.selectedFilterIndex].flagged_only = this.filterFlagged[0].item_id
    } else {
      this.filters[this.selectedFilterIndex].flagged_only = null;
    }

    if (this.filterAutoScheduled[0]) {
      this.filters[this.selectedFilterIndex].auto_scheduled_only = this.filterAutoScheduled[0].item_id
    } else {
      this.filters[this.selectedFilterIndex].auto_scheduled_only = null;
    }

    if (this.filters[this.selectedFilterIndex].currency && this.filters[this.selectedFilterIndex].currency.length == 1) {
      this.selectedCurrency = this.filters[this.selectedFilterIndex].currency[0]["item_text"].split('-')[0]
    } else {
      this.selectedCurrency = "$";
    }

    const orderf = this.filters[this.selectedFilterIndex].order

    this.filters[this.selectedFilterIndex].order = orderf || {
      state: 1,
    };

    const prize_pool_max = this.filters[this.selectedFilterIndex].prize_pool_max
    const prize_pool_min = this.filters[this.selectedFilterIndex].prize_pool_min

    const buy_in_max = this.filters[this.selectedFilterIndex].buy_in_max
    const buy_in_min = this.filters[this.selectedFilterIndex].buy_in_min

    const field_min = this.filters[this.selectedFilterIndex].min_participants
    const field_max = this.filters[this.selectedFilterIndex].field

    buy_in_max ? parseInt(buy_in_max) < parseInt(buy_in_min) ? this.filters[this.selectedFilterIndex].buy_in_max = this.filters[this.selectedFilterIndex].buy_in_min : null : null

    if (this.filters[this.selectedFilterIndex].field != null && this.filters[this.selectedFilterIndex].field <= 0) {
      this.filters[this.selectedFilterIndex].field = Math.abs(this.filters[this.selectedFilterIndex].field)
    }

    if (this.filters[this.selectedFilterIndex].min_participants != null && this.filters[this.selectedFilterIndex].min_participants <= 0) {
      this.filters[this.selectedFilterIndex].min_participants = Math.abs(this.filters[this.selectedFilterIndex].min_participants)
    }

    field_max && field_min ? Number(field_max) < Number(field_min) ? this.filters[this.selectedFilterIndex].field = this.filters[this.selectedFilterIndex].min_participants : null : null

    if (this.filters[this.selectedFilterIndex].prize_pool_min != null && (Number(this.filters[this.selectedFilterIndex].prize_pool_min) <= 0)) {
      this.filters[this.selectedFilterIndex].prize_pool_min = Math.abs(this.filters[this.selectedFilterIndex].prize_pool_min)
    }

    if (this.filters[this.selectedFilterIndex].prize_pool_max != null && (Number(this.filters[this.selectedFilterIndex].prize_pool_max) <= 0)) {
      this.filters[this.selectedFilterIndex].prize_pool_max = Math.abs(this.filters[this.selectedFilterIndex].prize_pool_max)
    }

    prize_pool_max && prize_pool_min ? Number(prize_pool_max) < Number(prize_pool_min) ? this.filters[this.selectedFilterIndex].prize_pool_max = this.filters[this.selectedFilterIndex].prize_pool_min : null : null

    if (this.bloquedFunctions) {
      let buyInMax = parseFloat(
        this.filters[this.selectedFilterIndex].buy_in_max
      );
      this.filters[this.selectedFilterIndex].buy_in_max = (
        buyInMax > 5 ? 5 : buyInMax
      ).toString();
    }

    if (this.filters[this.selectedFilterIndex].to_end_time && this.filters[this.selectedFilterIndex].from_time) {
      this.filters[this.selectedFilterIndex].from_end_time = this.filters[this.selectedFilterIndex].from_time
    }
    
    if(searchAfter && withDebouncer) {
      this.filterSubject.next(this.filters[this.selectedFilterIndex]);
    } else if(searchAfter && !withDebouncer) {
      this.sendFilterList(this.filters[this.selectedFilterIndex]);
    }
  }

  onKeyupSearch() {
    clearTimeout(this.waitForNextKey);
    this.waitForNextKey = setTimeout(() => {
      this.onClickSubmitFilters();
    }, 2000);
  }

  reset() {
    this.selectedFilterIndex = 0;
    this.filterName = "";
    this.filterFromDate = null;
    this.filterToDate = null;
    this.dateToModel = null;
    this.filterScheduled = [];
    this.filterAutoScheduled = [];
    this.filterFlagged = [];
    this.dateFromModel = null;
    this.searchModel = [];
    this.excludeModel = [];
    this.resetDateFromUntil('from')
    this.resetDateFromUntil('to')
    if(this.extraFiltersVisible){
      this.elementFromTime.nativeElement.value = '';
      this.elementToTime.nativeElement.value = '';
    }
    this.filters[0] = {...this.emptyFilter};
    this.filters[0].auto_scheduled_only = null;
    this.filters[0].include_closed = false;
    this.filters[0].auto_only = true;
    this.filters[0].manual_only = false;
    this.filters[0].deleted_filter_only = false;
    this.filters[0].unchecked_filter_only = false;
    this.selectedCurrency = "$"
    this.onClickSubmitFilters(null, false, true, false);
  }

  async onClickSaveFilter() {
    try {
      this.spinner.show();
      const index = this.selectedFilterIndex;
      const filterData = { ...this.filters[index] };
      if(filterData.name != this.filterName) {
        filterData.id = 0;
      }
      filterData.name = this.filterName;
      filterData.timezone = -new Date().getTimezoneOffset();
      const data = await this.userService.updateUserFilter(filterData.id, {
        data: filterData,
        auto_schedule: this.filterIsAutoSchedule[filterData.id],
        priority: Number(this.filterPriority[filterData.id])
      }).toPromise();
      filterData.id = data.id;
      this.getFilters(data.id);
      this.showSnackMessage("Filter Updated !");
    } finally {
      this.spinner.hide();
    }
  }

  async getCurrentUser(i: number) {
    this.currentUser = await this.userService.getCurrentUser().toPromise();
    const role = await this.userService.getRole().toPromise()
    this.isMaster = role.master;
    const license = await this.licenseService
    .getLicense()
    .then((e) => e.license.toLowerCase());
    this.bloquedFunctions = !role.ta && !role.sa && license == 'free';
  }

  async openSavedFilters() {
    this.loadingFilters = true;
    this.toggleModal(this.savedFilters, 'modal-xl');
    await this.getFilters(this.selectedFilterIndex);
    this.openedSaved = true;
    this.loadingFilters = false;
  }

  async openAutoSchedule() {
    this.loadingFilters = true;
    this.toggleModal(this.autoSchedule, 'modal-xl');
    await this.getFilters(this.selectedFilterIndex);
    this.openedAuto = true;
    this.loadingFilters = false;
   }

  async getFilters(resetIndex: number = 0) {
    var filterList = [];
    this.showFilterOptions = false;
    if(this.isMaster) {
      filterList = await this.userService.getFiltersFromTier(true).toPromise();
    } else {
      filterList = await this.userService.getFilters().toPromise();
    }
    this.filters = { 0: { ...this.emptyFilter, ...{ include_closed: window.location.pathname == "/rebalance" ? true : false} } };
    filterList.forEach(filter => {
      const data = filter.data;
      data.id = filter.id;
      if (filter.user_id == null) {
        filter.user_id = this.currentUser.id
      }
      if (filter.is_master == null || filter.user_id == this.currentUser.id) {
        this.filterOwnerIsMaster[filter.id] = true;
      } else {
        this.filterOwnerIsMaster[filter.id] = filter.is_master;
        if(filter.is_master) {
          this.showFilterOptions = true;
        }
      }
      this.filterPriority[filter.id] = filter.priority;
      this.filters[filter.id] = data;
      this.filterIsAutoSchedule[filter.id] = filter.auto_schedule;
      this.filterIsDeleted[filter.id] = filter.deleted;
      this.checkedFilters[filter.id] = false;
      this.filterMaster[filter.id] = filter.user_id;
      if(this.filtersByMaster[filter.user_id]) {
        this.filtersByMaster[filter.user_id]["filters"].push(filter.id)
      } else if (filter.is_master) {
        this.filtersByMaster[filter.user_id] = {name: filter.user_name, filters: [filter.id]}
        this.dropdownListMasters.push({ item_id: filter.user_id, item_text: filter.user_name })
        if(Number(filter.user_id) == Number(this.currentUser.id)) {
          this.masterSearch = [{ item_id: filter.user_id, item_text: filter.user_name }]
        }
      }
    })
    this.selectedFilterIndex = resetIndex;
  }

  showFilterFromMaster(id: number) {
    if (!this.isMaster) {
      return true;
    }
    var found = false;
    this.masterSearch.map((e) => {
      const filters = this.filtersByMaster[e.item_id]['filters'];
      if(filters.includes(Number(id))) {
        this.autoScheduleSearch.map((x) => {
          if(x.item_id == this.filterIsAutoSchedule[id]) {
            found = true;
          }
        })
      }
    })
    return found;
  }

  checkFilter(id, check){
    this.checkedFilters[id] = check;
    this.prepareFiltersJson();
  }

  isAllFiltersChecked() {
    for (const [key, value] of Object.entries(this.filters)) {
      if((this.filterSearch == '' || ((value.name).toUpperCase()).includes(this.filterSearch.toUpperCase())) && !this.filterIsDeleted[key] && !this.checkedFilters[key]) {
        return false;
      }
    }
    return true;
  }

  checkAllFilters(check) {
    for (const [key, value] of Object.entries(this.filters)) {
      if ((this.filterSearch == '' || ((value.name).toUpperCase()).includes(this.filterSearch.toUpperCase())) && !this.filterIsDeleted[key] && value.id > 0) {
        this.checkedFilters[key] = check;
      } else {
        this.checkedFilters[key] = false;
      }
    }
    this.prepareFiltersJson();
  }

  isFilterCheckedEmpty() {
    for (const [key, value] of Object.entries(this.filters)) {
      if((this.filterSearch == '' || ((value.name).toUpperCase()).includes(this.filterSearch.toUpperCase())) && this.checkedFilters[key]){
        return false;
      }
    }
    return true;
  }

  prepareFiltersJson() {

    var array = []

    for (const [key, value] of Object.entries(this.filters)) {
      if((this.filterSearch == '' || ((value.name).toUpperCase()).includes(this.filterSearch.toUpperCase())) && this.checkedFilters[key] && !this.filterIsDeleted[key] && value.id > 0){
        array.push(value);
      }
    }

    if(array.length == 0) {

      this.downloadJsonHref = "";

    } else {

      var theJSON = JSON.stringify(array);
      var uri = this.sanitizer.bypassSecurityTrustUrl("data:text/json;charset=UTF-8," + encodeURIComponent(theJSON));
      console.log(uri)
      this.downloadJsonHref = uri;

    }

  }

  async importFiltersJson(target: HTMLInputElement) {
    var selectedFile = target.files[0];
    const fileReader: any = new FileReader();
    await fileReader.readAsText(selectedFile, "UTF-8");
    fileReader.onload = async () => {
      var result = JSON.parse(fileReader.result);
      this.spinner.show();
      for (const filter of result) {
        if(filter.id != null) {
          filter.id = 0;
          for (const [key, value] of Object.entries(this.filters)) {
            if(filter.name == value.name && !this.filterIsDeleted[key]){
              filter.name = this.translateService.instant("copy_of") +  " " + filter.name
            }
          }
          await this.userService.updateUserFilter(0, {data: filter, auto_schedule: false, priority: 0}).toPromise();
        }
      }
      this.getFilters();
      this.spinner.hide();
    }
    fileReader.onerror = (error) => {
      console.log(error);
    }
  }

  get availableFilters() {
    return Object.keys(this.filters).length > 1;
  }

  asIsOrder(a: KeyValue<number, any>, b: KeyValue<number, any>) {
    return a.value.name.toLowerCase() > b.value.name.toLowerCase() ? 1 : a.value.name.toLowerCase() === b.value.name.toLowerCase() ? 0 : -1;
  }

  asIsOrderFilters(a: KeyValue<string, any>, b: KeyValue<string, any>) {
    var orderedFilters = [
      "search", "site", "buy_in_min", "buy_in_max", "from_time", "to_time", "prize_pool_min", "prize_pool_max", "speed", "exclude", "variant", "min_participants", "field", "players", "av_ability", "from_date", "to_date", "week_day", "from_end_time", "to_end_time", "maxLate", "include_closed", "scheduled", "currency", "priority", "only_favorites", "show_exception", "from_register_time", "to_register_time", "state", "auto_scheduled_only", "flagged_only", "category", "from_duration", "game", "id", "init_stacks_max", "init_stacks_min", "limit", "name", "onlyFast", "onlyNew", "order", "order_av_ability", "order_buy_in", "order_duration", "order_field", "order_game", "order_grindstate", "order_name", "order_prize_pool", "order_priority", "order_register_time", "order_site", "order_speed", "order_start", "order_state", "timezone", "to_duration", "type"
    ];

    return orderedFilters.indexOf(a.key) > orderedFilters.indexOf(b.key) ? 1 : -1;
  }

  async onClickSavedFilter(i: number) {
    this.selectedFilterIndex = i;
    this.filterName = this.filters[i]?.name || '';

    const search = (this.filters[i]?.search || '').trim();
    if(search.length === 0) {
      this.searchModel = []
    } else {
      this.searchModel = search.split('|').filter(e => e.length > 0).map(e => ({ label: e }))
    }

    const exclude = (this.filters[i]?.exclude || '').trim();
    if (exclude.length === 0) {
      this.excludeModel = []
    } else {
      this.excludeModel = exclude.split('|').filter(e => e.length > 0).map(e => ({ label: e }))
    }

    if (this.filters[i].currency && this.filters[i].currency.length == 1) {
      this.selectedCurrency = this.filters[i].currency[0]["item_text"].split('-')[0]
    } else {
      this.selectedCurrency = "$";
    }

    if (this.filters[i]?.scheduled != undefined && this.filters[i]?.scheduled != null) {
      const scheduledText = this.filters[i]?.scheduled ? this.translateService.instant("scheduled") : this.translateService.instant("not_scheduled")
      this.filterScheduled = [{ item_id: this.filters[i]?.scheduled, item_text: scheduledText }]
    } else {
      this.filterScheduled = [];
    }

    if (this.filters[i]?.auto_scheduled_only != undefined && this.filters[i]?.auto_scheduled_only != null) {
      const autoScheduledText = this.filters[i]?.auto_scheduled_only ? this.translateService.instant("auto_scheduled") : this.translateService.instant("manually_scheduled")
      this.filterAutoScheduled = [{ item_id: this.filters[i]?.auto_scheduled_only, item_text: autoScheduledText }]
    } else {
      this.filterAutoScheduled = [];
    }

    if(this.filters[i]?.flagged_only != undefined && this.filters[i]?.flagged_only != null) {
      const flaggedText = this.filters[i]?.flagged_only ? this.translateService.instant("flagged") : this.translateService.instant("not_flagged")
      this.filterFlagged = [{ item_id: this.filters[i]?.flagged_only, item_text: flaggedText }]
    } else {
      this.filterFlagged = [];
    }

    const today = new Date();

    if(this.filters[i]?.from_date) {
      const fromDate = new Date(this.filters[i].from_date)
      if(today < fromDate) {
        this.filters[i].from_date = null;
      }
    }

    if(this.filters[i]?.to_date) {
      const toDate = new Date(this.filters[i].to_date)
      if(today > toDate) {
        this.filters[i].to_date = null;
      }
    }

    this.onClickSubmitFilters(null, false, true, false);
  }

  isAllAutoSchedule() {
    var isTrue = true;
    for (const [key, value] of Object.entries(this.filterIsAutoSchedule)) {
      const data = this.filters[key];

      if((this.filterSearch == '' || ((data.name).toUpperCase()).includes(this.filterSearch.toUpperCase())) && Number(key) != 0 && !this.filterIsDeleted[key] && this.filterMaster[key] == this.currentUser.id && !value) {
        isTrue = false;
      }
    }
    return isTrue;
  }

  async onClickOpenAutoSchedule(id: number, enable: boolean, isMass: boolean = false) {

    if(isMass) {
      this.isMassAutoSchedule = true;
      this.isEnableAutoSchedule = enable;
    } else {
      this.isEnableAutoSchedule = enable;
      this.autoScheduleSelected = id;
      this.isMassAutoSchedule = false;
    }

  }

  async onClickChangeAutoSchedule() {
    try {
      this.spinner.show();

      if(this.isMassAutoSchedule) {
        for (const [key, value] of Object.entries(this.filterIsAutoSchedule)) {
          if(Number(key) != 0 && this.filterMaster[key] == this.currentUser.id && !this.filterIsDeleted[key]) {

            const data = this.filters[key];

            if((this.filterSearch == '' || ((data.name).toUpperCase()).includes(this.filterSearch.toUpperCase()))) {
              this.filterIsAutoSchedule[key] = this.isEnableAutoSchedule;

              data.timezone = -new Date().getTimezoneOffset();

              await this.userService.updateUserFilter(data.id, {
                data,
                auto_schedule: this.isEnableAutoSchedule,
                priority: Number(this.filterPriority[data.id])
              }).toPromise();
            }
          }
        }

      } else {

        this.filterIsAutoSchedule[this.autoScheduleSelected] = this.isEnableAutoSchedule;

        const data = this.filters[this.autoScheduleSelected];

        data.timezone = -new Date().getTimezoneOffset();

        await this.userService.updateUserFilter(data.id, {
          data,
          auto_schedule: this.isEnableAutoSchedule,
          priority: Number(this.filterPriority[data.id])
        }).toPromise();

      }
    } finally {
      this.spinner.hide();
    }
  }

  getPriorityText(priority) {
    const value = Number(priority);
    switch (value) {
      case 0:
        return "N/A";
      case 1:
        return "1 - " + this.translateService.instant('VERY_LOW');
      case 2:
      return "2 - " + this.translateService.instant('VERY_LOW');
      case 3:
      return "3 - " + this.translateService.instant('LOW');
      case 4:
      return "4 - " + this.translateService.instant('LOW');
      case 5:
      return "5 - " + this.translateService.instant('MEDIUM');
      case 6:
      return "6 - " + this.translateService.instant('MEDIUM');
      case 7:
      return "7 - " + this.translateService.instant('MEDIUM');
      case 8:
      return "8 - " + this.translateService.instant('HIGH');
      case 9:
      return "9 - " + this.translateService.instant('HIGH');
      case 10:
      return "10 - " + this.translateService.instant('HIGH');
    }
  }

  async onClickChangePriority(id) {
    try {
      this.spinner.show();

      const data = this.filters[id];

      data.timezone = -new Date().getTimezoneOffset();

      await this.userService.updateUserFilter(data.id, {
        data,
        auto_schedule: this.filterIsAutoSchedule[data.id],
        priority: Number(this.filterPriority[data.id])
      }).toPromise();

    } finally {
      this.spinner.hide();
    }
  }

  showSnackMessage(msg: string) {
    this.snackbarMessage = msg;
    this.showSnackbar = true;
    setTimeout(() => {
      this.showSnackbar = false;
    }, 3000);
  }

  async onClickConfirmDeleteFilter() {

    try {
      this.spinner.show();
      for (const [key, value] of Object.entries(this.filters)) {
        if((this.filterSearch == '' || ((value.name).toUpperCase()).includes(this.filterSearch.toUpperCase())) && this.checkedFilters[key] && Number(key) != 0 && this.filterMaster[key] == this.currentUser.id){
          await this.userService.deleteUserFilter(Number(key)).toPromise();
          this.onClickSubmitFilters(undefined, true);
        }
      }
      this.getFilters();
      this.spinner.hide();
    } catch (e) {
      this.spinner.hide();
    }

  }

  async getSites() {
    this.subs.sink = this.siteService.getSitesHelpers(this.isAdmin).subscribe((data) => {
      this.sites = data.sites;
      this.sitesMap = data.sitesMap;
      let ddSites = data.sites.map((element) => {
        return { item_id: element.id, item_text: element.name };
      });
      this.dropdownListSites = ddSites;
    });
  }

  async getCurrencies(){
    const data = await this.currencyService.getCurrencies().toPromise()
    this.currencyRates = data.rates;
  }

  toggleCollapse(): void {
    this.extraFiltersVisible = !this.extraFiltersVisible;
    this.clickedCollapse.emit(true)
  }

  public getCurrentLang(){
    return this.translateService.currentLang
  }

  isFilterEmpty() {
    return _.isEqual(this.filters[this.selectedFilterIndex], this.emptyFilter)
  }

  anyFilterArrayOption(filter: string) {
    this.filters[this.selectedFilterIndex][filter] = null;
    this.onClickSubmitFilters();
  }

  toggleFilterArrayOption(filter:string, option:any) {
    if(this.filters[this.selectedFilterIndex][filter]) {
      const i = this.filters[this.selectedFilterIndex][filter].findIndex(e => e.item_id === option.item_id);
      if(i > -1){
        this.filters[this.selectedFilterIndex][filter].splice(i, 1);
      } else {
        this.filters[this.selectedFilterIndex][filter].push(option)
      }
    } else {
      this.filters[this.selectedFilterIndex][filter] = [option];
    }
    if(this.filters[this.selectedFilterIndex][filter] && this.filters[this.selectedFilterIndex][filter].length == 0) {
      this.filters[this.selectedFilterIndex][filter] = null;
    }
    this.onClickSubmitFilters();
  }

  filterArrayHasOption(filter:string, option:any):boolean {
    if(this.filters[this.selectedFilterIndex][filter]) {
      return this.filters[this.selectedFilterIndex][filter].some(e => e.item_id === option.item_id)
    } else {
      return false;
    }
  }

  changedRangeFilter(filter, newValue = null, isInput = false) {
    if(isInput) {
      this.rangeFilters[filter] = newValue;
    } else {
      if(filter == 'participants') {
        this.filters[this.selectedFilterIndex].min_participants = this.rangeFilters.participants[0];
        this.filters[this.selectedFilterIndex].field = this.rangeFilters.participants[1] < 1000 ? this.rangeFilters.participants[1] : null;
      }
      if(filter == 'prize_pool') {
        this.filters[this.selectedFilterIndex].prize_pool_min = this.rangeFilters.prize_pool[0];
        this.filters[this.selectedFilterIndex].prize_pool_max = this.rangeFilters.prize_pool[1] < 1000000 ? this.rangeFilters.prize_pool[1] : null;
      }
      if(filter == 'duration') {
        this.filters[this.selectedFilterIndex].from_duration = this.rangeFilters.duration[0];
        this.filters[this.selectedFilterIndex].to_duration = this.rangeFilters.duration[1];
      }
      if(filter == 'ability') {
        this.filters[this.selectedFilterIndex].av_ability = this.rangeFilters.ability;
      }
    }
    this.onKeyupSearch();
  }

  getFilterBadgeText(filter: any) {
    var filtersToIgnore = ['order', 'timezone', 'order_state', 'name', 'onlyNew', 'order_av_ability', 'order_buy_in', 'order_duration', 'order_field', 'order_game', 'order_grindstate', 'order_name', 'order_prize_pool', 'order_priority', 'order_register_time', 'order_site', 'order_speed', 'order_start', 'from_duration', 'to_duration', 'limit', 'id'];

    if(window.location.pathname != "/rebalance") {
      filtersToIgnore.push('auto_only', 'manual_only', 'deleted_filter_only', 'unchecked_filter_only');
    }

    var filtersBoolean = ['maxLate', 'only_favorites', 'show_exception', 'onlyFast', 'show_disabled', 'include_past']

    var i18n = {
      week_day: "day_of_the_week",
      av_ability: "max_ability",
      site: "site",
      game: "game",
      game2: "game",
      buy_in_min: "buy_in_min",
      buy_in_max: "buy_in_max",
      speed: "speed",
      type: "type",
      players: "players",
      variant: "variant",
      state: "state",
      prize_pool_min: "prize_pool_min",
      prize_pool_max: "prize_pool_max",
      init_stacks_min: "init_stacks_min",
      init_stacks_max: "init_stacks_max",
      from_date: "start",
      to_date: "to_date",
      from_time: "from_time",
      to_time: "to_time",
      from_datetime: "from_time",
      to_datetime: "to_time",
      search: "search_by_name",
      scheduled: "scheduled",
      maxLate: "only_max_late_tournaments",
      field: "max_participants",
      priority: "priority",
      include_closed: "include_closed",
      onlyFast: "only_fast",
      from_register_time: "from_registration_time",
      to_register_time: "to",
      exclude: "exclude",
      flagged_only: "flagged_only",
      auto_scheduled_only: "auto_scheduled",
      show_exception: "show_exception",
      category: "category",
      only_favorites: "favorite_schedules",
      currency: "currency",
      min_participants: "min_participants",
      from_end_time: "end_time",
      to_end_time: "end_time",
      show_disabled: "only_disabled_tournaments",
      include_past: "include_past_tournaments",
      auto_only: "auto_scheduled",
      manual_only: "manually_scheduled",
      deleted_filter_only: "deleted_filter",
      unchecked_filter_only: "filter_unchecked",
    }

    if(filtersToIgnore.includes(filter.key) || (filter.value === this.emptyFilter[filter.key] && (filter.key != 'auto_only' && filter.key != 'manual_only' && filter.key != 'deleted_filter_only' && filter.key != 'unchecked_filter_only')) || filter.value === "" || filter.value === null) {
      return null;
    }

    const key = filter.key;

    if(filter.value.constructor === Array) {
      if (filter.value.length > 0) {
        const values = filter.value.map((val) => {
          return val.item_text ?? val
        })
        return this.translateService.instant(i18n[key]) + ": " + values.join(', ');
      } else {
        return null;
      }
    } else if (key == 'auto_only' || key == 'manual_only' || key == 'deleted_filter_only' || key == 'unchecked_filter_only') {
      if(filter.value.toString() == 'true') {
        return this.translateService.instant(i18n[key]) + ": " + this.translateService.instant('filter_' + filter.value.toString());
      } else {
        return null;
      }
    } else if (key == 'search' || key == 'exclude') {
      const values = filter.value.split('|');
      return this.translateService.instant(i18n[key]) + ": " + values.join(', ');
    } else if (filtersBoolean.includes(key)){
      return this.translateService.instant(i18n[key]);
    } else if (key == 'from_date' || key == 'to_date') {
      return this.translateService.instant(i18n[key]) + ": " + filter.value.split('T')[0];
    } else if (filter.value.toString() == 'true' || filter.value.toString() == 'false') {
      return this.translateService.instant(i18n[key]) + ": " + this.translateService.instant('filter_' + filter.value.toString());
    } else if (i18n[key]) {
      return this.translateService.instant(i18n[key]) + ": " + filter.value;
    } else {
      return key.toString() + ": " + filter.value;
    }
  }

  allRebalanceFiltersChecked() {
    var all = true;
    if(this.filters[this.selectedFilterIndex].auto_only != true) {
      all = false;
    }
    if(this.filters[this.selectedFilterIndex].manual_only != true) {
      all = false;
    }
    if(this.filters[this.selectedFilterIndex].deleted_filter_only != true) {
      all = false;
    }
    if(this.filters[this.selectedFilterIndex].unchecked_filter_only != true) {
      all = false;
    }
    return all;
  }

  checkAllRebalanceFilters() {
    this.filters[this.selectedFilterIndex].auto_only = true;
    this.filters[this.selectedFilterIndex].manual_only = true;
    this.filters[this.selectedFilterIndex].deleted_filter_only = true;
    this.filters[this.selectedFilterIndex].unchecked_filter_only = true;
    this.onClickSubmitFilters();
  }

  removeFilterBadge(filter: any) {
    if(filter.key == "auto_only" || filter.key == "manual_only" || filter.key == "deleted_filter_only" || filter.key == "unchecked_filter_only") {
      this.filters[this.selectedFilterIndex][filter.key] = null;
    } else if (filter.key == "include_closed") {
      this.filters[this.selectedFilterIndex][filter.key] = false;
    } else {
      this.filters[this.selectedFilterIndex][filter.key] = this.emptyFilter[filter.key];
    }
    this.sendFilterList(this.filters[this.selectedFilterIndex])
  }

  goToOnTimeTournaments(): void {
    createDetachedFrame("/on-time-tournaments");
  }

  goToGrindMode(): void {
    createDetachedFrame("/grind-mode");
  }

  async onClickExecuteAutoScheduler() {
    this.loadingAutoScheduler = true;
    try {
      await this.tierService.executeAutoScheduler().toPromise();
      this.onClickAutoScheduler();
    } catch (e) {
      this.showSnackMessage(e);
    } finally {
      this.showSnackMessage(this.translateService.instant("auto_schedule_success"));
      this.loadingAutoScheduler = false;
      this.lockedAutoScheduler = true;
      setTimeout(() => {
        this.lockedAutoScheduler = false;
      }, 60000);
    }
  }

  onClickSubmit():void {
    this.filterSubject.next({skipDebounce: true, ...this.filters[this.selectedFilterIndex]});
  }

  async ngOnInit() {
      await this.getCurrentUser(0);

      this.getCurrencies();

      this.modalService.onHide.subscribe(() => {
        this.openedSaved = false;
        this.openedAuto = false;
      });

      let routeState = this.location.getState();
      if (
        routeState["buyin"] != undefined &&
        routeState["speed"] != undefined &&
        routeState["horario"] != undefined
      ) {
        this.filters[this.selectedFilterIndex].buy_in_max = routeState["buyin"];
        this.filters[this.selectedFilterIndex].buy_in_min = routeState["buyin"];
        this.filters[this.selectedFilterIndex].speed = routeState["speed"];
        this.filters[this.selectedFilterIndex].from_time =
          routeState["horario"];
        this.filters[this.selectedFilterIndex].to_time = routeState["horario"];
      }

      this.getSites();

      this.dropdownListGame = [
        { item_id: 1, item_text: "NLH" },
        { item_id: 2, item_text: "PLO4" },
        { item_id: 4, item_text: "PLO5" },
        { item_id: 5, item_text: "PLO6" },
      ];

      this.dropdownListGameAdmin = [
        { item_id: 1, item_text: "NLH" },
        { item_id: 2, item_text: "PLO4" },
        { item_id: 3, item_text: "Other" },
        { item_id: 4, item_text: "PLO5" },
        { item_id: 5, item_text: "PLO6" },
      ];

      this.dropdownListPlayers = [
        { item_id: "Full Ring", item_text: "7 to 10 (Full Ring)" },
        { item_id: "6-Max", item_text: "6-Max" },
        { item_id: "3 to 5-Max", item_text: "3 to 5-Max" },
        { item_id: "2 (Heads Up)", item_text: "2 (Heads-Up)" },
      ];
      this.dropdownListSpeed = [
        { item_id: "Hyper-Turbo", item_text: "Hyper-Turbo" },
        { item_id: "Turbo", item_text: "Turbo" },
        { item_id: "Regular", item_text: "Regular" },
        { item_id: "Slow", item_text: "Slow" },
      ];
      this.dropdownListType = [
        { item_id: "Regular", item_text: "Regular" },
        { item_id: "Satellite", item_text: "Satellite", item_disabled: true },
        { item_id: "Featured", item_text: "Featured", item_disabled: true },
        { item_id: "Regional", item_text: "Regional" },
        { item_id: "Private", item_text: "Private", item_disabled: true },
      ];

      this.dropdownListVariant = [
        { item_id: "Regular", item_text: "Regular" },
        { item_id: "Knockout", item_text: "Knockout" },
      ];

      this.dropdownListState = [
        { item_id: "Late Registration", item_text: "Late Registration" },
        { item_id: "Announced", item_text: "Announced" },
      ];

      this.dropdownListScheduled = [
        { item_id: true, item_text: this.translateService.instant("scheduled") },
        { item_id: false, item_text: this.translateService.instant("not_scheduled") },
      ]

      this.dropdownListFlagged = [
        { item_id: true, item_text: this.translateService.instant("flagged") },
        { item_id: false, item_text: this.translateService.instant("not_flagged") },
      ]

      this.dropdownListAutoScheduled = [
        { item_id: true, item_text: this.translateService.instant("auto_scheduled") },
        { item_id: false, item_text: this.translateService.instant("manually_scheduled") },
      ]

      this.dropdownListAutoScheduledSearch = [
        { item_id: true, item_text: this.translateService.instant("auto_scheduled") },
        { item_id: false, item_text: this.translateService.instant("not_auto_scheduled") },
      ]

      this.dropdownListPriority = [
        { item_id: 0, item_text: "N/A" },
        { item_id: 1, item_text: "1 - VERY LOW" },
        { item_id: 2, item_text: "2 - VERY LOW" },
        { item_id: 3, item_text: "3 - LOW" },
        { item_id: 4, item_text: "4 - LOW" },
        { item_id: 5, item_text: "5 - MEDIUM" },
        { item_id: 6, item_text: "6 - MEDIUM" },
        { item_id: 7, item_text: "7 - MEDIUM" },
        { item_id: 8, item_text: "8 - HIGH" },
        { item_id: 9, item_text: "9 - HIGH" },
        { item_id: 10, item_text: "10 - HIGH" },
      ];

      this.dropdownListCategory = [
        { item_id: 0, item_text: "Standard" },
        { item_id: 1, item_text: "Series" },
      ];

      this.dropdownListCurrency = Currency.list.map(e => {
        return {
          item_id: e.iso,
          item_text: `${e.symbol} - ${e.iso}`
        }
      })

      this.dropdownListDay = [
        { item_id: 0, item_text: this.translateService.instant("sunday") },
        { item_id: 1, item_text: this.translateService.instant("monday") },
        { item_id: 2, item_text: this.translateService.instant("tuesday") },
        { item_id: 3, item_text: this.translateService.instant("wednesday") },
        { item_id: 4, item_text: this.translateService.instant("thursday") },
        { item_id: 5, item_text: this.translateService.instant("friday") },
        { item_id: 6, item_text: this.translateService.instant("saturday") }
      ];

      this.dropdownSettings = {
        singleSelection: false,
        idField: "item_id",
        textField: "item_text",
        selectAllText: "Select All",
        unSelectAllText: "UnSelect All",
        disabledField: "item_disabled",
        itemsShowLimit: 2,
        allowSearchFilter: false,
        enableCheckAll: true,
      };

      this.dropdownSettingsTeam = {
        singleSelection: false,
        idField: "email",
        textField: "fullname",
        selectAllText: "Select All",
        unSelectAllText: "UnSelect All",
        itemsShowLimit: 1,
        allowSearchFilter: false,
        enableCheckAll: false,
      };

      this.dropdownSettingsSingle = {
        singleSelection: true,
        idField: "item_id",
        textField: "item_text",
        selectAllText: "Select All",
        unSelectAllText: "UnSelect All",
        itemsShowLimit: 2,
        allowSearchFilter: false,
        enableCheckAll: false,
      };

      this.filterSubject.pipe(debounce((filters) => timer(filters.skipDebounce ? 0 : 1000))).subscribe((filters: any) => {
        this.sendFilterList(filters);
      });
  }

  ngOnChanges() {

    this.filters[this.selectedFilterIndex].order_start = this.order?.start ?? 0;
    this.filters[this.selectedFilterIndex].order_site = this.order?.site ?? 0;
    this.filters[this.selectedFilterIndex].order_game = this.order?.game ?? 0;
    this.filters[this.selectedFilterIndex].order_buy_in = this.order?.buy_in ?? 0;
    this.filters[this.selectedFilterIndex].order_name = this.order?.name ?? 0;
    this.filters[this.selectedFilterIndex].order_prize_pool = this.order?.prize_pool ?? 0;
    this.filters[this.selectedFilterIndex].order_speed = this.order?.speed ?? 0;
    this.filters[this.selectedFilterIndex].order_grindstate = this.order?.state ?? 0;
    this.filters[this.selectedFilterIndex].order_field = this.order?.field ?? 0;
    this.filters[this.selectedFilterIndex].order_duration = this.order?.duration ?? 0;
    this.filters[this.selectedFilterIndex].order_priority = this.order?.priority ?? 0;
    this.filters[this.selectedFilterIndex].order_register_time = this.order?.register_time ?? 0

  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private sharedModal: BsModalRef;
  toggleModal(template?: TemplateRef<any>, size: string = 'modal-md') {
    if (template) {
      this.sharedModal = this.modalService.show(template, { class: size });
    } else if (this.sharedModal != null) {
      this.sharedModal.hide();
    }
  }

}
