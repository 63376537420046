import { Component, Input, OnDestroy, OnInit, TemplateRef } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DateTime } from "luxon";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { User } from "../../interfaces/user";
import * as moment from "moment-timezone";
import { Site } from "../../interfaces/sites";
import { UserService } from "../../services/user.service";
import { SiteService } from "../../services/site.service";
import { tournament } from "../../utils/tournament";
import { RequestTournamentService } from "./request-tournament.service";
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { TierService } from "../../services/tier.service";
import { TournamentService } from "../../services/tournament.service";
import { SubSink } from "subsink";

class SearchFilters {
  name: string;
  site: Array<any>;
  date: string;
  registerFrom: any;
  buyIn: number;

  validate(): boolean {
    let valid = true;

    if (!this.site) {
      valid = false;
    }

    if (!this.date && !this.name) {
      valid = false;
    }

    if (!this.registerFrom && !this.name) {
      valid = false;
    }

    if (!this.buyIn && !this.name) {
      valid = false;
    }

    return valid;
  }
}

interface RequestTournament {
  site: string;
  priority: string;
  startTime: string;
  buyIn: number;
  name: string;
}

@Component({
  selector: "app-request-tournamet",
  templateUrl: "./request-tournament.component.html",
  styleUrls: ["./request-tournament.component.css"],
})
export class RequestTournametComponent implements OnInit, OnDestroy {
  private subs = new SubSink();
  
  @Input() mode = 'grind'

  public dropdownSettings: IDropdownSettings = {};
  public dropdownListSites = [];
  public readonly tournamentUtils = tournament;
  sites: Site[];
  sitesMap: { [key: number]: Site } = {};
  modalRef: BsModalRef;
  searchFilters: SearchFilters = new SearchFilters();
  requestTournamentList: RequestTournament[] = [];
  selectedTournaments: number[] = [];
  alreadySearch: boolean = false;
  isSubmited = false;
  isLoading: boolean = false;
  // Snackbar Message
  public showSnackbar = false;
  public snackbarMessage = "";
  public filterFromDate: string;
  public dateFromModel: IMyDateModel = null;

  public currentUser: User = {
    id: null,
    team: "",
    role: "",
    schedules: [],
    profile: {
      sharkscope_nicks: [],
      grind_mode: {}
    },
  };

  constructor(
    private modalService: BsModalService,
    private requestTournamentService: RequestTournamentService,
    private translateService: TranslateService,
    private siteService: SiteService,
    private tierService: TierService,
    private tournamentService: TournamentService,
    private userService: UserService
  ) {}

  public dateFromOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: this.translateService.currentLang == 'en-US' ? 'mm/dd/yyyy' : 'dd/mm/yyyy'
  };

  public pendingTournaments = [];

  onDateChange(event: IMyDateModel, input: string): void {
    let newDate = event.singleDate.jsDate;
    if(newDate){
      const offset = newDate.getTimezoneOffset()
      newDate = new Date(newDate.getTime() - (offset*60*1000))
      this.searchFilters.date = newDate.toISOString().split('T')[0]
      let copy: IAngularMyDpOptions = this.getCopyOfOptions(input == 'from' ? 'to' : 'from');
      this.dateFromOptions = copy;
    } else {
      this.searchFilters.date = null;
    }
  }

  getCopyOfOptions(input: string): IAngularMyDpOptions {
    return JSON.parse(JSON.stringify(this.dateFromOptions))
  }

  public getCurrentLang(){
    return this.translateService.currentLang
  }

  async ngOnInit() {
    this.dropdownSettings = {
      singleSelection: true,
      idField: "item_id",
      textField: "item_text",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      disabledField: "item_disabled",
      itemsShowLimit: 2,
      allowSearchFilter: false,
      enableCheckAll: false,
    };
    this.getSites();
    await this.getCurrentUser(0);
    await this.getRequests();
  }

  openModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template, {
      animated: false,
      backdrop: "static",
      class: "modal-sm",
    });

    this.modalRef.setClass("modal-request-dialog");
  }

  showSnackMessage(msg: string) {
    this.snackbarMessage = msg;
    this.showSnackbar = true;
    setTimeout(() => {
      this.showSnackbar = false;
    }, 3000);
  }

  async getSites() {
    const data = await this.siteService.getSitesHelpers().toPromise();
    this.sites = data.sites
    this.sitesMap = data.sitesMap;

    let ddSites = data.sites.map((element) => {
      return { item_id: element.id, item_text: element.name };
    });

    this.dropdownListSites = ddSites;
  }

  async getCurrentUser(i: number) {
    this.currentUser = await this.userService.getCurrentUser().toPromise();
  }

  handleSearchTournament(): void {
    this.isSubmited = true;
    if (!this.searchFilters.validate()) {
      return;
    }
    this.isLoading = true;

    let time;
    //TIME
    if (this.searchFilters.registerFrom && this.searchFilters.registerFrom != "") {
      const original = new Date()
      original.setHours(this.searchFilters.registerFrom.split(':')[0])
      original.setMinutes(this.searchFilters.registerFrom.split(':')[1])
      original.setSeconds(0)
      original.setMilliseconds(0)
      time = Math.floor(original.getTime() / 1000)

    } else {
      time = null
    }

    let date;
    if (this.searchFilters.date && this.searchFilters.date != "") {
      const formated = moment(
        this.searchFilters.date
      ).format();

      const original = new Date(formated)
      date = new Date(original.toISOString().slice(0, -1))
    } else {
      date = null;
    }

    const payload = {
      filter: {
        search: this.searchFilters.name,
        site: this.searchFilters.site ? [Number(this.searchFilters.site)] : null,
        from_time: time,
        to_time: time,
        buy_in_min: this.searchFilters.buyIn,
        buy_in_max: this.searchFilters.buyIn,
        order: {state: 1},
        field: null,
        from_date: date,
        from_duration: null,
        from_register_time: null,
        game2: [1, 2, 3, 4, 5],
        id: null,
        init_stacks_max: null,
        init_stacks_min: null,
        maxLate: false,
        name: "New Filter",
        onlyFast: false,
        onlyNew: false,
        order_buy_in: 0,
        order_duration: 0,
        order_field: 0,
        order_game: 0,
        order_name: 0,
        order_prize_pool: 0,
        order_register_time: 0,
        order_site: 0,
        order_speed: 0,
        order_start: 1,
        order_state: 0,
        players: null,
        priority: null,
        prize_pool_max: null,
        prize_pool_min: null,
        scheduled: null,
        speed: null,
        state: null,
        to_date: date,
        to_duration: null,
        to_register_time: null,
        type: null,
        variant: null,
        limit: null,
        min_participants: null
      },
      limit: null
    }

    setTimeout(() => {
      this.tournamentService
        .getTournamentFilter(
          {
            ...payload,
            timezone: new Date().getTimezoneOffset(),
          },
          0
        )
        .subscribe((res) => {
          this.isLoading = false;
          this.alreadySearch = true;

          const scheduled = new Set(
            this.currentUser.schedules.map((e) => e.tournament_id)
          );

          this.requestTournamentList =
          res != null
          ? res.tournaments.map((e) => {
              e.scheduled = scheduled.has(e.id);
              return e;
            }).filter(e => !this.pendingTournaments.includes(e.id))
          : [];
        });
    }, 2000);
  }

  handleSelectTournament(selectedId: number): void {
    if (this.selectedTournaments.includes(selectedId)) {
      const index = this.selectedTournaments.indexOf(selectedId);
      this.selectedTournaments.splice(index, 1);
    } else {
      this.selectedTournaments.push(selectedId);
    }
  }

  sendTournamentToApprove(): void {
    if (!this.selectedTournaments.length) {
      return;
    }

    this.isLoading = true;
    setTimeout(() => {
      this.tierService
        .putRequest(this.selectedTournaments)
        .subscribe(
          (res) => {
            this.alreadySearch = false;
            this.isSubmited = false;
            this.searchFilters = new SearchFilters();
            this.isLoading = false;
            this.showSnackMessage(
              this.translateService.instant("request_sent")
            );
            this.modalRef.hide();
            this.getRequests();
          },
          (error: any) => {
            this.isLoading = false;
            console.log(error)
            // TODO: implementar tratativa de erro de acordo com o que o backend retornar como erro
          }
        );
    }, 2000);
  }

  getOnlyTime(isoDate: string): string {
    return DateTime.fromISO(isoDate).toFormat("d, HH:mm");
  }

  async getRequests() {
    try {

      this.subs.sink = this.tierService
        .listRequest()
        .subscribe(
          (res: any) => {
            res.pending.map((e) => {
              this.pendingTournaments.push(e.id);
            })
          },
          (err) => {
            // tratativa do erro
          }
        );
    } catch (e) {
      console.log(e)
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
