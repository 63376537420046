<div
  *ngIf="columnsOptions"
  (mouseenter)="columnsOptions = true"
  (mouseleave)="columnsOptions = false"
  class="columns-options-wrapper"
>
  <div
    class="card columns-options shadow mb-0"
    [style.width]="getColumnsOptionsWidth('card')"
  >
    <div class="card-header">
      <div class="row">
        <div class="col-auto">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              (click)="toggleAllCol()"
              [checked]="isCheckedAllCol()"
            />
          </div>
        </div>
        <div class="col">
          Columns
        </div>
      </div>
    </div>
    <div class="card-body py-1">
      <div class="row">
        <ng-container *ngFor="let col of allColumns">
          <div
            *ngIf="showColumns.includes(col.name)"
            [class]="getColumnsOptionsWidth('column')"
          >
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                [id]="col.name"
                (click)="toggle(col)"
                [checked]="isChecked(col)"
              />
              <label class="form-check-label">{{ ((col.i18n) | translate) }}</label>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div class="group-section" *ngIf="groupByHour">
  <div class="hours-header">
    {{ 'hour' | translate }}
  </div>
  <div #divToScroll style="overflow-y: hidden;" [ngStyle]="{'height': getHourGroupHeight(height)}">
    <ng-container *ngFor="let tor of tournaments">
      <div *ngIf="rowsByHour[tor.id]" class="rowByHour" [style.height]="(rowsByHour[tor.id].rows * 40) + 'px'">
        <div class="hour-text">{{ rowsByHour[tor.id].hour }}</div>
      </div>
    </ng-container>
  </div>
</div>
<div #tableElement [ngClass]="{'table-section': groupByHour}">
  <ngx-datatable
    #myTable
    [style.height]="height"
    [style.width]="width"
    [style.fontSize]="getFontSize()"
    class="bootstrap"
    [ngClass]="{'embedded-table': tableMode == 'embedded'}"
    [rows]="tournaments"
    [columnMode]="'force'"
    [headerHeight]="53"
    [footerHeight]="0"
    [rowHeight]="tableMode == 'rebalance' ? 92 : tableMode == 'embedded' ? 32 : getRowHeight()"
    [rowClass]="
      tableMode == 'schedule' || tableMode == 'embedded'
        ? getRowClass
        : (tableMode == 'grind' && !isSimpleMode)
        ? getPlayingRowClass
        : tableMode == 'addTournaments' || tableMode == 'addTournamentsTier' || tableMode == 'addTournamentsTools'
        ? getDisabledRowClass
        : getCheckedRowClass
    "
    [scrollbarV]="virtualScroll"
    [scrollbarH]="true"
    (reorder) = 'reordered($event)'
  >
    <ngx-datatable-row-detail
      [rowHeight]="100"
      #myDetailRow
      (toggle)="onDetailToggle($event)"
    >
      <ng-template
        let-row="row"
        let-expanded="expanded"
        ngx-datatable-row-detail-template
      >
        <table class="table table-borderless">
          <thead>
            <tr>
              <th>Played</th>
              <th>Rebuy</th>
              <th>ITM</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td class="text-center">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    id="inlineCheckbox1"
                    type="checkbox"
                    [checked]="row['playing']"
                    [(ngModel)]="row['playing']"
                  />
                </div>
              </td>
              <td>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"
                      ><i class="fa fa-refresh"></i
                    ></span>
                  </div>
                  <input
                    class="form-control form-control-sm"
                    type="number"
                    ngModel
                    #rebuy
                    name="rebuy"
                    autocomplete="off"
                    [(ngModel)]="selectedRowRebuy"
                    min="0"
                    step="1"
                    style="min-width: 6rem"
                  />
                </div>
                <span *ngIf="getRowMaxRebuy(row) && selectedRowRebuy > getRowMaxRebuy(row)" style="color: red;">{{ 'error_max_rebuy' | translate }} {{ getRowMaxRebuy(row) }}</span>
              </td>
              <td>
                <div class="d-flex align-items-center">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        ><i class="fa fa-dollar"></i
                      ></span>
                    </div>
                    <input
                      class="form-control form-control-sm"
                      appCurrencyMask
                      name="itm"
                      ngModel
                      #itm
                      [value]="row['itm']"
                      type="text"
                      autocomplete="off"
                      placeholder="0,00"
                      style="min-width: 6rem"
                    />
                  </div>
                </div>
              </td>
              <td>
                <button
                  (click)="onClickConfirmBust(row, selectedRowRebuy, itm.value)"
                  [disabled]="getRowMaxRebuy(row) && selectedRowRebuy > getRowMaxRebuy(row)"
                  class="btn btn-sm btn-success mr-1"
                >
                  confirm
                </button>
                <button
                  (click)="toggleExpandRow(row)"
                  class="btn btn-sm btn-danger"
                >
                  close
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </ng-template>
    </ngx-datatable-row-detail>
    <!-- CHECKBOX -->
    <ngx-datatable-column
      [width]="getCheckboxColumndWidth(tableMode, licenseType, isMaster, isSimpleMode)"
      [sortable]="false"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="false"
      *ngIf="showColumns.includes('checkbox') && tableMode != 'embedded'"
    >
      <ng-template ngx-datatable-header-template>
        <span class="datatable-header-cell-label checkboxes"
          ><i
            (mouseenter)="columnsOptions = true"
            class="fa fa-cog absolute-left"
          ></i
          ><input
            *ngIf="tableMode == 'requested' || tableMode == 'tournaments' || tableMode == 'addTournaments' || tableMode == 'addTournamentsTools' || tableMode == 'addTournamentsTier' || tableMode == 'rebalance'"
            #checkboxAll
            type="checkbox"
            [checked]="isAllChecked()"
            (change)="checkAllTournaments($event)"
          />
          <a
            container="body"
            *ngIf="tableMode == 'addTournaments' || tableMode == 'addTournamentsTier'"
            (click)="onClickOpenDeleteAdd(true)"
            tooltip="{{ 'delete_schedule' | translate }}"
            triggers="hover"
            style="cursor: pointer"
            [ngClass]="{'disabled-link': isAllEnabled == false || isAllEnabled == null}"
          >
            <i
              class="fa fa-trash btn-edit-icon ml-2"
              style="color: rgb(228, 70, 70); font-size: 19px; margin: 0"
            ></i>
          </a>
          <a
            container="body"
            *ngIf="tableMode == 'addTournaments'"
            (click)="onClickOpenEnable(true)"
            tooltip="{{ 'enable_schedule' | translate }}"
            triggers="hover"
            style="cursor: pointer"
            [ngClass]="{'disabled-link': isAllEnabled == true || isAllEnabled == null}"
          >
            <i
              class="fa fa-refresh btn-edit-icon ml-2"
              style="color: rgb(109, 185, 58); font-size: 19px; margin: 0"
            ></i>
          </a>
        </span>
      </ng-template>
      <ng-template
        ngx-datatable-cell-template
        let-row="row"
        let-rowIndex="rowIndex"
      >
        <div
          (mouseenter)="openComment = row['id']"
          (click)="checkedMe(row)"
          class="comment-wrapper"
          [ngClass]="{'tour-checkbox': tableMode == 'tournaments' && !row['scheduled'], 'guide-tournament': tableMode == 'rebalance'}"
        >
          <div class="manual-schedule-text" *ngIf="tableMode == 'rebalance' && !row['auto_scheduled']">{{ 'manually_scheduled' | translate }}</div>
        </div>
        <div
          [@inOutAnimation]
          *ngIf="
            (tableMode == 'grind' || tableMode == 'schedule' || tableMode == 'embedded') &&
            openComment == row['id'] &&
            row['metadata'] &&
            row['metadata']['COMMENT']
          "
          [ngClass]="{
            'comment-tooltip-first': rowIndex == 0,
            'comment-tooltip': rowIndex > 0
          }"
        >
          <div
            [ngClass]="{
              'comment-text-first': rowIndex == 0,
              'comment-text': rowIndex > 0
            }"
          >
            " {{ row["metadata"] && row["metadata"]["COMMENT"] }} "
          </div>
        </div>
        <div class="form-check">
          <div class="row">
            <div *ngIf="tableMode != 'grind'" class="col-auto p-0 pl-3">
              <ng-container *ngIf="(tableMode == 'addTournamentsTier' && row['owner_id'] == null) || (tableMode == 'addTournamentsTools' && !isAdmin && row['owner_id'] == null); else noForm">
                <input *ngIf="tableMode != 'tournaments'" class="form-check-input" type="checkbox" disabled />
                <a
                  *ngIf="tableMode == 'tournaments'"
                  container="body"
                  (click)="onClickOpenEdit(row['schedule_id'])"
                  tooltip="{{ 'edit_schedule' | translate }}"
                  triggers="hover"
                  style="cursor: pointer"
                  class="edit-schedule"
                >
                  <i
                    class="fa fa-edit btn-edit-icon"
                    style="font-size: 21px; margin: 0"
                  ></i>
                </a>
              </ng-container>
              <ng-template #noForm>
                <input
                  *ngIf="tableMode != 'grind' && tableMode != 'requested-log'"
                  #checkboxes
                  tabindex="-1"
                  (click)="checkedMe(row)"
                  class="form-check-input"
                  id="{{ row['id'] }}"
                  type="checkbox"
                  [ngModel]="checkedTorneios[row['id']]"
                  [style.marginTop]="tableMode == 'rebalance' ? '4px' : null"
                />
                <span
                  *ngIf="isDevMode && tournament.isNew(row)"
                  class="badge badge-warning"
                  >{{ "new" | translate }}</span
                >
              </ng-template>
              <a
                container="body"
                *ngIf="tableMode == 'grind' || tableMode == 'schedule'"
                (click)="onClickSetFavorite(row)"
                triggers="hover"
                tooltip="{{ 'favorite' | translate }}"
                style="cursor: pointer"
              >
                <i
                  class="fa btn-edit-icon ml-1"
                  [ngClass]="{
                    'fa-star-o': !row['metadata']['FAVORITE'],
                    'fa-star': row['metadata']['FAVORITE'],
                    'text-warning': row['metadata']['FAVORITE']
                  }"
                  style="font-size: 19px; margin: 0"
                ></i>
              </a>
            </div>
            <ng-container *ngIf="tableMode == 'addTournamentsTools'">
              <div class="col-auto col-btns pl-2">
                <a
                  container="body"
                >
                  {{ row['id'] }}
                </a>
              </div>
            </ng-container>
            <ng-container *ngIf="tableMode == 'addTournaments' || tableMode == 'addTournamentsTier'">
              <div *ngIf="!row['is_disabled']" class="col-auto col-btns pl-1">
                <a
                  container="body"
                  (click)="onClickOpenDeleteAdd(false, row['id'])"
                  tooltip="{{ 'delete_schedule' | translate }}"
                  triggers="hover"
                  *ngIf="tableMode == 'addTournaments' || row['owner_id'] != null"
                  style="cursor: pointer"
                >
                  <i
                    class="fa fa-trash btn-edit-icon"
                    style="color: rgb(228, 70, 70); font-size: 19px; margin: 0"
                  ></i>
                </a>
              </div>
              <div *ngIf="row['is_disabled']" class="col-auto col-btns pl-1">
                <a
                  container="body"
                  (click)="onClickOpenEnable(false, row['id'])"
                  tooltip="{{ 'enable_schedule' | translate }}"
                  triggers="hover"
                  style="cursor: pointer"
                  *ngIf="tableMode == 'addTournaments' || row['owner_id'] != null"
                >
                  <i
                    class="fa fa-refresh btn-edit-icon"
                    style="color: rgb(109, 185, 58); font-size: 19px; margin: 0"
                  ></i>
                </a>
              </div>
              <div class="col-auto col-btns pl-2">
                <a
                  container="body"
                  (click)="onClickOpenEdit(row['id'])"
                  tooltip="{{ 'edit_schedule' | translate }}"
                  triggers="hover"
                  style="cursor: pointer"
                  *ngIf="tableMode == 'addTournaments' || row['owner_id'] != null"
                >
                  <i
                    class="fa fa-edit btn-edit-icon text-primary"
                    style="font-size: 21px; margin: 0"
                  ></i>
                </a>
              </div>
              <div class="col-auto col-btns pl-2">
                <a
                  container="body"
                  (click)="onClickOpenEditCopy(row['id'])"
                  tooltip="{{ 'create_from_tournament' | translate }}"
                  triggers="hover"
                  style="cursor: pointer"
                >
                  <i
                    class="fa fa-copy btn-edit-icon text-success"
                    style="font-size: 21px; margin: 0"
                  ></i>
                </a>
              </div>
              <div class="col-auto col-btns pl-2">
                <a
                  container="body"
                >
                  {{ row['id'] }}
                </a>
              </div>
            </ng-container>
            <ng-container *ngIf="tableMode == 'schedule'">
              <div class="col-auto col-btns pl-1">
                <a
                  container="body"
                  *ngIf="!hasTeam || isMaster"
                  (click)="onClickOpenDelete(row['id'])"
                  tooltip="{{ 'delete_schedule' | translate }}"
                  triggers="hover"
                  style="cursor: pointer"
                >
                  <i
                    class="fa fa-trash btn-edit-icon ml-1"
                    style="color: rgb(228, 70, 70); font-size: 19px; margin: 0"
                  ></i>
                </a>
              </div>
              <div class="col-auto col-btns pl-2">
                <a
                  container="body"
                  (click)="onClickOpenEdit(row['id'])"
                  tooltip="{{ 'edit_schedule' | translate }}"
                  triggers="hover"
                  style="cursor: pointer"
                  class="tour-edit-schedule"
                >
                  <i
                    class="fa fa-edit btn-edit-icon"
                    style="font-size: 21px; margin: 0"
                  ></i>
                </a>
              </div>
              <div class="col-auto col-btns pl-1">
                <a
                  container="body"
                  (click)="onClickOpenReport(row)"
                  tooltip="{{ 'open_report' | translate }}"
                  triggers="hover"
                  style="cursor: pointer"
                >
                  <i
                    class="fa fa-signal btn-edit-icon"
                    style="font-size: 19px; margin: 0"
                  ></i>
                </a>
              </div>
              <div class="col-auto col-btns pl-2">
                <button
                  class="btn btn-sm skip-btn btn-dark text-white pt-1"
                  (click)="onClickOpenSkip(row)"
                >
                  skip
                </button>
              </div>
              <div
                *ngIf="licenseType != 'free' || isMaster"
                container="body"
                class="col-auto col-btns pl-1"
              >
                <button
                  container="body"
                  class="btn btn-sm btn-danger skip-btn pt-1"
                  (click)="redirectToCalendar(row)"
                >
                  <i
                    class="fa fa-calendar"
                    tooltip="Add to google calendar"
                    triggers="hover"
                  ></i>
                </button>
              </div>
            </ng-container>
            <ng-container *ngIf="tableMode == 'grind'">
              <a
                container="body"
                *ngIf="isMaster"
                (click)="onClickOpenDelete(row['id'])"
                tooltip="{{ 'delete_schedule' | translate }}"
                triggers="hover"
                style="cursor: pointer; margin-top: -4px;"
              >
                <i
                  class="fa fa-trash btn-edit-icon ml-1"
                  style="color: rgb(228, 70, 70); font-size: 19px; margin: 0"
                ></i>
              </a>
              <a
                container="body"
                *ngIf="tableMode == 'grind' || tableMode == 'schedule'"
                (click)="onClickSetFavorite(row)"
                triggers="hover"
                tooltip="{{ 'favorite' | translate }}"
                style="cursor: pointer; margin-left: 6px; margin-top: -4px;"
              >
                <i
                  class="fa btn-edit-icon"
                  [ngClass]="{
                    'fa-star-o': !getFavorite(row),
                    'fa-star': getFavorite(row),
                    'text-warning': getFavorite(row)
                  }"
                  style="font-size: 19px; margin: 0"
                ></i>
              </a>
              <a
                container="body"
                (click)="onClickOpenEdit(row['id'])"
                tooltip="{{ 'edit_schedule' | translate }}"
                triggers="hover"
                style="cursor: pointer; margin-left: 6px; margin-top: -4px;"
                *ngIf="!isOldPlayingList">
                <i
                  class="fa fa-edit btn-edit-icon"
                  style="font-size: 21px; margin: 0"
                ></i>
              </a>
              <div class="col-auto col-btns pl-1" *ngIf="isSimpleMode" style="margin-top: -9px;">
                <button
                  class="btn btn-sm btn-dark text-white px-3"
                  (click)="
                  row['playing']
                    ? isPlayingList
                      ? onClickRemoveFromPlaying(row)
                      : onClickRemoveFromPlayingOld(row)
                    : onClickSkipTournament(row)
                  "
                >
                  <i class="fa fa-remove"></i>
                </button>
              </div>
              <div
              *ngIf="!isSimpleMode"
              class="btn-group group-button-size pl-2"
              role="group"
              aria-label="Basic example"
              style="margin-top: -4px;"
            >
              <button
                type="button"
                (click)="
                  row['playing']
                    ? toggleExpandRow(row)
                    : onClickStartPlay(row)
                "
                class="btn btn-sm"
                [style.height]="tableMode == 'grind' ? '25px' : null"
                [style.lineHeight]="tableMode == 'grind' ? 1.1 : null"
                [disabled]="!row['playing'] && playingDisabled"
                [class.btn-success]="!row['playing']"
                [class.btn-danger]="row['playing']"
                container="body"
              >
                <ng-container *ngIf="row['playing']">
                  Bust
                </ng-container>
                <ng-container *ngIf="!row['playing']">
                  <i class="fa fa-play"></i>
                </ng-container>
              </button>
              <button
                (click)="
                  row['playing']
                    ? isPlayingList
                      ? onClickRemoveFromPlaying(row)
                      : onClickRemoveFromPlayingOld(row)
                    : onClickSkipTournament(row)
                "
                [style.height]="tableMode == 'grind' ? '25px' : null"
                [style.lineHeight]="tableMode == 'grind' ? 1.1 : null"
                type="button"
                class="btn btn-sm btn-dark"
                container="body"
              >
                <i class="fa fa-remove"></i>
              </button>
              <button
                *ngIf="isOldPlayingList"
                (click)="onClickKeepOldTournament(row)"
                type="button"
                class="btn btn-sm btn-secondary"
                [style.height]="tableMode == 'grind' ? '25px' : null"
                [style.lineHeight]="tableMode == 'grind' ? 1.1 : null"
              >
                Keep
              </button>
              <ng-container *ngIf="!isOldPlayingList && row['status'] != 'bust_late'">
                <button
                  (click)="onClickAddToBustLate(row)"
                  type="button"
                  class="btn btn-sm btn-warning"
                  *ngIf="
                    minutesLeft(row['start'], row['late_register']) >= 1;
                    else maxLateDisabled
                  "
                  [style.height]="tableMode == 'grind' ? '25px' : null"
                  [style.lineHeight]="tableMode == 'grind' ? 1.1 : null"
                  container="body" [tooltip]="'Max late' + getMaxLate()" triggers="hover" [placement]="rowIndex == 0 ? 'bottom' : 'top'"
                >
                  <i class="fa fa-clock-o"></i>
                </button>
                <ng-template #maxLateDisabled>
                  <button
                    container="body"
                    type="button"
                    class="btn btn-sm btn-secondary"
                    style="cursor: default; opacity: 0.6"
                    [style.height]="tableMode == 'grind' ? '25px' : null"
                    [style.lineHeight]="tableMode == 'grind' ? 1.1 : null"
                    tooltip="can't add to max late with less than 1 minute left"
                    triggers="hover"
                    [placement]="rowIndex > 1 ? 'top' : 'bottom'"
                  >
                    <i class="fa fa-clock-o"></i>
                  </button>
                </ng-template>
              </ng-container>
              <div
                *ngIf="row['playing']"
                class="input-group input-group-sm flex-nowrap"
                style="width: 73px;"
              >
                <span class="rebuy-label">Rebuy</span>
                <div class="input-group-prepend">
                  <button
                    (click)="onClickDecreaseRebuy(row)"
                    class="btn border border-right-0 btn-light"
                    style="width: 1.5rem"
                    [style.height]="tableMode == 'grind' ? '25px' : null"
                    [style.lineHeight]="tableMode == 'grind' ? 1.1 : null"
                  >
                    <i class="fa fa-minus"></i>
                  </button>
                  <span
                    [style.height]="tableMode == 'grind' ? '25px' : null"
                    [style.lineHeight]="tableMode == 'grind' ? 1.1 : null"
                    class="input-group-text border-top border-bottom justify-content-center"
                    style="
                      width: 1.8rem;
                      border-left: none;
                      border-right: none;
                    "
                  >
                    {{ row["rebuy"] }}</span
                  >
                </div>
                <div class="input-group-append">
                  <button
                    (click)="onClickIncreaseRebuy(row)"
                    [disabled]="getRowMaxRebuy(row) != null && row['rebuy'] >= getRowMaxRebuy(row)"
                    class="btn border border-left-0 btn-light"
                    style="width: 1.5rem"
                    [style.height]="tableMode == 'grind' ? '25px' : null"
                    [style.lineHeight]="tableMode == 'grind' ? 1.1 : null"
                  >
                    <i class="fa fa-plus"></i>
                  </button>
                </div>
              </div>
            </div>
            </ng-container>
            <ng-container *ngIf="tableMode == 'rebalance'">
              <div class="col-auto p-0 pl-1" [style.opacity]="openComment == row['id'] ? 1 : 0.3">
                <span class="badge bg-success" style="width: 53px; font-size: 11px;">{{ 'current' | translate }}</span>
                <ng-container *ngIf="!isTorNull(row['old_tournament'])">
                  <div class="old-tournament-row">
                    <span class="badge bg-warning" style="width: 53px; font-size: 11px;">{{ 'previous' | translate }}</span>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-template>
    </ngx-datatable-column>

    <ng-container *ngFor="let col of columns">

      <!-- SITE -->
      <ngx-datatable-column
        [minWidth]="95"
        [width]="95"
        [resizeable]="false"
        [canAutoResize]="false"
        [draggable]="tableMode != 'grind'"
        *ngIf="col.name == 'site' && showColumns.includes('site')"
        name="site"
      >
        <ng-template let-column="column" ngx-datatable-header-template>
          <span class="datatable-header-cell-wrapper">
            <ng-container>
              <span class="datatable-header-cell-label draggable"
                ><i
                  container="body"
                  *ngIf="tableMode != 'requested' && tableMode != 'requested-log' && tableMode != 'embedded'"
                  (click)="onClickOrder('site')"
                  [class]="getSortClass('site')"
                  tooltip="{{ 'reorder_column' | translate }}"
                  triggers="hover"
                  placement="right"
                ></i>
                {{ "site" | translate }}</span
              >
            </ng-container>
          </span>
        </ng-template>
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
          <a *ngIf="sitesMap[value]?.hiperlink && tableMode != 'grind'; else noLink" [href]="sitesMap[value]?.hiperlink" target="_blank">
            <div
              [class]="(tableMode == 'rebalance' ? 'site-background-rebalance ' : 'site-background ') + styleSiteBackground(row['site'])"
              [style.paddingTop]="tableMode == 'grind' ? '0.55rem' : tableMode == 'embedded' ? '0.7rem' : null"
              [style.opacity]="openComment == row['id'] ? 1 : 0.8"
            >
              {{ sitesMap[value]?.name }}
            </div>
          </a>
          <ng-template #noLink>
            <div
              [class]="(tableMode == 'rebalance' ? 'site-background-rebalance ' : 'site-background ') + styleSiteBackground(row['site'])"
              [style.paddingTop]="tableMode == 'grind' ? '0.55rem' : tableMode == 'embedded' ? '0.7rem' : null"
              [style.opacity]="openComment == row['id'] ? 1 : 0.8"
            >
              {{ sitesMap[value]?.name }}
            </div>
          </ng-template>
        </ng-template>
      </ngx-datatable-column>
      <!-- PRIORITY -->
      <ngx-datatable-column
        [minWidth]="85"
        [width]="85"
        [maxWidth]="95"
        [resizeable]="true"
        [draggable]="tableMode != 'grind'"
        *ngIf="col.name == 'priority' && showColumns.includes('priority')"
        name="priority"
      >
        <ng-template let-column="column" ngx-datatable-header-template>
          <span class="datatable-header-cell-wrapper">
            <span class="datatable-header-cell-label draggable"
              ><i
                container="body"
                *ngIf="tableMode != 'requested' && tableMode != 'requested-log' && tableMode != 'embedded'"
                (click)="onClickOrder('priority')"
                [class]="getSortClass('priority')"
                tooltip="{{ 'reorder_column' | translate }}"
                triggers="hover"
                placement="right"
              ></i>
              {{ "priority" | translate }}</span
            >
          </span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template let-row="row">
          <div
            *ngIf="row['priority'] != 0"
            class="priority-label"
            [ngStyle]="{ 'background-color': getTournamentPriorityColor(row) }"
          >
            <i
              *ngIf="row['auto_scheduled']"
              class="fa fa-android icon-bot mr-1"
              container="body"
              tooltip="{{
                row['auto_scheduled']
                  ? ('tournament_was_auto_scheduled' | translate)
                  : ''
              }}"
              triggers="hover"
              placement="right"
            ></i>
            <span class="mr-1 priority-number">{{ row['priority'] }}</span>{{ getTournamentPriorityName(row) }}
          </div>
          <span *ngIf="row['priority'] == 0" class="priority-label no-priority">
            <i
              *ngIf="row['auto_scheduled']"
              class="fa fa-android icon-bot mr-1"
              container="body"
              tooltip="{{
                row['auto_scheduled']
                  ? ('tournament_was_auto_scheduled' | translate)
                  : ''
              }}"
              triggers="hover"
              placement="right"
            ></i>
            {{ getTournamentPriorityName(row) }}
          </span>
        </ng-template>
      </ngx-datatable-column>
      <!-- START -->
      <ngx-datatable-column
        [minWidth]="getStartColumndWidth(tableMode, 'min')"
        [width]="getStartColumndWidth(tableMode, 'width')"
        [maxWidth]="getStartColumndWidth(tableMode, 'max')"
        [resizeable]="true"
        [draggable]="tableMode != 'grind'"
        *ngIf="col.name == 'start' && showColumns.includes('start')"
        name="start"
      >
        <ng-template let-column="column" ngx-datatable-header-template>
          <span class="datatable-header-cell-wrapper">
            <span class="datatable-header-cell-label draggable"
              ><i
                container="body"
                *ngIf="tableMode != 'requested' && tableMode != 'requested-log'"
                (click)="onClickOrder('start')"
                [class]="getSortClass('start')"
                tooltip="{{ 'reorder_column' | translate }}"
                triggers="hover"
                placement="right"
              ></i>
              {{ "start" | translate }}</span
            >
          </span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <div style="position: relative">
            <div [ngClass]="{'text-warning': tableMode == 'rebalance' && (isFilterDifferent(row, savedFilters[row['scheduled_filter_id']])['from_time'] || isFilterDifferent(row, savedFilters[row['scheduled_filter_id']])['to_time'])}">
              {{ utcToLocal(value) }}
              <i
                *ngIf="tableMode == 'tournaments' && flagged[row['id']] && !row['scheduled']"
                class="fa fa-flag flagged-tor"
              ></i>
            </div>
            <ng-container *ngIf="tableMode == 'rebalance' && !isTorNull(row['old_tournament']) && row['old_tournament']['start']">
              <div *ngIf="!isTorNull(row['old_tournament'])" class="old-tournament-row" [ngClass]="{'text-warning': row['old_tournament']['start'] != '0001-01-01T00:00:00Z' && (isFilterDifferent(row['old_tournament'], savedFilters[row['scheduled_filter_id']])['from_time'] || isFilterDifferent(row['old_tournament'], savedFilters[row['scheduled_filter_id']])['to_time'])}">
                {{ row['old_tournament']['start'] != "0001-01-01T00:00:00Z" ? utcToLocal(row['old_tournament']['start']) : utcToLocal(row['start']) }}
              </div>
            </ng-container>
            <ng-container *ngIf="tableMode == 'rebalance' && !isTorNull(row['old_tournament']) && !row['old_tournament']['start']">
              <div class="old-tournament-row" [ngClass]="{'text-warning': (isFilterDifferent(row, savedFilters[row['scheduled_filter_id']])['from_time'] || isFilterDifferent(row, savedFilters[row['scheduled_filter_id']])['to_time'])}">
                {{ utcToLocal(row['start']) }}
              </div>
            </ng-container>
            <ng-template #popTemplate>
              <div>
                <strong>{{('notification' | translate)}}</strong>
                <br>
                <ng-container *ngFor="let not of row['notification']">
                  <br>
                  {{ not.is_personal ? '' : ('created_by_master' | translate)}}
                  <br>
                  {{ (not.time > 0
                    ? not.type == 'at'
                      ? getNotificationTime(not.time)
                      : not.type == 'at-blind' ? not.time : not.time + ' mins' + ' '
                    : '') +
                  (not.type != 'at' && not.type != 'at-blind'
                    ? (not.type.replace('-', '_') | translate)
                    : not.type == 'at-blind' ? ' bbs' : '')
                  }}
                  <br>
                </ng-container>
              </div>
            </ng-template>
            <i
              *ngIf="tableMode == 'addTournaments' || tableMode == 'addTournamentsTools' || tableMode == 'addTournamentsTier' && row['has_schedules']"
              class="text-success fa fa-calendar-check-o ml-1"
              container="body"
              tooltip="{{ 'has_schedules' | translate }}"
              triggers="hover"
              placement="bottom"
            ></i>
            <i
              *ngIf="row['notification']"
              container="body"
              class="fa fa-clock-o noti-icon"
              [tooltip]="popTemplate"
              triggers="hover"
              placement="bottom"
            ></i>
            <div
              *ngIf="row['scheduled'] && tableMode == 'requested'"
              class="ribbon-wrapper-green"
            >
              <div class="ribbon-green">{{ "scheduled" | translate }}</div>
            </div>

            <div
              *ngIf="
                row['scheduled'] &&
                !row['auto_scheduled'] &&
                tableMode == 'tournaments'
              "
              class="ribbon-wrapper-green"
            >
              <div class="ribbon-green">{{ "scheduled" | translate }}</div>
            </div>

            <div
              *ngIf="row['auto_scheduled'] && tableMode == 'tournaments'"
              class="ribbon-wrapper-blue"
              container="body"
              tooltip="{{ 'tournament_was_auto_scheduled' | translate }}"
              triggers="hover"
              placement="right"
            >
              <div class="ribbon-blue">
                <i *ngIf="row['auto_scheduled']" class="fa fa-android"></i>
                {{ "scheduled" | translate }}
              </div>
            </div>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <!-- REGISTER TIME -->
      <ngx-datatable-column
        [minWidth]="70"
        [width]="80"
        [maxWidth]="90"
        [resizeable]="true"
        [draggable]="tableMode != 'grind'"
        *ngIf="
        col.name == 'registration_time' &&
          showColumns.includes('registration_time')
        "
        name="start"
      >
        <ng-template let-column="column" ngx-datatable-header-template>
          <span class="datatable-header-cell-wrapper">
            <span class="datatable-header-cell-label draggable"
              ><i
                container="body"
                *ngIf="tableMode != 'requested' && tableMode != 'requested-log' && tableMode != 'embedded'"
                (click)="onClickOrder('register_time')"
                [class]="getSortClass('register_time')"
                tooltip="{{ 'reorder_column' | translate }}"
                triggers="hover"
                placement="right"
              ></i>
              {{ "reg_time" | translate }}</span
            >
          </span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <div style="width: 100%; text-align: center">
            {{
              (row["metadata"] &&
                row["metadata"]["PSTART"] &&
                utcToLocal(row["metadata"]["PSTART"], false)) ||
                "-"
            }}
          </div>
        </ng-template>
      </ngx-datatable-column>
      <!-- BUY-IN -->
      <ngx-datatable-column
        [minWidth]="80"
        [width]="80"
        [maxWidth]="100"
        [resizeable]="true"
        [draggable]="tableMode != 'grind'"
        *ngIf="col.name == 'buy_in' && showColumns.includes('buy_in')"
        name="buy_in"
      >
        <ng-template let-column="column" ngx-datatable-header-template>
          <span class="datatable-header-cell-wrapper">
            <span class="datatable-header-cell-label draggable"
              ><i
                container="body"
                *ngIf="tableMode != 'requested' && tableMode != 'requested-log' && tableMode != 'embedded'"
                (click)="onClickOrder('buy_in')"
                [class]="getSortClass('buy_in')"
                tooltip="{{ 'reorder_column' | translate }}"
                triggers="hover"
                placement="right"
              ></i>
              {{ "buy_in" | translate }}</span
            >
          </span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <div style="width: 100%; text-align: center" [ngClass]="{'text-warning': tableMode == 'rebalance' && (isFilterDifferent(row, savedFilters[row['scheduled_filter_id']])['buy_in_min'] || isFilterDifferent(row, savedFilters[row['scheduled_filter_id']])['buy_in_max'])}">
            {{ isoToSym[row["currency"]] }}{{ row["buy_in"] }} <span *ngIf="row['currency'] != 'USD'"> / </span><span *ngIf="row['currency'] != 'USD'" class="converted-currency">${{ getDollar(row, 'buy_in') != NaN ? getDollar(row, 'buy_in') : '...' }}</span>
          </div>
          <ng-container *ngIf="tableMode == 'rebalance' && !isTorNull(row['old_tournament']) && row['old_tournament']['buy_in']">
            <div *ngIf="!isTorNull(row['old_tournament'])" class="old-tournament-row" style="width: 100%; text-align: center" [ngClass]="{'text-warning': (isFilterDifferent(row['old_tournament'], savedFilters[row['scheduled_filter_id']])['buy_in_min'] || isFilterDifferent(row['old_tournament'], savedFilters[row['scheduled_filter_id']])['buy_in_max'])}">
              {{ isoToSym[row["currency"]] }}{{ row['old_tournament']["buy_in"] }} <span *ngIf="row['currency'] != 'USD'"> / </span><span *ngIf="row['currency'] != 'USD'" class="converted-currency">${{ getDollar(row['old_tournament'], 'buy_in') != NaN ? getDollar(row['old_tournament'], 'buy_in') : '...' }}</span>
            </div>
          </ng-container>
          <ng-container *ngIf="tableMode == 'rebalance' && !isTorNull(row['old_tournament']) && !row['old_tournament']['buy_in']">
            <div class="old-tournament-row" style="width: 100%; text-align: center" [ngClass]="{'text-warning': (isFilterDifferent(row, savedFilters[row['scheduled_filter_id']])['buy_in_min'] || isFilterDifferent(row, savedFilters[row['scheduled_filter_id']])['buy_in_max'])}">
              {{ isoToSym[row["currency"]] }}{{ row["buy_in"] }} <span *ngIf="row['currency'] != 'USD'"> / </span><span *ngIf="row['currency'] != 'USD'" class="converted-currency">${{ getDollar(row, 'buy_in') != NaN ? getDollar(row, 'buy_in') : '...' }}</span>
            </div>
          </ng-container>
        </ng-template>
      </ngx-datatable-column>
      <!-- NAME -->
      <ngx-datatable-column
        [draggable]="tableMode != 'grind'"
        [resizeable]="true"
        [minWidth]="310"
        [width]="tableMode == 'grind' ? 330 : innerWidth > 1900 ? 530 : 330"
        *ngIf="col.name == 'name' && showColumns.includes('name')"
        name="name"
      >
        <ng-template let-column="column" ngx-datatable-header-template>
          <span class="datatable-header-cell-wrapper">
            <span class="datatable-header-cell-label draggable"
              ><i
                container="body"
                *ngIf="tableMode != 'requested' && tableMode != 'requested-log' && tableMode != 'embedded'"
                (click)="onClickOrder('name')"
                [class]="getSortClass('name')"
                tooltip="{{ 'reorder_column' | translate }}"
                triggers="hover"
                placement="right"
              ></i>
              {{ "name" | translate }}</span
            >
          </span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <button
            *ngIf="tableMode != 'embedded'"
            class="btn btn-sm btn-outline-secondary btn-copy-name mr-1"
            (click)="openLobby($event, row)"
            tabindex="-1"
            [style.marginTop]="tableMode == 'grind' ? '-4px' : '-3px'"
          >
            <i
            class="fa"
            [ngClass]="siteCanOpenLobby.has(row['site']) ? 'fa-rocket' : 'fa-copy'"
            ></i>
          </button>
          <div
            #scrollContainer
            class="scroll-container"
            [style.paddingTop]="tableMode == 'grind' ? '5px' : tableMode == '7px' ? '0.25rem' : null"
            [style.marginTop]="tableMode == 'grind' ? '-5px' : tableMode == 'embedded' ? '-7px' : null"
            [ngClass]="{ 'container-with-btn': tableMode == 'grind' }"
          >
            <div #textContainer>
              <ng-template
                [ngIf]="row['variant'] == 'Knockout' && !nameContainsMB(row['name'])"
              >
                <span>
                  <img
                    container="body" tooltip="KO" trigger="hover"
                    class="ko-image mr-1"
                    [style.filter]="(row['isPlayList'] && !isSimpleMode) ? 'none' : null"
                    [style.height]="tableMode == 'grind' ? '24px' : null"
                    [style.marginTop]="tableMode == 'grind' ? '-5px' : '-4px'"
                    src="../assets/img/util/boxing-gloves.png"
                  />
                </span>
              </ng-template>
              <ng-template
                [ngIf]="nameContainsMB(row['name'])"
              >
                <span>
                  <img
                    container="body"
                    class="mb-image mr-1"
                    [style.filter]="(row['isPlayList'] && !isSimpleMode) ? 'none' : null"
                    [style.height]="tableMode == 'grind' ? '24px' : null"
                    [style.marginTop]="tableMode == 'grind' ? '-5px' : '-4px'"
                    src="../assets/img/util/treasure-chest.png"
                    tooltip="Mystery Bounty"
                    trigger="hover"
                  />
                </span>
              </ng-template>
              <span class="badge bg-warning" *ngIf="tableMode == 'addTournaments' && row['owner_id']">custom</span>
              <span [ngStyle]="styleTournamentName(row['name'], row['site'], row['color'])" [ngClass]="{'shadow-text-dark': openComment == row['id'] && isDarkTheme}" [style.textTransform]="tableMode != 'addTournaments' ? 'uppercase' : ''">{{
                getRowName(row)
              }}</span>
              <ng-container>
                <span *ngIf="row['prize_pool'] > 0" style="font-weight: bold">
                  {{ formatMoney(row["prize_pool"]) + ' GTD' }} -
                </span>
                <span [ngClass]="{'text-warning': tableMode == 'rebalance' && isFilterDifferent(row, savedFilters[row['scheduled_filter_id']])['players']}">{{ row["players"] }}</span>
              </ng-container>
            </div>
          </div>
          <ng-container *ngIf="tableMode == 'rebalance'">
            <div class="old-tournament-row">
              <div
                #scrollContainer
                class="scroll-container"
                style="margin-left: 34px;"
                *ngIf="!isTorNull(row['old_tournament'])"
              >
                <div #textContainer>
                  <ng-template
                    [ngIf]="row['variant'] == 'Knockout' && !nameContainsMB(row['name'])"
                  >
                    <span>
                      <img
                        container="body" tooltip="KO" trigger="hover"
                        class="ko-image mr-1"
                        [style.height]="tableMode == 'grind' ? '24px' : null"
                        [style.marginTop]="tableMode == 'grind' ? '-5px' : '-4px'"
                        src="../assets/img/util/boxing-gloves.png"
                      />
                    </span>
                  </ng-template>
                  <ng-template
                    [ngIf]="nameContainsMB(row['name'])"
                  >
                    <span>
                      <img
                        container="body"
                        class="mb-image mr-1"
                        [style.height]="tableMode == 'grind' ? '24px' : null"
                        [style.marginTop]="tableMode == 'grind' ? '-5px' : '-4px'"
                        src="../assets/img/util/treasure-chest.png"
                        tooltip="Mystery Bounty"
                        trigger="hover"
                      />
                    </span>
                  </ng-template>
                  <span [ngStyle]="styleTournamentName(row['name'], row['site'], row['color'])" [ngClass]="{'shadow-text-dark': openComment == row['id'] && isDarkTheme}" [style.textTransform]="tableMode != 'addTournaments' ? 'uppercase' : ''">{{
                    getRowName(row)
                  }}</span>
                  <ng-container>
                    <span style="font-weight: bold">
                      {{ (row['old_tournament']["prize_pool"] && row['old_tournament']["prize_pool"] > 0 && row["prize_pool"] != row['old_tournament']["prize_pool"] ? formatMoney(row['old_tournament']["prize_pool"]) : formatMoney(row["prize_pool"])) + ' GTD' }} -
                    </span>
                    <span [ngClass]="{'text-warning': (row['old_tournament']['players'] && row['old_tournament']['players'] != row['players'] && isFilterDifferent(row['old_tournament'], savedFilters[row['scheduled_filter_id']])['players']) || (isFilterDifferent(row, savedFilters[row['scheduled_filter_id']])['players']) }">{{ row['old_tournament']["players"] && row['old_tournament']["players"] != row["players"] ? row['old_tournament']["players"] : row["players"] }}</span>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-template>
      </ngx-datatable-column>

      <!-- VARIANT -->
      <ngx-datatable-column
        [minWidth]="80"
        [width]="80"
        [maxWidth]="90"
        [resizeable]="true"
        [draggable]="tableMode != 'grind'"
        *ngIf="col.name == 'variant' && showColumns.includes('variant')"
        name="variant"
      >
        <ng-template let-column="column" ngx-datatable-header-template>
          <span class="datatable-header-cell-wrapper">
            <span class="datatable-header-cell-label draggable">
              {{ "variant" | translate }}
            </span>
          </span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <div style="width: 100%; text-align: center;" [ngClass]="{'text-warning': tableMode == 'rebalance' && isFilterDifferent(row, savedFilters[row['scheduled_filter_id']])['variant']}">
            <div style="width: 100%; text-align: center">
              {{ row['variant'] }}
            </div>
          </div>

          <ng-container *ngIf="tableMode == 'rebalance' && !isTorNull(row['old_tournament']) && row['old_tournament']['variant']">
            <div *ngIf="!isTorNull(row['old_tournament'])" class="old-tournament-row" [ngClass]="{'text-warning': isFilterDifferent(row['old_tournament'], savedFilters[row['scheduled_filter_id']])['variant']}">
              <div style="width: 100%; text-align: center;">
                <div style="width: 100%; text-align: center">
                  {{ row['old_tournament']['variant'] }}
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="tableMode == 'rebalance' && !isTorNull(row['old_tournament']) && !row['old_tournament']['variant']">
            <div class="old-tournament-row" [ngClass]="{'text-warning': isFilterDifferent(row, savedFilters[row['scheduled_filter_id']])['variant']}">
              <div style="width: 100%; text-align: center;">
                <div style="width: 100%; text-align: center">
                  {{ row['variant'] }}
                </div>
              </div>
            </div>
          </ng-container>
        </ng-template>
      </ngx-datatable-column>

      <!-- PRIZE POOL -->
      <ngx-datatable-column
        [minWidth]="100"
        [width]="100"
        [maxWidth]="110"
        [resizeable]="true"
        [draggable]="tableMode != 'grind'"
        *ngIf="col.name == 'prize_pool' && showColumns.includes('prize_pool')"
        name="prize_pool"
      >
        <ng-template let-column="column" ngx-datatable-header-template>
          <span class="datatable-header-cell-wrapper">
            <span class="datatable-header-cell-label draggable"
              ><i
                container="body"
                *ngIf="tableMode != 'requested' && tableMode != 'requested-log' && tableMode != 'embedded'"
                (click)="onClickOrder('prize_pool')"
                [class]="getSortClass('prize_pool')"
                tooltip="{{ 'reorder_column' | translate }}"
                triggers="hover"
                placement="right"
              ></i>
              {{ "prize_pool" | translate }}</span
            >
          </span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <div style="width: 100%; text-align: center; font-weight: bold;" [ngClass]="{'text-warning': tableMode == 'rebalance' && (isFilterDifferent(row, savedFilters[row['scheduled_filter_id']])['prize_pool_min'] || isFilterDifferent(row, savedFilters[row['scheduled_filter_id']])['prize_pool_max'])}">
            <div style="width: 100%; text-align: center">
              {{ isoToSym[row["currency"]] }}{{ row["prize_pool"] > 0 ? formatMoney(row["prize_pool"]) : '-' }} <span *ngIf="row['currency'] != 'USD'"> / </span><span *ngIf="row['currency'] != 'USD'" class="converted-currency">${{ !formatMoney(getDollar(row, 'prize_pool')).includes('NaN') ? formatMoney(getDollar(row, 'prize_pool')) : '...' }}</span>
            </div>
          </div>

          <ng-container *ngIf="tableMode == 'rebalance' && !isTorNull(row['old_tournament']) && row['old_tournament']['prize_pool']">
            <div *ngIf="!isTorNull(row['old_tournament'])" class="old-tournament-row" [ngClass]="{'text-warning': (isFilterDifferent(row['old_tournament'], savedFilters[row['scheduled_filter_id']])['prize_pool_min'] || isFilterDifferent(row['old_tournament'], savedFilters[row['scheduled_filter_id']])['prize_pool_max'])}">
              <div style="width: 100%; text-align: center; font-weight: bold;">
                <div style="width: 100%; text-align: center">
                  {{ isoToSym[row["currency"]] }}{{ row['old_tournament']["prize_pool"] > 0 ? formatMoney(row['old_tournament']["prize_pool"]) : '-' }} <span *ngIf="row['currency'] != 'USD'"> / </span><span *ngIf="row['currency'] != 'USD'" class="converted-currency">${{ !formatMoney(getDollar(row['old_tournament'], 'prize_pool')).includes('NaN') ? formatMoney(getDollar(row['old_tournament'], 'prize_pool')) : '...' }}</span>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="tableMode == 'rebalance' && !isTorNull(row['old_tournament']) && !row['old_tournament']['prize_pool']">
            <div class="old-tournament-row" [ngClass]="{'text-warning': (isFilterDifferent(row, savedFilters[row['scheduled_filter_id']])['prize_pool_min'] || isFilterDifferent(row, savedFilters[row['scheduled_filter_id']])['prize_pool_max'])}">
              <div style="width: 100%; text-align: center; font-weight: bold;">
                <div style="width: 100%; text-align: center">
                  {{ isoToSym[row["currency"]] }}{{ row["prize_pool"] > 0 ? formatMoney(row["prize_pool"]) : '-' }} <span *ngIf="row['currency'] != 'USD'"> / </span><span *ngIf="row['currency'] != 'USD'" class="converted-currency">${{ !formatMoney(getDollar(row, 'prize_pool')).includes('NaN') ? formatMoney(getDollar(row, 'prize_pool')) : '...' }}</span>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-template>
      </ngx-datatable-column>

      <!-- QTE MAX REBUY -->
      <ngx-datatable-column
      [minWidth]="90"
      [width]="90"
      [maxWidth]="100"
      [resizeable]="true"
      [draggable]="tableMode != 'grind'"
      *ngIf="col.name == 'max_rebuy' && showColumns.includes('max_rebuy')"
      name="max_rebuy"
      >
      <ng-template let-column="column" ngx-datatable-header-template>
        <span class="datatable-header-cell-wrapper">
          <span class="datatable-header-cell-label draggable ml-1">
            {{ 'max_rebuy' | translate }}</span
          >
        </span>
      </ng-template>
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        <div style="width: 100%; text-align: center;">
          <span>{{ row['scheduled'] || tableMode != 'tournaments' ? getRowMaxRebuy(row) != null && getRowMaxRebuy(row) > -1 ? getRowMaxRebuy(row) : '-' : '-' }}</span>
        </div>
      </ng-template>
      </ngx-datatable-column>

      <!-- NRO BLIND -->
      <ngx-datatable-column
        [minWidth]="80"
        [width]="80"
        [maxWidth]="90"
        [resizeable]="true"
        [draggable]="tableMode != 'grind'"
        [cellClass]="getCellBlindClass"
        *ngIf="col.name == 'blinds' && showColumns.includes('blinds')"
        name="current_blind"
      >
        <ng-template let-column="column" ngx-datatable-header-template>
          <span class="datatable-header-cell-wrapper">
            <span class="datatable-header-cell-label draggable"
              ><i
                container="body"
                *ngIf="tableMode != 'requested' && tableMode != 'requested-log' && tableMode != 'embedded'"
                (click)="onClickOrder('current_blind')"
                [class]="getSortClass('current_blind')"
                tooltip="{{ 'reorder_column' | translate }}"
                triggers="hover"
                placement="right"
              ></i>
              {{ "blinds" | translate }}</span
            >
          </span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <div style="width: 100%; text-align: center">
            {{ getCurrentBlind(row["current_blind"]) }}
          </div>
        </ng-template>
      </ngx-datatable-column>
      <!-- SPEED -->
      <ngx-datatable-column
        [minWidth]="100"
        [width]="100"
        [maxWidth]="120"
        [draggable]="tableMode != 'grind'"
        *ngIf="col.name == 'speed' && showColumns.includes('speed')"
        name="speed"
      >
        <ng-template let-column="column" ngx-datatable-header-template>
          <span class="datatable-header-cell-wrapper">
            <span class="datatable-header-cell-label draggable"
              ><i
                container="body"
                *ngIf="tableMode != 'requested' && tableMode != 'requested-log' && tableMode != 'embedded'"
                (click)="onClickOrder('speed')"
                [class]="getSortClass('speed')"
                tooltip="{{ 'reorder_column' | translate }}"
                triggers="hover"
                placement="right"
              ></i>
              {{ "speed" | translate }}</span
            >
          </span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <i class="fa mr-1 icon-speed" [ngClass]="{'fa-caret-right': value == 'Slow' || value == 'Regular', 'fa-step-forward': value == 'Turbo', 'fa-forward': value == 'Hyper-Turbo'}"></i><span [ngClass]="{'text-warning': tableMode == 'rebalance' && isFilterDifferent(row, savedFilters[row['scheduled_filter_id']])['speed']}">{{ value == "Hyper-Turbo" ? "Hyper" : value }}</span>
          <ng-container *ngIf="tableMode == 'rebalance' && !isTorNull(row['old_tournament']) && row['old_tournament']['speed']">
            <div *ngIf="!isTorNull(row['old_tournament'])" class="old-tournament-row" [ngClass]="{'text-warning': isFilterDifferent(row['old_tournament'], savedFilters[row['scheduled_filter_id']])['speed']}">
              <i class="fa mr-1 icon-speed" [ngClass]="{'fa-caret-right': row['old_tournament']['speed'] == 'Slow' || row['old_tournament']['speed'] == 'Regular', 'fa-step-forward': row['old_tournament']['speed'] == 'Turbo', 'fa-forward': row['old_tournament']['speed'] == 'Hyper-Turbo'}"></i>{{ row['old_tournament']['speed'] == "Hyper-Turbo" ? "Hyper" : row['old_tournament']['speed'] }}
            </div>
          </ng-container>
          <ng-container *ngIf="tableMode == 'rebalance' && !isTorNull(row['old_tournament']) && !row['old_tournament']['speed']">
            <div class="old-tournament-row" [ngClass]="{'text-warning': isFilterDifferent(row, savedFilters[row['scheduled_filter_id']])['speed']}">
              <i class="fa mr-1 icon-speed" [ngClass]="{'fa-caret-right': row['speed'] == 'Slow' || row['speed'] == 'Regular', 'fa-step-forward': row['speed'] == 'Turbo', 'fa-forward': row['speed'] == 'Hyper-Turbo'}"></i>{{ row['speed'] == "Hyper-Turbo" ? "Hyper" : row['speed'] }}
            </div>
          </ng-container>
        </ng-template>
      </ngx-datatable-column>
      <!-- GAME -->
      <ngx-datatable-column
        [minWidth]="100"
        [width]="100"
        [maxWidth]="120"
        [draggable]="tableMode != 'grind'"
        *ngIf="col.name == 'game' && showColumns.includes('game')"
        name="game"
      >
        <ng-template let-column="column" ngx-datatable-header-template>
          <span class="datatable-header-cell-wrapper">
            <span class="datatable-header-cell-label draggable"
              ><i
                container="body"
                *ngIf="tableMode != 'requested' && tableMode != 'requested-log' && tableMode != 'embedded'"
                (click)="onClickOrder('game')"
                [class]="getSortClass('game')"
                tooltip="{{ 'reorder_column' | translate }}"
                triggers="hover"
                placement="right"
              ></i>
              {{ "game" | translate }}</span
            >
          </span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <div style="width: 100%; text-align: center;" [ngClass]="{'text-warning': tableMode == 'rebalance' && isFilterDifferent(row, savedFilters[row['scheduled_filter_id']])['game']}">
            {{ getGameText(value) }}
          </div>
          <ng-container *ngIf="tableMode == 'rebalance' && !isTorNull(row['old_tournament']) && row['old_tournament']['game']">
            <div *ngIf="!isTorNull(row['old_tournament'])" class="old-tournament-row" [ngClass]="{'text-warning': isFilterDifferent(row['old_tournament'], savedFilters[row['scheduled_filter_id']])['game']}">
              <div style="width: 100%; text-align: center;">
                {{ row['old_tournament']['game'] == 1 ? 'NLH' : row['old_tournament']['game'] == 2 ? 'Omaha' : 'Unknown' }}
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="tableMode == 'rebalance' && !isTorNull(row['old_tournament']) && !row['old_tournament']['game']">
            <div class="old-tournament-row" [ngClass]="{'text-warning': isFilterDifferent(row, savedFilters[row['scheduled_filter_id']])['game']}">
              <div style="width: 100%; text-align: center;">
                {{ row['game'] == 1 ? 'NLH' : row['game'] == 2 ? 'Omaha' : 'Unknown' }}
              </div>
            </div>
          </ng-container>
        </ng-template>
      </ngx-datatable-column>
      <!-- STATE -->
      <ngx-datatable-column
        [minWidth]="tableMode == 'grind' ? 110 : tableMode == 'requested' || tableMode == 'requested-log' || tableMode == 'rebalance' ? 90 : 140"
        [width]="tableMode == 'grind' ? 110 : tableMode == 'requested' || tableMode == 'requested-log' || tableMode == 'rebalance' ? 90 : 140"
        [maxWidth]="150"
        [resizeable]="true"
        [draggable]="tableMode != 'grind'"
        *ngIf="col.name == 'state' && showColumns.includes('state')"
        name="late_state"
      >
        <ng-template let-column="column" ngx-datatable-header-template>
          <span class="datatable-header-cell-wrapper">
            <span class="datatable-header-cell-label draggable"
              ><i
                container="body"
                *ngIf="tableMode != 'requested' && tableMode != 'requested-log'"
                (click)="onClickOrder('state')"
                [class]="getSortClass('state')"
                tooltip="{{ 'reorder_column' | translate }}"
                triggers="hover"
                placement="right"
              ></i>
              {{ "state" | translate }}</span
            >
          </span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <ng-container *ngIf="tableMode != 'grind'">
            {{ row["late_state"] }}
            <i
              container="body"
              *ngIf="
                !row['is_disabled'] &&
                (row['site'] == 4)
              "
              class="fa fa-warning glow"
              style="color: #e8ad0e"
              tooltip="{{ 'approximation_tooltip' | translate }}"
              triggers="hover"
              placement="left"
            ></i>
            <ng-container *ngIf="tableMode == 'rebalance'">
              <div *ngIf="!isTorNull(row['old_tournament'])" class="old-tournament-row" style="width: 100%; text-align: center">
                -
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="tableMode == 'grind'">
            <span
              class="late-reg-size text-center"
              [class.playing]="row['playing']"
              *ngIf="
                minutesLeft(row['start'], row['late_register']) > 3;
                else finalCountdown
              "
              >{{ tournament.getState(row, tableMode == 'addTournaments' || tableMode == 'addTournamentsTier' || tableMode == 'addTournamentsTools' ? false : true) }}</span
            >
          </ng-container>
          <ng-template #finalCountdown>
            <span
              class="late-reg-size text-center end-late-reg"
              [class.playing]="row['playing']"
              >{{ tournament.getState(row, tableMode == 'addTournaments' || tableMode == 'addTournamentsTier' || tableMode == 'addTournamentsTools' ? false : true) }}</span
            >
          </ng-template>
        </ng-template>
      </ngx-datatable-column>
      <!-- FIELD -->
      <ngx-datatable-column
        [minWidth]="70"
        [width]="70"
        [maxWidth]="90"
        [resizeable]="true"
        [draggable]="tableMode != 'grind'"
        *ngIf="col.name == 'field' && showColumns.includes('field')"
        name="field"
      >
        <ng-template let-column="column" ngx-datatable-header-template>
          <span class="datatable-header-cell-wrapper">
            <span class="datatable-header-cell-label draggable"
              ><i
                container="body"
                *ngIf="tableMode != 'requested' && tableMode != 'requested-log' && tableMode != 'embedded'"
                (click)="onClickOrder('field')"
                [class]="getSortClass('field')"
                tooltip="{{ 'reorder_column' | translate }}"
                triggers="hover"
                placement="right"
              ></i>
              {{ "field" | translate }}</span
            >
          </span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <div style="width: 100%; text-align: center" [ngClass]="{'text-warning': tableMode == 'rebalance' && (isFilterDifferent(row, savedFilters[row['scheduled_filter_id']])['min_participants'] || isFilterDifferent(row, savedFilters[row['scheduled_filter_id']])['field'])}">
            {{ row["field"] > 0 ? row["field"] : "-" }}
          </div>
          <ng-container *ngIf="tableMode == 'rebalance' && !isTorNull(row['old_tournament']) && row['old_tournament']['field']">
            <div *ngIf="!isTorNull(row['old_tournament'])" class="old-tournament-row" [ngClass]="{'text-warning': (isFilterDifferent(row['old_tournament'], savedFilters[row['scheduled_filter_id']])['min_participants'] || isFilterDifferent(row['old_tournament'], savedFilters[row['scheduled_filter_id']])['field'])}">
              <div style="width: 100%; text-align: center; font-weight: bold;">
                {{ row['old_tournament']['field'] > 0 ? row['old_tournament']['field'] : "-" }}
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="tableMode == 'rebalance' && !isTorNull(row['old_tournament']) && !row['old_tournament']['field']">
            <div class="old-tournament-row" [ngClass]="{'text-warning': (isFilterDifferent(row, savedFilters[row['scheduled_filter_id']])['min_participants'] || isFilterDifferent(row, savedFilters[row['scheduled_filter_id']])['field'])}">
              <div style="width: 100%; text-align: center; font-weight: bold;">
                {{ row['field'] > 0 ? row['field'] : "-" }}
              </div>
            </div>
          </ng-container>
        </ng-template>
      </ngx-datatable-column>
      <!-- DURATION -->
      <ngx-datatable-column
        [minWidth]="100"
        [width]="100"
        [maxWidth]="120"
        [resizeable]="true"
        [draggable]="tableMode != 'grind'"
        *ngIf="col.name == 'duration' && showColumns.includes('duration')"
        name="average_duration"
      >
        <ng-template let-column="column" ngx-datatable-header-template>
          <span class="datatable-header-cell-wrapper">
            <span class="datatable-header-cell-label draggable"
              >
              {{ "end_time" | translate }}</span
            >
          </span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <div style="width: 100%; text-align: center">
            {{
              row["average_duration"] > 0
                ? "≈ " + getHoursAndMinutes(row["start"], row["average_duration"])
                : "-"
            }}
          </div>
          <ng-container *ngIf="tableMode == 'rebalance'">
            <div *ngIf="!isTorNull(row['old_tournament'])" class="old-tournament-row" style="width: 100%; text-align: center">
              -
            </div>
          </ng-container>
        </ng-template>
      </ngx-datatable-column>
      <!-- ABILITY -->
      <ngx-datatable-column
        [minWidth]="90"
        [width]="90"
        [maxWidth]="110"
        [resizeable]="true"
        [draggable]="tableMode != 'grind'"
        [cellClass]="getCellAbilityClass"
        *ngIf="col.name == 'ability' && showColumns.includes('ability')"
        name="av_ability"
      >
        <ng-template let-column="column" ngx-datatable-header-template>
          <span class="datatable-header-cell-wrapper">
            <span class="datatable-header-cell-label draggable"
              ><i
                container="body"
                *ngIf="tableMode != 'requested' && tableMode != 'requested-log' && tableMode != 'embedded'"
                (click)="onClickOrder('av_ability')"
                [class]="getSortClass('av_ability')"
                tooltip="{{ 'reorder_column' | translate }}"
                triggers="hover"
                placement="right"
              ></i>
              {{ "ability" | translate }}</span
            >
          </span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <div style="width: 100%; text-align: center">
            {{ row["av_ability"] | number:'1.0-1' }}
          </div>
        </ng-template>
      </ngx-datatable-column>
      <!-- GROUP_ID -->
      <ngx-datatable-column
        [minWidth]="80"
        [width]="80"
        [maxWidth]="90"
        [resizeable]="true"
        [draggable]="tableMode != 'grind'"
        *ngIf="col.name == 'group_id' && showColumns.includes('group_id')"
        name="group_id"
      >
        <ng-template let-column="column" ngx-datatable-header-template>
          <span class="datatable-header-cell-wrapper">
            <span class="datatable-header-cell-label draggable ml-1">
              Group ID</span
            >
          </span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          {{ getLastFourGroupId(row["group_id"]) }}
        </ng-template>
      </ngx-datatable-column>

    </ng-container>

    <!-- APPROVE/DECLINE REQUESTS -->
    <ngx-datatable-column
      *ngIf="tableMode === 'requested'"
      [width]="170"
      [canAutoResize]="false"
      [draggable]="false"
      name="action"
    >
      <ng-template let-column="column" ngx-datatable-header-template>
        <span class="datatable-header-cell-wrapper"> </span>
      </ng-template>
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        <div style="width: 100%; text-align: center">
          <button
            class="btn btn-sm btn-success mr-1"
            (click)="
              onClickOpenAcceptRequest(row, requestUserId, requestTierId)
            "
          >
            <i class="fa fa-check-square-o"></i> {{ "approve" | translate }}
          </button>
          <button
            class="btn btn-sm btn-danger"
            (click)="
              onClickOpenDeclineRequest(row, requestUserId, requestTierId)
            "
          >
            <i class="fa fa-close"></i> {{ "decline" | translate }}
          </button>
        </div>
      </ng-template>
    </ngx-datatable-column>

    <!-- APPROVE/DECLINE REBALANCE -->
    <ngx-datatable-column
      *ngIf="tableMode === 'rebalance'"
      [width]="170"
      [canAutoResize]="true"
      [draggable]="false"
      name="action"
    >
      <ng-template let-column="column" ngx-datatable-header-template>
        <span class="datatable-header-cell-label">
          <div style="width: 100%; text-align: center">
            <button
              container="body"
              class="btn btn-sm btn-dark mr-1"
              (click)="onClickIgnoreRebalance(null, true)"
              tooltip="{{ 'mass_ignore_rebalance_button' | translate }}"
              triggers="hover"
              placement="bottom"
              [disabled]="countSelected == 0"
            >
              {{ "ignore" | translate }}
            </button>
            <button
              container="body"
              class="btn btn-sm btn-danger"
              (click)="onClickRemoveRebalance(null, true)"
              tooltip="{{ 'mass_remove_rebalance_button' | translate }}"
              triggers="hover"
              placement="bottom"
              [disabled]="countSelected == 0"
            >
              <i class="fa fa-close"></i> {{ "remove" | translate }}
            </button>
          </div>
        </span>
      </ng-template>
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        <div style="width: 100%; text-align: center;">
          <button
            class="btn btn-sm btn-dark mr-1 guide-ignore"
            (click)="onClickIgnoreRebalance(row['id'])"
            tooltip="{{ 'ignore_rebalance_button' | translate }}"
            triggers="hover"
          >
            {{ "ignore" | translate }}
          </button>
          <button
            class="btn btn-sm btn-danger guide-remove"
            (click)="onClickRemoveRebalance(row['id'])"
            tooltip="{{ 'remove_rebalance_button' | translate }}"
            triggers="hover"
          >
            <i class="fa fa-close"></i> {{ "remove" | translate }}
          </button>
        </div>
        <div style="width: 100%; text-align: center; margin-top: 5px; position: relative; z-index: 998;" class="guide-filter-name" [ngClass]="{'text-success': row['scheduled_filter_id'] && savedFilters[row['scheduled_filter_id']]?.deleted == false, 'text-danger': row['scheduled_filter_id'] && savedFilters[row['scheduled_filter_id']]?.deleted == true}">
          <ng-template #filterTemplate>
            <div>
              <strong>{{('filters_by' | translate)}}</strong>
              <br>
              <strong>{{ savedFilters[row['scheduled_filter_id']]?.user_name }}</strong>
              <br>
              <br>
              <ng-container *ngFor="let filter of savedFilters[row['scheduled_filter_id']].data | keyvalue">
                <div [ngClass]="{'text-warning': isFilterDifferent(row, savedFilters[row['scheduled_filter_id']])[filter.key] || isFilterDifferent(row['old_tournament'], savedFilters[row['scheduled_filter_id']])[filter.key]}">{{ getFilterBadgeText(filter, true) }}</div>
              </ng-container>
            </div>
          </ng-template>
          <div container="body" *ngIf="row['scheduled_filter_id']" [tooltip]="filterTemplate" trigger="hover" placement="bottom">{{ 'filter' | translate }}: {{ savedFilters[row['scheduled_filter_id']]?.data.name }} {{ savedFilters[row['scheduled_filter_id']]?.deleted ? ('- ' + ('deleted' | translate)) : '' }}</div>
        </div>
        <div *ngIf="row['scheduled_filter_id']" class="guide-cause" style="width: 100%; text-align: center; position: relative; white-space: normal;">
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-footer></ngx-datatable-footer>
  </ngx-datatable>

  <!-- Modal Red Flag -->
  <div
    bsModal
    #modalRedFlag="bs-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-danger" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">
            {{ "confirm_red_flag" | translate }}
          </h4>
          <button
            type="button"
            class="close"
            (click)="modalRedFlag.hide()"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>{{ allFlagged ? ("confirm_red_flag_uncheck" | translate) : ("confirm_red_flag_check" | translate) }}</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            (click)="modalRedFlag.hide()"
          >
            {{ "cancel" | translate }}
          </button>
          <button
            type="button"
            class="btn btn-danger"
            (click)="modalRedFlag.hide(); flagAllTournaments(!allFlagged)"
          >
            {{ "confirm" | translate }}
          </button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
  <!-- /.modal -->
</div>

<div [@inOutFloatBar] class="options-bar-container" *ngIf="countSelected > 0 && tableMode == 'tournaments'">
  <div class="options-bar" style="color: #fff;">
    <div *ngIf="!selectedAllSch" class="row align-items-center justify-content-center">
      <div class="d-none d-lg-block col-auto mr-4 border-right">
        {{ countSelected }} {{ 'tournaments_selected' | translate }}
      </div>
      <div class="col-12 col-lg pl-1 pr-3">
        <button (click)="onClickScheduleTournaments()" class="btn btn-success tour-schedule-button px-4 py-1" type="button" [disabled]="bloquedFunctions">
          <i class="fa fa-plus mr-2"></i>{{ 'schedule_button' | translate }}
        </button>
      </div>
      <div class="col-auto px-1" *ngIf="isMaster && numberOfTiers > 1">
        <button
          container="body"
          class="btn btn-outline-secondary float-btn"
          tooltip="{{ 'mass_schedule_button' | translate }}"
          triggers="hover"
          style="cursor: pointer; border: none; color: #fff;"
          (click)="onClickSelectUserTier()"
        >
          <i class="fa fa-group" style="padding-left: 7px; padding-right: 7px;"></i>
        </button>
      </div>
      <div class="col-auto px-1" *ngIf="licenseType != 'free' && isMaster">
        <button
          container="body"
          class="btn btn-outline-secondary float-btn"
          (click)="modalRedFlag.show()"
          tooltip="{{ 'red_flag' | translate }}"
          triggers="hover"
          style="cursor: pointer; border: none; color: #fff;"
        >
          <i
            class="fa"
            [ngClass]="{
              'fa-flag': allFlagged,
              'text-danger': allFlagged,
              'fa-flag-o': !allFlagged
            }"
            style="padding-left: 7px; padding-right: 7px;"
          ></i>
        </button>
      </div>
      <div class="col-auto ml-3 border-left">
        <i container="body" tooltip="{{ 'uncheck_tournaments' | translate }}" triggers="hover" (click)="uncheckTournaments()" class="fa fa-window-close" style="cursor: pointer; color: #ffadad;"></i>
      </div>
    </div>
    <div *ngIf="selectedAllSch" class="row align-items-center">
      <div class="col-auto mr-4 border-right">
        {{ countSelected }} {{ 'tournaments_selected' | translate }}
      </div>
      <div class="col-auto px-1" *ngIf="isMaster">
        <button
          container="body"
          class="btn btn-outline-secondary float-btn"
          tooltip="{{ 'delete_selected_schedules' | translate }}"
          triggers="hover"
          style="cursor: pointer; border: none; color: #fff;"
          (click)="onClickOpenDelete(0)"
        >
          <i class="fa fa-trash" style="padding-left: 9px; padding-right: 9px;"></i>
        </button>
      </div>
      <div class="col-auto px-1">
        <button
          container="body"
          class="btn btn-outline-secondary float-btn"
          tooltip="{{ 'edit_selected_schedules' | translate }}"
          triggers="hover"
          style="cursor: pointer; border: none; color: #fff;"
          (click)="onClickOpenEdit(null)"
        >
          <i class="fa fa-edit" style="padding-top: 7px; padding-left: 7px; padding-right: 7px;"></i>
        </button>
      </div>
      <div class="col-auto px-1 pt-2">
        <button class="btn copy-btn btn-sm btn-primary text-white" (click)="onClickOpenEditCopy(null)">{{ 'copy' | translate }}</button>
      </div>
      <div class="col-auto ml-4 border-left">
        <i container="body" tooltip="{{ 'uncheck_tournaments' | translate }}" triggers="hover" (click)="uncheckTournaments()" class="fa fa-window-close" style="cursor: pointer; color: #ffadad;"></i>
      </div>
    </div>
  </div>
</div>
