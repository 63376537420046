<button
  [style.opacity]="hasSomeChecked() ? 1 : 0.4"
  [disabled]="!hasSomeChecked()"
  type="button"
  class="btn btn-link float-right"
  (click)="openModal(modalTemplate)"
>
  <i class="fa fa-bug"></i> {{ "report" | translate }}
</button>

<ng-template #modalTemplate>
  <div class="modal-header">
    <h4 class="modal-title">{{ "bug_report" | translate }}</h4>
    <button
      type="button"
      class="close"
      (click)="modalRef.hide()"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group row">
      <!-- Error Type -->
      <div class="col-md-12">
        <label>{{ "error_type" | translate }}</label>
        <select [(ngModel)]="bugReport.type" name="site" class="form-control">
          <option value="Hora início">{{ "start" | translate }}</option>
          <option value="Late reg">{{ "late_reg" | translate }}</option>
          <option value="Buy in">{{ "buy_in" | translate }}</option>
          <option value="Nome">{{ "name" | translate }}</option>
          <option value="Outro">{{ "other" | translate }}</option>
        </select>
      </div>

      <!-- Error Text -->
      <div *ngIf="bugReport.type == 'Outro'" class="col-md-12 mt-3">
        <label>{{ "elaborate" | translate }}</label>
        <textarea [(ngModel)]="bugReport.text" class="form-control" type="text" cols="5" rows="5"  ></textarea>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-secondary"
      (click)="modalRef.hide()"
    >
      {{ "cancel" | translate }}
    </button>
    <button
      type="button"
      class="btn btn-success"
      (click)="modalRef.hide(); onClickSubmitBugReport()"
    >
      {{ "send" | translate }}
    </button>
  </div>
</ng-template>
