<div id="welcome-page-wrap" *ngIf="active">
    <div id="welcome-page-background">
      <div class="welcome-page-content">
        <div class="keen-slider" #sliderRef>
          <div class="keen-slider__slide number-slide1">
            <div class="welcome-page-close white" (click)="close()">
              <i class="fa fa-close"></i>
            </div>
            <div class="row m-0">
              <div class="col welcome-page-full-img p-0 align-items-center d-none d-md-block">
                <img src="assets/guide/home/grind_home.png" [@inRightOutLeft] alt="lobbyze screen" />
              </div>
              <div class="col welcome-page-full-img p-0 align-items-center d-block d-md-none">
                <img src="assets/guide/home/grind_home_mobile.png" [@inRightOutLeft] alt="lobbyze screen" />
              </div>
              <div class="banner-checkbox">
                <input type="checkbox" [(ngModel)]="dontShowAgain"><span>Don't show this again</span>
              </div>
            </div>
          </div>
          <div class="keen-slider__slide number-slide2">
            <div class="welcome-page-close" (click)="close()">
              <i class="fa fa-close"></i>
            </div>
            <div class="row m-0" style="min-height: 403px;">
              <div class="col-12 col-md-6 welcome-page-img p-0 align-items-center">
                <img src="assets/guide/grind/lista.png" class="guide-img" [@inRightOutLeft] width="100%" alt="lobbyze screen" />
              </div>
              <div class="col-12 col-md-6 p-4 pt-5">
                <h1 class="mb-4" [@inLeftOutRight]>Grind Mode Updated!</h1>
                <p [@inLeftOutRight]>
                  The Grind Mode was updated and now it's easier and faster to use.
                </p>
                <ul class="mt-2" [@inLeftOutRight]>
                  <li><span>Lighter and more responsive;</span></li>
                  <li><span>Control the size and order of columns;</span></li>
                  <li><span>Sort by multiple columns at once.</span></li>
                </ul>
                <div class="mt-4 mb-3">
                  <input type="checkbox" [(ngModel)]="dontShowAgain"> Don't show this again
                </div>
                <div class="welcome-page-footer mb-2">
                  <button class="btn btn-outline-primary mr-1" style="width: 28%;" (click)="close()">Close</button>
                  <a class="btn btn-danger" style="width: 69%;" (click)="clickedVideo()" href="https://youtu.be/9SIYvxW6acw?si=15Jb3ItJ2LdY65Ju&t=467" target="_blank">Watch the guide<i class="ml-2 fa fa-play"></i></a>
                </div>
                <div class="welcome-page-steps row m-0">
                  <div class="col step active px-0"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="dots">
          <button
            (click)="slider.moveToIdx(i)"
            *ngFor="let slide of dotHelper; let i = index"
            [class]="'dot ' + (i === currentSlide ? 'active' : '')"
          ></button>
        </div>
    </div>
  </div>
</div>