import { Component, OnDestroy, OnInit } from "@angular/core";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { map, mergeMap, shareReplay, switchMap } from "rxjs/operators";
// import { Notification } from "../../interfaces/notification";
import { Router } from "@angular/router";
import { getCurrentWindow } from "@tauri-apps/api/window";
import { map as _map } from "lodash";
import { navItems } from "../../_nav";
import { AdsService } from "../../services/ads.service";
import { AuthService } from "../../services/auth.service";
import { LicenseService } from "../../services/license.service";
import { MessagingService } from "../../services/messaging.service";
import { ScheduleService } from "../../services/schedule.service";
import { TierService } from "../../services/tier.service";
import { UserService } from "../../services/user.service";

// Moment
import { ShepherdService } from "angular-shepherd";
import * as moment from "moment-timezone";
import { NgxSpinnerService } from "ngx-spinner";
import { BehaviorSubject, combineLatest, from, Observable, of } from "rxjs";
import { take } from "rxjs/operators";
import { environment } from "../../../environments";
import { User } from "../../interfaces/user";
import { Tier, TierLicense } from "../../interfaces/v2/tier";
import {
  NotificationService,
  RegisterStatus,
} from "../../services/notification.service";
import Cookies from "../../utils/cookies";
import { tour, tourRebalance, tourManageFilters } from "./tour-guide";
import posthog from "posthog-js";
import { Native } from "../../../native";
import { IsFeatureEnable } from "../../utils/experiments";
import { NativeUpdater } from "../../../native/updater";
import { ToastrService } from "ngx-toastr";
moment().tz("America/Los_Angeles").format();

@Component({
  selector: "app-dashboard",
  templateUrl: "./default-layout.component.html",
  styleUrls: ["./default-layout.component.scss"],
  providers: [
    ScheduleService,
    MessagingService,
    UserService,
    AuthService,
    LicenseService,
    TranslateService,
    ShepherdService,
  ],
})
export class DefaultLayoutComponent implements OnInit, OnDestroy {
  currentYear: number = new Date().getFullYear();

  public numNotifications = 0;

  public sidebarMinimized = true;
  public navItems: typeof navItems = [];
  public mainMarginRight = "0";
  public sideMarginRight = "-250px";
  public schedulesFN = [];

  public tourHover = false;
  public tourOptionsOpen = false;

  public rowHeight = localStorage.getItem("row-height")
    ? Number(localStorage.getItem("row-height"))
    : 46;
  public fontSize = localStorage.getItem("font-size")
    ? Number(localStorage.getItem("font-size").replace(/\D/g, ""))
    : 13;

  public notificationBellContent = {
    count: 0,
    items: [],
  };

  public notificationBellOpen = false;

  public userName: string;
  public userEmail: string;

  public licenseType: string;
  public slots: number;
  public selfLicense: boolean;
  public isFreeLocked = false;
  public isFullLocked = false;

  public tierLicense: any = null;

  public isUserVerified: boolean = false;

  // Snackbar Message
  public showSnackbar = false;
  public snackbarMessage = "";

  public notifications: Notification[] = [];
  public confirmDelete: number;

  public userData: User = null;
  public isMaster: boolean = false;

  public isDarkTheme = Cookies.get("theme-ui")
    ? JSON.parse(Cookies.get("theme-ui"))
    : true;

  public roles: Set<string> = new Set();
  public team: string;
  public tierId: number;

  private refreshTierData = new BehaviorSubject<void>(null);
  public allowNewTier$: Observable<boolean>;
  public tierList$: Observable<{tiers: Tier[], freeAvailable: boolean}>;
  public tierLicense$: Observable<TierLicense>;

  public mySubText$: Observable<string>;

  notificationStatus$: Observable<RegisterStatus>;

  newTierName: string = "";

  public tierSearch: string = "";

  public userCount: number = 0;

  public tierToDelete = { id: null, name: "" };
  public confirmDeletionText = "";

  public defaultTier = -1;
  public showTitleBar = false;

  unsubList: (() => void)[] = [];

  /* public get newNotifications(): Notification[] {
    return this.notifications.filter((notification) => !notification.viewed);
  } */

  public showUpdateIcon = false;
  public isUpdateReady = false;
  public updateDownloadProgress = "";
  public updateError = "";

  private readonly emptyAd = {
    id: null,
    title: "",
    image: "",
    link: "",
    position: "",
    start: "",
    finish: "",
    state: "disabled",
  };

  public featureTextResize$ = IsFeatureEnable("text-size-change");

  constructor(
    public auth: AuthService,
    private userService: UserService,
    private notificationService: NotificationService,
    private adsService: AdsService,
    private licenseService: LicenseService,
    private tierService: TierService,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    private guideTour: ShepherdService,
    private router: Router,
    private toastService: ToastrService
  ) {
    this.notificationStatus$ = this.notificationService.getRegistrationStatus();
  }

  public selectLanguage(lang: string) {
    this.translateService.use(lang);
    Cookies.set("lang", lang, { expires: 365 });
  }

  public getCurrentLang() {
    return this.translateService.currentLang;
  }

  toggleAside() {
    if (this.mainMarginRight == "250px") {
      this.mainMarginRight = "0";
      this.sideMarginRight = "-250px";
    } else {
      this.mainMarginRight = "250px";
      this.sideMarginRight = "0";
    }
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  logout() {
    posthog.reset();
    this.auth.logout();
  }

  showSnackMessage(msg: string) {
    this.snackbarMessage = msg;
    this.showSnackbar = true;
    setTimeout(() => {
      this.showSnackbar = false;
    }, 5000);
  }

  utcToLocal(date: string) {
    const dt = moment(date).format();
    return dt;
  }

  getHasPendingRequests() {
    this.userService
      .getPendingRequest()
      .pipe(take(1))
      .subscribe((has_pending_requests) => {
        if (this.isMaster && has_pending_requests) {
          this.notificationBellContent.count = 1;
          this.notificationBellContent.items = [
            `${this.translateService.instant("has_pending_requests")}`,
          ];
          setTimeout(() => this.getHasPendingRequests(), 10000);
        } else {
          this.notificationBellContent.count = 0;
          this.notificationBellContent.items = [];
        }
      });
  }

  async getCurrentUser() {
    const user = this.auth.user;
    this.userName = user.name;
    this.userEmail = user.email;
    this.isUserVerified = user.is_verified;
    const data = await this.userService.getCurrentUser().toPromise();
    if (this.licenseType == "") {
      if (this.selfLicense) {
        this.goToShopUrl();
      } else {
        this.goToTierLicenseErr();
      }
    }
    const roles = new Set(
      (data.role || "").split(",").map((e) => e.trim().toUpperCase())
    );
    this.roles = roles;
    this.team = data.team;
    this.tierId = data.tier_id;
    this.userData = data;
    this.isMaster = roles.has("MASTER");
    if (this.isMaster) {
      this.getHasPendingRequests();
    }
  }

  async organizeMenuItems() {
    const userData: any = this.userData;
    userData.slots = this.slots;

    this.navItems = navItems
      .filter((e) => !e.role || e.role.some((r) => this.roles.has(r)))
      .filter((e) => !e.filter || e.filter(userData));
    this.navItems.forEach((item) => {
      if (
        !this.roles.has("TA") &&
        !this.roles.has("SA") &&
        !item.isFree &&
        this.isFreeLocked
      ) {
        item.attributes = { disabled: true };
      }
      if (item.i18nkey == "tournaments") {
        item.attributes = { class: "tournaments-page" };
      } else if (item.i18nkey == "manage_schedules") {
        item.attributes = { class: "schedule-page" };
      } else if (item.i18nkey == "download_notification_app") {
        item.attributes = { class: "download-page" };
      } else if (item.i18nkey == "rebalance_schedule") {
        item.attributes = { class: "rebalance-page" };
      } else if (item.i18nkey == "manage_filters") {
        item.attributes = { class: "manage-filters-page" };
      }
      if (item.i18nkey !== undefined) {
        item.name = this.translateService.instant(item.i18nkey);
      }
    });
  }

  async goToShopUrl() {
    if (!this.router.url.startsWith("/buy")) {
      this.router.navigate(["/buy"]);
    }
  }

  async goToTierLicenseErr() {
    if (!this.router.url.startsWith("/no-license")) {
      this.router.navigate(["/no-license"]);
    }
  }

  async getUserLicense() {
    const license = await this.licenseService.getLicense();
    this.licenseType = license.license;
    this.slots = license.slots;
    this.selfLicense = license.self;
    this.isFreeLocked = await this.licenseService.isFreeLocked();
    this.isFullLocked = await this.licenseService.isFullLocked();
  }

  async sendValidation() {
    const resp = await this.userService.sendValidation().toPromise();
    if (resp.ok) {
      return;
    }
  }

  /*   async getNotifications() {
      this.notifications = await this.notificationService.get().toPromise().then((data) => data.filter((notification) => {
        const now = moment()
        const then = moment(notification.date)
        return then.isSameOrAfter(now, "minute")
      }))
    } */

  formatDate(d: string) {
    const date = new Date(d);
    return `${date.getHours().toString().padStart(2, "0")}:${date
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;
  }

  formatDateFull(date: Date) {
    return new Date(date).toLocaleString();
  }

  /*   async viewNotifications() {
      await this.notificationService
        .view(this.notifications.map((notification) => notification.id))
        .toPromise();
      this.getNotifications();
    }

    async deleteNotification(id: number) {
      if (this.confirmDelete == id) {
        await this.notificationService.dismiss([id]).toPromise();
        this.confirmDelete = undefined;
        this.getNotifications();
      } else {
        this.confirmDelete = id;
      }
    }

    async handleAction(notification: Notification, action: string) {
      await this.notificationService.handleAction(notification, action);
      this.getNotifications()
    } */

  downloadLog() {
    window.location.href = environment.API_URL + "v2/log/download";
  }

  downloadNotificationApp() {}

  WindowResize(e) {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 250);
  }

  async ngOnInit() {
    this.showTitleBar = Native.IsMainWindow();
    if (this.showTitleBar) {
      Native.setDecoration(false);
      const header = document.querySelector("app-header");
      header?.addEventListener("mousedown", (e: MouseEvent) => {
        if (e.target !== header) {
          return;
        }
        const appWindow = getCurrentWindow();
        if (e.buttons === 1) {
          // Primary (left) button
          e.detail === 2
            ? appWindow.toggleMaximize() // Maximize on double click
            : appWindow.startDragging(); // Else start dragging
        }

        addEventListener("beforeunload", () => {
          Native.setDecoration(true);
        });
      });

      const startDownload = () => {
        this.updateDownloadProgress = "";
        NativeUpdater.startDownload().then((isReady) => {
          console.log(isReady);
          if (isReady) {
            this.isUpdateReady = true;
          }
        });
      };

      this.unsubList.push(
        await NativeUpdater.listenDownloadProgress((e) => {
          if (e.type == "progress") {
            this.updateDownloadProgress =
              e.percent == -1
                ? "Downloading"
                : (e.percent * 100).toFixed(2) + "%";
          } else if (e.type == "error") {
            this.updateError = e.error;
            setTimeout(() => {
              // Retry in 1 min
              this.updateError = "";
              startDownload();
            }, 1000 * 60);
          } else if (e.type == "complete") {
            this.isUpdateReady = true;
          }
        })
      );

      this.unsubList.push(
        await NativeUpdater.listenAvailableUpdate(() => {
          this.showUpdateIcon = true;
          startDownload();
        })
      );
      this.showUpdateIcon = await NativeUpdater.hasUpdateAvailable();
      if (this.showUpdateIcon) {
        startDownload();
      }
    }

    if (this.isDarkTheme) {
      this.toggleUITheme();
    }

    if (!this.auth.isLoggedIn()) {
      this.auth.login();
      return;
    }

    const user = this.auth.user;
    if (user) {
      await this.getUserLicense();
      await this.getCurrentUser();

      posthog.identify(user.id.toString(), {
        email: user.email,
        name: user.name,
      });
      if (this.tierId != null) {
        posthog.group("tier", this.tierId.toString(), {
          id: this.tierId,
          name: this.team,
        });
      }

      // Notification.requestPermission();
      if (
        this.isMaster &&
        this.licenseType != "free" &&
        (!Cookies.get("showed-guide") ||
          (Cookies.get("showed-guide") &&
            Cookies.get("showed-guide") != "true")) &&
        !this.isShopPage()
      ) {
        this.executeNonMasterGuide();
      }
    }

    this.tierLicense$ = (
      this.isMaster ? this.tierService.getTierLicense() : of(null)
    ).pipe(
      map((data) => {
        if (!data) {
          return null;
        }
        if (data.available_slots == -1) {
          return null;
        }
        return data;
      }),
      shareReplay()
    );

    this.allowNewTier$ = this.tierLicense$.pipe(
      map((license) => {
        return (
          license &&
          license.available_slots > license.used_slots &&
          license.tiers.every((e) => e.blocking)
        );
      })
    );

    this.tierList$ = this.refreshTierData.pipe(
      switchMap(() => {
        return combineLatest([
          this.userService.listTiers(true),
          this.tierLicense$,
        ]);
      }),
      map(([data, license]) => {
        data.tiers.sort((a, b) => {
          return a.name.toLowerCase() > b.name.toLowerCase()
            ? 1
            : a.name.toLowerCase() === b.name.toLowerCase()
            ? 0
            : -1;
        });
        const licenseMap = new Map(
          ((license && license.tiers) || []).map((e) => [e.id, e])
        );
        return {
          tiers: data.tiers.map((e) => {
            this.defaultTier = e.is_default ? e.id : this.defaultTier;
            const l = licenseMap.get(e.id);
            return {
              ...e,
              member_count: l ? l.member_count : null,
            };
          }),
          freeAvailable: data.freeAvailable,
        };
      })
    );

    this.mySubText$ = of(null).pipe(
      mergeMap(() => {
        return from(this.licenseService.currentSubscription());
      }),
      map((data) => {
        if (!data.has) {
          return "No license";
        }
        let t = data.license_type;
        if (data.quantity > 1) {
          t += ` [${data.quantity}]`;
        }
        return t;
      })
    );

    // this.getAds();
    /* this.getNotifications();
    setInterval(() => this.getNotifications(), 60e3); */

    this.organizeMenuItems();

    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.navItems.forEach((item) => {
        item.name = this.translateService.instant(item.i18nkey);
      });
      this.navItems = JSON.parse(JSON.stringify(this.navItems));
    });
  }

  ngOnDestroy(): void {
    this.unsubList.forEach((e) => e());
    if (Native.IsMainWindow()) {
      Native.setDecoration(true);
    }
  }

  selectTier(tier: Tier) {
    const tierId = tier.id.toString();
    Cookies.set("tier", tierId);
    window.location.reload();
  }

  isInputDeletionValid() {
    const lang = this.translateService.currentLang;

    if (lang == "pt-BR") {
      if (this.confirmDeletionText.toUpperCase() == "CONFIRMAR") {
        return true;
      }
    } else {
      if (this.confirmDeletionText.toUpperCase() == "CONFIRM") {
        return true;
      }
    }

    return false;
  }

  async setDefaultTier(tier) {
    const originalDefault = this.defaultTier;
    this.defaultTier = tier.id;
    try {
      await this.userService.setDefaultTier(tier.id).toPromise();
      this.showSnackMessage(
        this.translateService.instant("default_tier_changed")
      );
    } catch (e) {
      this.defaultTier = originalDefault;
      this.showSnackMessage(
        this.translateService.instant("Something went wrong!")
      );
      console.log(e);
    }
  }

  async onClickDeleteTier() {
    try {
      this.spinner.show();
      await this.tierService.deleteTier(this.tierToDelete.id).toPromise();
      const currTier = Number(Cookies.get("tier"));
      if (currTier == this.tierToDelete.id) {
        var tiers = await this.userService.listTiers().toPromise();
        this.selectTier(tiers[0]);
      } else {
        window.location.reload();
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.spinner.hide();
    }
  }

  get isCreateNewTierInvalid(): boolean {
    return this.newTierName == null || this.newTierName.length <= 0;
  }

  async createNewTier() {
    if (this.newTierName == null || this.newTierName.length <= 0) {
      return;
    }
    try {
      this.spinner.show();

      const name = this.newTierName;

      const newTier: any = await this.tierService.createTier(name).toPromise();

      this.selectTier(newTier.tier);
    } finally {
      this.spinner.hide();
    }
  }

  executeMasterGuide(): void {
    this.guideTour.defaultStepOptions = tour.defaultStepOptions;
    this.guideTour.modal = true;
    this.guideTour.confirmCancel = false;
    this.guideTour.addSteps(tour.steps);
    this.guideTour.start();
  }

  executeNonMasterGuide(): void {
    var tourAux = { ...tour };
    if (this.router.url == "/tournaments") {
      tourAux.steps.forEach((element, index) => {
        if (element.id == "tournaments-page") tourAux.steps.splice(index, 1);
      });
    }

    tourAux.steps.map((e) => {
      e.text = this.translateService.instant(e.i18n_text);
      e.title = this.translateService.instant(e.i18n_title);
      _map(e.buttons, (b) => {
        if (b["i18n"]) {
          b["text"] = this.translateService.instant(b["i18n"]);
        }
      });
    });

    this.guideTour.defaultStepOptions = tourAux.defaultStepOptions;
    this.guideTour.modal = true;
    this.guideTour.confirmCancel = false;
    this.guideTour.addSteps(tourAux.steps);
    this.guideTour.start();
  }

  executeRebalanceGuide(): void {
    var tourAux = { ...tourRebalance };
    if (this.router.url == "/rebalance") {
      tourAux.steps.forEach((element, index) => {
        if (element.id == "rebalance-page") tourAux.steps.splice(index, 1);
      });
    }

    tourAux.steps.map((e) => {
      e.text = this.translateService.instant(e.i18n_text);
      e.title = this.translateService.instant(e.i18n_title);
      _map(e.buttons, (b) => {
        if (b["i18n"]) {
          b["text"] = this.translateService.instant(b["i18n"]);
        }
      });
    });

    this.guideTour.defaultStepOptions = tourAux.defaultStepOptions;
    this.guideTour.modal = true;
    this.guideTour.confirmCancel = false;
    this.guideTour.addSteps(tourAux.steps);
    this.guideTour.start();
  }

  executeManageFiltersGuide(): void {
    var tourAux = { ...tourManageFilters };
    if (this.router.url == "/manage-filters") {
      tourAux.steps.forEach((element, index) => {
        if (element.id == "manage-filters-page") tourAux.steps.splice(index, 1);
      });
    }

    tourAux.steps.map((e) => {
      e.text = this.translateService.instant(e.i18n_text);
      e.title = this.translateService.instant(e.i18n_title);
      _map(e.buttons, (b) => {
        if (b["i18n"]) {
          b["text"] = this.translateService.instant(b["i18n"]);
        }
      });
    });

    this.guideTour.defaultStepOptions = tourAux.defaultStepOptions;
    this.guideTour.modal = true;
    this.guideTour.confirmCancel = false;
    this.guideTour.addSteps(tourAux.steps);
    this.guideTour.start();
  }

  increaseRowHeight() {
    this.rowHeight += 5;
    localStorage.setItem("row-height", this.rowHeight.toString());
  }

  decreaseRowHeight() {
    this.rowHeight -= 5;
    localStorage.setItem("row-height", this.rowHeight.toString());
  }

  increaseFontSize() {
    this.fontSize += 2;
    localStorage.setItem("font-size", this.fontSize.toString() + "px");
  }

  decreaseFontSize() {
    this.fontSize -= 2;
    localStorage.setItem("font-size", this.fontSize.toString() + "px");
  }

  searchCheck(name: string): boolean {
    return name.toUpperCase().startsWith(this.tierSearch.toUpperCase());
  }

  toggleUITheme() {
    this.isDarkTheme = document.body.classList.toggle("dark-theme");
    Cookies.set("theme-ui", this.isDarkTheme.toString(), { expires: 365 });
  }

  isShopPage() {
    const path = window.location.pathname;
    return path == "/buy" || path == "/buy/small" || path == "/buy/full";
  }

  async openSubscription() {
    this.spinner.show();
    try {
      const license = await this.licenseService.currentSubscription();
      if (license.has) {
        const form = document.createElement("form");
        form.method = "POST";
        form.action = this.licenseService.subscriptionPortalUrl();
        const returnURLField = form.appendChild(
          document.createElement("input")
        );
        returnURLField.type = "hidden";
        returnURLField.name = "return_url";
        returnURLField.value = location.href;
        document.body.appendChild(form);
        form.submit();
      } else {
        this.router.navigate(["/buy"]);
        this.spinner.hide();
      }
    } catch (e) {
      this.spinner.hide();
    }
  }

  titleAction(action: string) {
    if (!this.showTitleBar) {
      return;
    }
    const window = getCurrentWindow();
    if (action === "minimize") {
      window.minimize();
    } else if (action == "maximize") {
      window.toggleMaximize();
    } else if (action == "close") {
      window.close();
    }
  }

  installUpdate() {
    NativeUpdater.installUpdate();
  }

  async leaveTier(tier_id: number) {
    if (!confirm("Are you sure?")) {
      return;
    }
    try {
      this.spinner.show();
      await this.tierService.leaveTier(tier_id);
      this.refreshTierData.next();
      this.toastService.success("You left the tier");
      if (tier_id === this.tierId) {
        window.location.reload();
      }
    } catch (e) {
      this.toastService.error(
        e?.error?.error || e?.message || JSON.stringify(e)
      );
    } finally {
      this.spinner.hide();
    }
  }

  async joinFreeTiers() {
    try {
      this.spinner.show();
      await this.userService.joinFreeTiers();
      this.refreshTierData.next();
      this.toastService.success("You received the new tiers");
    } catch (e) {
      this.toastService.error(
        e?.error?.error || e?.error || e?.message || JSON.stringify(e)
      );
    } finally {
      this.spinner.hide();
    }
  }
}
