import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { TournamentService } from '../../app/services/tournament.service'
import { SiteService } from '../../app/services/site.service'
import { trigger, state, style, animate, transition, query, group, stagger } from '@angular/animations';
import posthog from "posthog-js";
import KeenSlider, { KeenSliderInstance } from "keen-slider"

// Moment
import * as moment from 'moment-timezone';
moment().tz("America/Los_Angeles").format()

import _ from 'lodash';

@Component({
  selector: 'main-guide',
  templateUrl: './main-guide-modal.component.html',
  styleUrls: ['./main-guide-modal.component.scss'],
  providers: [TournamentService, SiteService],
  animations: [
    trigger('animation', [
        transition('* <=> *', [
          group([
            query(':enter', [
              style({opacity: 0, transform: 'scale(0.7)'}),
              stagger(100, [
                animate('500ms ease-in', style({opacity: 1, transform: 'scale(1)'}))
              ])
            ], { optional: true }),
            query(':enter', [
              style({backgroundColor: 'rgba(0 ,255, 0, 0.2)'}),
              animate('3s ease-in', style({backgroundColor: 'rgba(0, 0, 0, 0.1)'}))
            ], { optional: true }),
            query(':enter h2, :enter dl', [
              style({color: 'green'}),
              animate('3s ease-in', style({color: 'inherit'}))
            ], { optional: true }),
          ]),
          group([
            query(':leave', [
              style({opacity: 1, transform: 'scale(1)'}),
              stagger(-100, [
                animate('1s ease-in', style({opacity: 0, transform: 'scale(0.7)'}))
              ])
            ], { optional: true }),
            query(':leave', [
              animate('250ms ease-in', style({backgroundColor: 'rgba(255, 0, 0, 0.2)'}))
            ], { optional: true }),
            query(':leave h2, :leave dl', [
              animate('250ms ease-in', style({color: 'red'}))
            ], { optional: true }),
          ])
        ])
      ]),
    trigger(
      'inRightOutLeft', 
      [
        transition(
          ':enter', 
          [
            style({ left: '-105%', opacity: 0 }),
            animate('0.4s  ease-out', 
                    style({ left: 0, opacity: 1 }))
          ]
        ),
        transition(
          ':leave', 
          [
            style({ left: 0, opacity: 1 }),
            animate('0.2s ease-in', 
                    style({ left: '-105%', opacity: 0 }))
          ]
        )
      ]
    ),
    trigger(
      'inLeftOutRight', 
      [
        transition(
          ':enter', 
          [
            style({ right: '-105%', opacity: 0 }),
            animate('0.2s ease-out', 
                    style({ right: 0, opacity: 1 }))
          ]
        ),
        transition(
          ':leave', 
          [
            style({ right: 0, opacity: 1 }),
            animate('0.4s ease-in', 
                    style({ right: '-105%', opacity: 0 }))
          ]
        )
      ]
    ),
  ]
})

export class MainGuideComponent implements OnInit {
    @ViewChild("sliderRef") sliderRef: ElementRef;

    public page = 1;
    public active = JSON.parse(localStorage.getItem('dontShowHomeModal') || "null") ? false : true;
    public dontShowAgain = false;

    currentSlide: number = 0
    dotHelper: Array<Number> = []
    slider: KeenSliderInstance = null;

    async ngOnInit() {
    }

    next() {
        this.page++;
    }

    previous() {
        if(this.page > 1) {
            this.page--;
        }
    }

    close() {
      this.active = false;
      if(this.dontShowAgain) {
        const now = new Date();
        const info = {
          dont_show: true,
          date: now.getTime()
        }
        localStorage.setItem('dontShowHomeModal', JSON.stringify(info));
      } else if(localStorage.getItem('dontShowHomeModal')) {
        localStorage.removeItem('dontShowHomeModal');
      }
    }

    clickedVideo() {
      posthog.capture('user_clicked_guide');
    }

    ngAfterViewInit() {
      this.slider = new KeenSlider(this.sliderRef.nativeElement, {
        initial: this.currentSlide,
        loop: true,
        slideChanged: (s) => {
          this.currentSlide = s.track.details.rel
        },
      },
      [
          (slider) => {
          let timeout
          let mouseOver = false
          function clearNextTimeout() {
              clearTimeout(timeout)
          }
          function nextTimeout() {
              clearTimeout(timeout)
              if (mouseOver) return
              timeout = setTimeout(() => {
              slider.next()
              }, 7000)
          }
          slider.on("created", () => {
              slider.container.addEventListener("mouseover", () => {
              mouseOver = true
              clearNextTimeout()
              })
              slider.container.addEventListener("mouseout", () => {
              mouseOver = false
              nextTimeout()
              })
              nextTimeout()
          })
          slider.on("dragStarted", clearNextTimeout)
          slider.on("animationEnded", nextTimeout)
          slider.on("updated", nextTimeout)
          },
      ],
      )
      
      this.dotHelper = [
        ...Array(this.slider.track.details.slides.length).keys(),
      ]
    }

    ngOnDestroy() {
      if (this.slider) this.slider.destroy();
    }

}
