import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { GetRandomBannerComponent } from "./get-random-banner.component";

@NgModule({
    declarations: [GetRandomBannerComponent],
    exports: [GetRandomBannerComponent],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
    ]
})
export class GetRandomBannerModule {}