import { googleCalendarEventUrl } from "google-calendar-url";
import { DateTime } from "luxon";
import { Site } from "../interfaces/sites";
import { Tournament } from "../interfaces/tournament";
export const SITE_MAP = "siteMaps";

let windowObjectReference: any;

export const createDetachedFrame = (url: string, leftParam?: number) => {
  let left = window.screen.width - 550;

  if (leftParam || leftParam === 0) {
    console.log(leftParam);
    left = leftParam;
  }
  if (windowObjectReference == null || windowObjectReference.closed) {
    const strWindowFeatures = `left=0,top=0,height=${
      window.screen.height || "570"
    },width=${window.screen.width || "1320"},scrollbars=yes,status=yes,popup=yes`;
    windowObjectReference = window.open(url, "_blank", strWindowFeatures);
  } else {
    windowObjectReference.focus();
  }
};

export const closeDetachedFrame = (): void => {
  window.close();
};

export function formatToCurrency(v) {
  if (!v) {
    return v;
  }
  if (typeof v === "number") {
    v = v.toFixed(2);
  } else {
    const splited = v.split(".");
    if (splited.length === 1 && splited[0].length === 1) {
      v = v + ",00";
      return v;
    } else if (splited[1] && splited[1].length < 2) {
      v = splited[0] + "." + splited[1] + "0";
    }
  }
  return formatToMoney(v);
}

export function maskCurrency(v) {
  if (typeof v === "number") {
    v = v.toFixed(2);
  }
  if (v?.length === 2) {
    v = "0" + v;
  }
  return formatToMoney(v);
}

export function formatToMoney(v) {
  if (!v) {
    v = "";
  }
  v = v + "";
  v = v.replace(/\D/g, "");
  if (v.length === 3) {
    v = v.replace(/(\d{1})(\d{2})/, "$1,$2");
  } else if (v.length === 4) {
    v = v.replace(/(\d{2})(\d{2})/, "$1,$2");
  } else if (v.length === 5) {
    v = v.replace(/(\d{3})(\d{2})/, "$1,$2");
  } else if (v.length === 6) {
    v = v.replace(/(\d{1})(\d{3})(\d{2})/, "$1.$2,$3");
  } else if (v.length === 7) {
    v = v.replace(/(\d{2})(\d{3})(\d{2})/, "$1.$2,$3");
  } else if (v.length === 8) {
    v = v.replace(/(\d{3})(\d{3})(\d{2})/, "$1.$2,$3");
  } else if (v.length === 9) {
    v = v.replace(/(\d{1})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3,$4");
  } else if (v.length === 10) {
    v = v.replace(/(\d{2})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3,$4");
  } else if (v.length === 11) {
    v = v.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3,$4");
  } else if (v.length === 12) {
    v = v.replace(/(\d{1})(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3.$4,$5");
  } else {
    v = v.replace(/(\d{10,})(\d{2})/, "$1.$2");
  }
  return v;
}

export function generateEventCalendar(
  tournament: Tournament,
  sitesMap: { [key: number]: Site }
): string {
  const endTime = DateTime.fromISO(tournament.start).plus({
    minute: tournament.late_register,
  }).toJSDate().toISOString().split('.')[0]+"Z";

  const url = googleCalendarEventUrl({
    start: tournament.start.replace(/\W/g, ""),
    end: endTime.replace(/\W/g, ""),
    title: `(${sitesMap[tournament.site].name}) ${tournament.name}`,
    details: `
      Blind: ---------------- ${tournament.blind}\n
      BuyIn: ---------------- ${formatToCurrency(tournament.buy_in)}${
        tournament.currency
      }\n
      Prize pool: ----------- ${formatToCurrency(tournament.prize_pool)} ${
      tournament.currency
    }\n
      Type: ----------------- ${tournament.type}

    `,
    location: `${sitesMap[tournament.site].name}`,
  });

  return url;
}
