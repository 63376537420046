import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";

interface RequestTournamentFilters {
  name?: string;
  site: string;
  buyIn: number;
  registerFrom: string;
}

@Injectable({
  providedIn: "root",
})
export class RequestTournamentService {
  constructor(private http: HttpClient) {}

  public getRequestedTournament(
    requestTournamentFilters: RequestTournamentFilters
  ): Observable<any> {
    // todo: trocar o conteudo abaixo pela chamada correta ao backend enviando o filtro no body
    // ou como params usando HttpParams e convertendo os valores no requestTournamentFilters para params


    // remover esse mock quando for integrar
    return of([
      {
        currency: "EUR",
        site: 12,
        late_register: 140.66666666666666,
        game: "NL Hold'em",
        buy_in: 3.3,
        rake: 0.3,
        name: "EL RINGUE PKO 3,30",
        speed: "Regular",
        type: "Regular",
        players: "9-max",
        variant: "Knockout",
        state: "Announced",
        blind: 12,
        start: "2023-02-09T21:15:00Z",
        prize_pool: 1000,
        overlay: 0,
        average_duration: 0,
        init_stacks: 0,
        current_blind: -1,
        field: 303,
        id: 2618,
        tournament_id: 12904,
        priority: 1,
        notification: {
          type: "none",
          time: 0,
        },
      },
      {
        currency: "USD",
        site: 4,
        late_register: 50.666666666666664,
        game: "NL Hold'em",
        buy_in: 5.5,
        rake: 0.5,
        name: "R\u0026A TURBO - 5,50",
        speed: "Turbo",
        type: "Regular",
        players: "9-max",
        variant: "Turbo",
        state: "Announced",
        blind: 6,
        start: "2023-02-09T22:45:00Z",
        prize_pool: 1000,
        overlay: 0,
        average_duration: 0,
        init_stacks: 1500,
        current_blind: -1,
        field: 181,
        id: 2639,
        tournament_id: 2155,
        priority: 1,
        notification: {
          type: "none",
          time: 0,
        },
      },
      {
        currency: "USD",
        site: 11,
        late_register: 220,
        game: "NL Hold'em",
        buy_in: 22,
        rake: 2,
        name: "EXTRA PLAY - $22",
        speed: "Slow",
        type: "Regular",
        players: "9-max",
        variant: "Regular",
        state: "Announced",
        blind: 20,
        start: "2023-02-09T20:00:00Z",
        prize_pool: 3000,
        overlay: 0,
        average_duration: 0,
        init_stacks: 0,
        current_blind: -1,
        field: 136,
        id: 2654,
        tournament_id: 2104,
        priority: 1,
        notification: {
          type: "none",
          time: 0,
        },
      },
    ]);
  }

  public sendTournamentToApprove(request:any):Observable<any>{

    // TODO: implementar aqui qualquer tratativa necessaria antes de chamar o backend.

    // TODO: Quando definir as informações que o backend precisar, favor Tipar o parametro request neste metodo

    // TODO: trocar pela chamada ao backend passando a variavel request como parametro
    return of({ok:'ok'})
  }
}
