<div id="snackbar" class="show" *ngIf="showSnackbar">{{ snackbarMessage }}</div>
<div *ngIf="showHeader" class="row justify-content-between px-1">
  <div class="col-12 col-md-6 col-lg-auto mb-4">
    <div class="row">
      <div class="col-auto pr-1">
        <button class="btn btn-warning tour-grind" type="button" (click)="goToGrindMode()" tooltip="{{ 'grind_mode_tooltip' | translate }}" trigger="hover" placement="top"><span class="badge badge-danger mr-2">new</span>{{ 'grind_mode' | translate }}</button>
        <a class="help-link" tooltip="{{'help' | translate}}" triggers="hover" href="https://www.youtube.com/watch?v=HHyXjqVRBLA" target="_blank" style="right: -4px;"><i class="fa fa-question-circle text-primary"></i></a>
      </div>
      <div class="col pl-1">
        <a class="btn btn-outline-secondary tour-weekly-view" container="body" style="border: none; padding-top: 10px;" type="button" href="/scheduled-tournaments" tooltip="{{ 'weekly_view_tooltip' | translate }}" trigger="hover" placement="top"><i class="fa fa-list-ul mr-2"></i>{{ 'weekly_view' | translate }}</a>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-6 mb-4">
    <div class="row justify-content-end">
      <div class="col-12 col-lg-auto text-center" *ngIf="filters[selectedFilterIndex].scheduled != true">
        <button class="btn btn-outline-secondary tour-auto-schedule" (click)="openAutoSchedule()" style="border: none;"><i class="fa fa-calendar-o mr-2"></i>{{ 'auto_schedule' | translate }}</button>
        <a class="help-link" tooltip="{{'help' | translate}}" triggers="hover" href="https://www.youtube.com/watch?v=ylPQWFdA4_g" target="_blank" ><i class="fa fa-question-circle text-primary"></i></a>
      </div>
    </div>
  </div>
</div>

<div
  class="row py-2 row-no-margin tour-filter-tournaments"
  [ngClass]="{ 'inset-shadow': extraFiltersVisible }"
>
  <div class="col-12">
    <div class="main-filters row justify-content-between mb-2" style="align-items: flex-end">
      <!-- Search Field -->
      <div
        *ngIf="showFilters.includes('name')"
        class="col-12 col-sm-12 col-lg-4 col-xl-22 col-xxl-2 filter-items px-1 mb-2"
      >
        <ng-select
          [multiple]="true"
          [addTag]="true"
          [(ngModel)]="searchModel"
          (change)="onClickSubmitFilters(undefined, null, false)"
          placeholder="{{'search_by_name' | translate}}"
        >
        </ng-select>
      </div>

      <!-- Site -->
      <div
        *ngIf="showFilters.includes('site')"
        class="col-12 col-sm-12 col-lg-4 col-xl-3 col-xxl-3 filter-items px-1 mb-2"
      >
        <ng-multiselect-dropdown
          [(ngModel)]="filters[selectedFilterIndex].site"
          [placeholder]="'Site'"
          [settings]="dropdownSettings"
          [data]="dropdownListSites"
          (onFilterChange)="onClickSubmitFilters(undefined, null, false)"
          (onSelect)="onClickSubmitFilters(undefined, null, false)"
          (onSelectAll)="onClickSubmitFilters(undefined, null, false)"
          (onDeSelect)="onClickSubmitFilters(undefined, null, false)"
        >
        </ng-multiselect-dropdown>
      </div>

      <!-- Buy In -->
      <div
        *ngIf="showFilters.includes('buy_in')"
        class="col-12 col-sm-12 col-lg-4 col-xl-23 col-xxl-3 filter-items px-1 mb-2"
      >
        <div class="row">
          <div class="col-65 pr-1">
            <div class="input-group">
              <span class="input-group-text" [ngStyle]="{'background-color': isDarkTheme ? '#454d52' : '#e6e6e6'}" style="border-top-right-radius: 0; border-bottom-right-radius: 0;">{{ selectedCurrency }}</span>
              <input
                [(ngModel)]="filters[selectedFilterIndex].buy_in_min"
                class="form-control"
                id="buy_in_min"
                placeholder="{{ 'buy_in_min' | translate }}"
                type="number"
                (blur)="onClickSubmitFilters(undefined, null, false)"
                (change)="onClickSubmitFilters(undefined, null, false)"
              />
            </div>
          </div>
          <div class="col-55 pl-1">
            <input
              [(ngModel)]="filters[selectedFilterIndex].buy_in_max"
              class="form-control"
              id="buy_in_max"
              placeholder="{{ 'buy_in_max' | translate }}"
              type="number"
              (blur)="onClickSubmitFilters(undefined, null, false)"
              [max]="bloquedFunctions ? 5 : null"
              (change)="onClickSubmitFilters(undefined, null, false)"
            />
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-12 col-lg-12 col-xl-45 col-xxl-4 px-1 mb-2">
        <div class="row m-0 justify-content-between">
          <div class="col px-1">
            <button
              [disabled]="isLoading"
              class="btn btn-outline-secondary tour-search"
              type="button"
              style="width: 100%;"
              (click)="sendFilterList(filters[selectedFilterIndex])"
            >
              {{ isLoading ? "loading..." : ("search" | translate) }}
            </button>
          </div>
          <div class="col px-1" *ngIf="showFilters.includes('favorite')">
            <button
              class="btn btn-outline-secondary tour-filters px-0"
              (click)="filters[selectedFilterIndex].only_favorites = !filters[selectedFilterIndex].only_favorites; sendFilterList(filters[selectedFilterIndex])"
              style="border: none; width: 100%;"
            >
              {{ 'favorites' | translate }}
              <i 
                class="fa fa-favorite ml-2"
                [ngClass]="{
                  'fa-star-o': !filters[selectedFilterIndex].only_favorites,
                  'fa-star': filters[selectedFilterIndex].only_favorites,
                  'text-warning': filters[selectedFilterIndex].only_favorites
                }"
              ></i>
            </button>
          </div>
          <div class="col px-1">
            <button
              class="btn btn-outline-secondary tour-filters px-0"
              (click)="toggleCollapse()"
              style="border: none; width: 100%;"
            >
              {{ "more_filters" | translate }}
              <i class="fa fa-filter ml-2"></i>
            </button>
          </div>
          <div class="col px-1" *ngIf="mode != 'manage-filters'">
            <button
              [disabled]="bloquedFunctions"
              class="btn btn-outline-secondary tour-saved-filters px-0"
              style="border: none; width: 100%;"
              (click)="openSavedFilters()"
            >
              {{ "saved_filters" | translate }}<i class="fa fa-angle-down ml-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!isFilterEmpty()" class="row applied-filters mb-2">
      <div class="col px-1 pt-2">
        <div class="row m-0">
          <div *ngIf="filterName" class="col-auto px-1 mb-1">
            <div class="applied-filter-badge success" tooltip="{{ 'save_filter' | translate }}" (click)="modalEditSavedFilter.show()">
              <div class="badge-text">{{ 'save' | translate }}: {{ filterName }}</div><i class="ml-2 fa fa-save"></i>
            </div>
          </div>
          <div *ngIf="!filterName" class="col-auto px-1 mb-1">
            <div class="applied-filter-badge success" (click)="modalEditSavedFilter.show()">
              {{ 'save_filter' | translate }}<i class="ml-2 fa fa-save"></i>
            </div>
          </div>
          <div class="col-auto px-1 mb-1">
            <div class="applied-filter-badge" (click)="reset()">
              {{ 'clear_all' | translate }}<i class="ml-2 fa fa-eraser"></i>
            </div>
          </div>
          <ng-container *ngFor="let filter of filters[selectedFilterIndex] | keyvalue : asIsOrderFilters">
            <div *ngIf="getFilterBadgeText(filter)" class="col-auto px-1 mb-1">
              <div class="applied-filter-badge" (click)="removeFilterBadge(filter)">
                {{ getFilterBadgeText(filter) }}<i class="ml-2 fa fa-times"></i>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="extraFiltersVisible" class="extra-filters-background" (click)="extraFiltersVisible = false"></div>
  <div *ngIf="extraFiltersVisible" [@inOutAnimation] class="extra-filters">
    <div class="extra-filters-header">
      <i class="filters-close fa fa-close" (click)="extraFiltersVisible = false"></i>
    </div>
    <div class="extra-filters-body">
      <!-- Time -->
      <div *ngIf="showFilters.includes('time')" class="filter-items row mt-0">
        <div class="col pl-0">
          <label>{{ "from_time" | translate }}</label>
          <div class="input-group">
            <input
              #from_time
              [(ngModel)]="filters[selectedFilterIndex].from_time"
              class="form-control"
              id="from_time"
              type="time"
              (blur)="onKeyupSearch()"
              (change)="onKeyupSearch()"
            />
          </div>
        </div>
        <div class="col pl-0 pr-3">
          <label for="init_stacks">{{ "to_time" | translate }}</label>
          <div class="input-group">
            <input
              #to_time
              [(ngModel)]="filters[selectedFilterIndex].to_time"
              class="form-control"
              id="to_time"
              type="time"
              (blur)="onKeyupSearch()"
              (change)="onKeyupSearch()"
            />
          </div>
        </div>
        <div class="col pr-0" *ngIf="showFilters.includes('end_time')">
          <!-- End Time -->
          <label>{{ "end_time" | translate }}</label>
          <input
            #from_end_time
            [(ngModel)]="filters[selectedFilterIndex].to_end_time"
            class="form-control"
            id="to_end_time"
            type="time"
            (blur)="onKeyupSearch()"
            (change)="onKeyupSearch()"
          />
        </div>
      </div>

      <!-- Prize Pool -->
      <div
        *ngIf="showFilters.includes('prize_pool')"
        class="filter-items row"
      >
        <div class="col pl-0">
          <label>{{ "prize_pool_min" | translate }}</label>
          <div class="input-group">
            <span class="input-group-text" [ngStyle]="{'background-color': isDarkTheme ? '#454d52' : '#e6e6e6'}" style="border-top-right-radius: 0; border-bottom-right-radius: 0;">{{ selectedCurrency }}</span>
            <input
                [(ngModel)]="filters[selectedFilterIndex].prize_pool_min"
                class="form-control"
                id="prize_pool_min"
                placeholder="Min"
                type="number"
                (blur)="onKeyupSearch()"
                (change)="onKeyupSearch(); changedRangeFilter('prize_pool', [filters[selectedFilterIndex].prize_pool_min, filters[selectedFilterIndex].prize_pool_max], true)"
              />
          </div>
        </div>
        <div class="col pr-0">
          <label>{{ "prize_pool_max" | translate }}</label>
          <div class="input-group">
            <span class="input-group-text" [ngStyle]="{'background-color': isDarkTheme ? '#454d52' : '#e6e6e6'}" style="border-top-right-radius: 0; border-bottom-right-radius: 0;">{{ selectedCurrency }}</span>
            <input
                [(ngModel)]="filters[selectedFilterIndex].prize_pool_max"
                class="form-control"
                id="prize_pool_max"
                placeholder="Max"
                type="number"
                (blur)="onKeyupSearch()"
                (change)="onKeyupSearch(); changedRangeFilter('prize_pool', [filters[selectedFilterIndex].prize_pool_min, filters[selectedFilterIndex].prize_pool_max], true)"
              />
          </div>
        </div>
        <div class="col-12">
          <div class="custom-slider">
            <ngx-slider [(value)]="rangeFilters.prize_pool[0]" [(highValue)]="rangeFilters.prize_pool[1]" [options]="optionsPrizePool" (userChange)="changedRangeFilter('prize_pool')"></ngx-slider>
          </div>
        </div>
      </div>

      <!-- Speed -->
      <div
      *ngIf="showFilters.includes('speed')"
      class="filter-items"
      >
        <label>{{ "speed" | translate }}</label>
        <div class="row">
          <div class="col-auto mb-2 px-1">
            <div
              class="btn-badge filter-option"
              [ngClass]="{ 'selected': filters[selectedFilterIndex].speed == null || filters[selectedFilterIndex].speed == [] }"
              (click)="anyFilterArrayOption('speed')"
            >
              {{ 'any' | translate }}  
            </div>
          </div>
          <ng-container *ngFor="let speed of dropdownListSpeed">
            <div class="col-auto mb-2 px-1">
              <div
                class="btn-badge filter-option"
                [ngClass]="{ 'selected': filterArrayHasOption('speed', speed) }"
                (click)="toggleFilterArrayOption('speed', speed)"
              >
                {{ speed.item_text }}  
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <!-- Game -->
      <div
        *ngIf="showFilters.includes('game') && mode != 'addTournaments'"
        class="filter-items"
      >
      <label>{{ "game" | translate }}</label>
        <div class="row">
          <div class="col-auto mb-2 px-1">
            <div
              class="btn-badge filter-option"
              [ngClass]="{ 'selected': filters[selectedFilterIndex].game2 == null || filters[selectedFilterIndex].game2 == [] }"
              (click)="anyFilterArrayOption('game2')"
            >
              {{ 'any' | translate }}  
            </div>
          </div>
          <ng-container *ngFor="let game of dropdownListGame">
            <div class="col-auto mb-2 px-1">
              <div
                class="btn-badge filter-option"
                [ngClass]="{ 'selected': filterArrayHasOption('game2', game) }"
                (click)="toggleFilterArrayOption('game2', game)"
              >
                {{ game.item_text }}  
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <!-- Game Admin -->
      <div
        *ngIf="showFilters.includes('game') && mode == 'addTournaments'"
        class="filter-items"
      >
      <label>{{ "game" | translate }}</label>
        <div class="row">
          <div class="col-auto mb-2 px-1">
            <div
              class="btn-badge filter-option"
              [ngClass]="{ 'selected': filters[selectedFilterIndex].game2 == null || filters[selectedFilterIndex].game2 == [] }"
              (click)="anyFilterArrayOption('game2')"
            >
              {{ 'any' | translate }}  
            </div>
          </div>
          <ng-container *ngFor="let game of dropdownListGameAdmin">
            <div class="col-auto mb-2 px-1">
              <div
                class="btn-badge filter-option"
                [ngClass]="{ 'selected': filterArrayHasOption('game2', game) }"
                (click)="toggleFilterArrayOption('game2', game)"
              >
                {{ game.item_text }}  
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <!-- Variant -->
      <div
      *ngIf="showFilters.includes('variant')"
      class="filter-items"
      >
      <label>{{ "variant" | translate }}</label>
      <div class="row">
        <div class="col-auto mb-2 px-1">
          <div
            class="btn-badge filter-option"
            [ngClass]="{ 'selected': filters[selectedFilterIndex].variant == null || filters[selectedFilterIndex].variant == [] }"
            (click)="anyFilterArrayOption('variant')"
          >
            {{ 'any' | translate }}  
          </div>
        </div>
        <ng-container *ngFor="let variant of dropdownListVariant">
          <div class="col-auto mb-2 px-1">
            <div
              class="btn-badge filter-option"
              [ngClass]="{ 'selected': filterArrayHasOption('variant', variant) }"
              (click)="toggleFilterArrayOption('variant', variant)"
            >
              {{ variant.item_text }}  
            </div>
          </div>
        </ng-container>
      </div>
      </div>

      <!-- Exclude Field -->
      <div
      *ngIf="showFilters.includes('name')"
      class="filter-items"
      >
      <label>{{ 'exclude' | translate }}</label>
      <ng-select
        class="mr-sm-2"
        [multiple]="true"
        [addTag]="true"
        [(ngModel)]="excludeModel"
        (change)="onKeyupSearch()"
        placeholder="{{ 'exclude' | translate }}"
      >
      </ng-select>
      </div>

      <!-- Field -->
      <div
        *ngIf="showFilters.includes('field')"
        class="filter-items row"
      >
        <div class="col pl-0">
          <label>{{ "min_participants" | translate }}</label>
          <input
            [(ngModel)]="filters[selectedFilterIndex].min_participants"
            class="form-control"
            id="field"
            type="number"
            (blur)="onKeyupSearch()"
            placeholder="Min"
            (change)="onKeyupSearch(); changedRangeFilter('participants', [filters[selectedFilterIndex].min_participants, filters[selectedFilterIndex].field], true)"
          />
        </div>
        <div class="col pr-0">
          <label>{{ "max_participants" | translate }}</label>
          <input
            [(ngModel)]="filters[selectedFilterIndex].field"
            class="form-control"
            id="field"
            type="number"
            (blur)="onKeyupSearch()"
            placeholder="Max"
            (change)="onKeyupSearch(); changedRangeFilter('participants', [filters[selectedFilterIndex].min_participants, filters[selectedFilterIndex].field], true)"
          />
        </div>
        <div class="col-12">
          <div class="custom-slider">
            <ngx-slider [(value)]="rangeFilters.participants[0]" [(highValue)]="rangeFilters.participants[1]" [options]="optionsParticipants" (userChange)="changedRangeFilter('participants')"></ngx-slider>
          </div>
        </div>
      </div>

      <!-- Players -->
      <div
      *ngIf="showFilters.includes('players')"
      class="filter-items"
      >
      <label>{{ "players" | translate }}</label>
      <div class="row">
        <div class="col-auto mb-2 px-1">
          <div
            class="btn-badge filter-option"
            [ngClass]="{ 'selected': filters[selectedFilterIndex].players == null || filters[selectedFilterIndex].players == [] }"
            (click)="anyFilterArrayOption('players')"
          >
            {{ 'any' | translate }}  
          </div>
        </div>
        <ng-container *ngFor="let player of dropdownListPlayers">
          <div class="col-auto mb-2 px-1">
            <div
              class="btn-badge filter-option"
              [ngClass]="{ 'selected': filterArrayHasOption('players', player) }"
              (click)="toggleFilterArrayOption('players', player)"
            >
              {{ player.item_text }}  
            </div>
          </div>
        </ng-container>
      </div>
      </div>

      <!-- Ability -->
      <div
        *ngIf="showFilters.includes('ability')"
        class="row filter-items"
      >
        <div class="col-12 pl-0">
          <label>{{ "max_ability" | translate }}</label>
        </div>
        <div class="col-3 pl-0">
          <input
            [(ngModel)]="filters[selectedFilterIndex].av_ability"
            class="form-control"
            id="field"
            type="number"
            (blur)="onKeyupSearch()"
            placeholder="Max"
            (change)="onKeyupSearch(); changedRangeFilter('ability', filters[selectedFilterIndex].av_ability, true)"
          />
        </div>
        <div class="col pr-0">
          <div class="custom-slider" style="margin-top: -32px !important;">
            <ngx-slider (userChange)="changedRangeFilter('ability')" [(ngModel)]="rangeFilters.ability" [options]="{step:1, floor: 0, ceil: 100, showSelectionBar: true, ticksArray: [0, 100], showTicksValues: true}"></ngx-slider>
          </div>
        </div>
      </div>

      <!-- From Date / To Date -->
      <div class="row filter-items" *ngIf="showFilters.includes('date') && !filters[selectedFilterIndex].include_past">
        <!-- From Date  -->
        <div class="col pl-0">
          <label for="init_stacks">{{ "start" | translate }}</label>
          <div class="input-group">
            <!-- input box -->
            <input
              class="form-control"
              name="mydate"
              (click)="dpf.toggleCalendar()"
              angular-mydatepicker
              #dpf="angular-mydatepicker"
              placeholder="{{ 'from_date' | translate }}"
              [(ngModel)]="dateFromModel"
              [options]="dateFromOptions"
              (dateChanged)="onDateChange($event, 'from')"
              [locale]="
                getCurrentLang() == 'en-US'
                  ? 'en'
                  : getCurrentLang() == 'pt-BR'
                  ? 'pt-br'
                  : getCurrentLang() == 'es'
                  ? 'es'
                  : 'ru'
              "
            />

            <!-- clear date button -->
            <div class="input-group-append">
              <button
                type="button"
                [class]="'btn btn-' + accentColor"
                *ngIf="filterFromDate"
                (click)="dpf.clearDate()"
              >
                <i class="fa fa-close"></i>
              </button>
            </div>

            <!-- toggle calendar button -->
            <div class="input-group-append">
              <button
                type="button"
                [class]="'btn btn-' + accentColor"
                (click)="dpf.toggleCalendar()"
              >
                <i class="fa fa-calendar-o"></i>
              </button>
            </div>
          </div>
        </div>

        <!-- To Date  -->
        <div class="col pr-0">
          <label for="init_stacks">{{ "to_date" | translate }}</label>
          <div class="input-group">
            <!-- input box -->
            <input
              class="form-control"
              name="mydate"
              (click)="dp.toggleCalendar()"
              angular-mydatepicker
              #dp="angular-mydatepicker"
              placeholder="{{ 'to_date' | translate }}"
              [(ngModel)]="dateToModel"
              [options]="dateToOptions"
              (dateChanged)="onDateChange($event, 'to')"
              [locale]="
                getCurrentLang() == 'en-US'
                  ? 'en'
                  : getCurrentLang() == 'pt-BR'
                  ? 'pt-br'
                  : getCurrentLang() == 'es'
                  ? 'es'
                  : 'ru'
              "
            />
    
            <!-- clear date button -->
            <div class="input-group-append">
              <button
                type="button"
                [class]="'btn btn-' + accentColor"
                *ngIf="filterToDate"
                (click)="dp.clearDate()"
              >
                <i class="fa fa-close"></i>
              </button>
            </div>
    
            <!-- toggle calendar button -->
            <div class="input-group-append">
              <button
                type="button"
                [class]="'btn btn-' + accentColor"
                (click)="dp.toggleCalendar()"
              >
                <i class="fa fa-calendar-o"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- From Date / To Date - Include Past -->
      <div class="row filter-items" *ngIf="showFilters.includes('date') && filters[selectedFilterIndex].include_past">
        <!-- From Date  -->
        <div class="col pl-0">
          <label for="init_stacks">{{ "start" | translate }}</label>
          <div class="input-group">
            <!-- input box -->
            <input
              class="form-control"
              name="mydate"
              (click)="dpf.toggleCalendar()"
              angular-mydatepicker
              #dpf="angular-mydatepicker"
              placeholder="{{ 'from_date' | translate }}"
              [(ngModel)]="dateFromModel"
              [options]="datePastOptions"
              (dateChanged)="onDateChange($event, 'from')"
              [locale]="
                getCurrentLang() == 'en-US'
                  ? 'en'
                  : getCurrentLang() == 'pt-BR'
                  ? 'pt-br'
                  : getCurrentLang() == 'es'
                  ? 'es'
                  : 'ru'
              "
            />

            <!-- clear date button -->
            <div class="input-group-append">
              <button
                type="button"
                [class]="'btn btn-' + accentColor"
                *ngIf="filterFromDate"
                (click)="dpf.clearDate()"
              >
                <i class="fa fa-close"></i>
              </button>
            </div>

            <!-- toggle calendar button -->
            <div class="input-group-append">
              <button
                type="button"
                [class]="'btn btn-' + accentColor"
                (click)="dpf.toggleCalendar()"
              >
                <i class="fa fa-calendar-o"></i>
              </button>
            </div>
          </div>
        </div>

        <!-- To Date  -->
        <div class="col pr-0">
          <label for="init_stacks">{{ "to_date" | translate }}</label>
          <div class="input-group">
            <!-- input box -->
            <input
              class="form-control"
              name="mydate"
              (click)="dp.toggleCalendar()"
              angular-mydatepicker
              #dp="angular-mydatepicker"
              placeholder="{{ 'to_date' | translate }}"
              [(ngModel)]="dateToModel"
              [options]="datePastOptions"
              (dateChanged)="onDateChange($event, 'to')"
              [locale]="
                getCurrentLang() == 'en-US'
                  ? 'en'
                  : getCurrentLang() == 'pt-BR'
                  ? 'pt-br'
                  : getCurrentLang() == 'es'
                  ? 'es'
                  : 'ru'
              "
            />
    
            <!-- clear date button -->
            <div class="input-group-append">
              <button
                type="button"
                [class]="'btn btn-' + accentColor"
                *ngIf="filterToDate"
                (click)="dp.clearDate()"
              >
                <i class="fa fa-close"></i>
              </button>
            </div>
    
            <!-- toggle calendar button -->
            <div class="input-group-append">
              <button
                type="button"
                [class]="'btn btn-' + accentColor"
                (click)="dp.toggleCalendar()"
              >
                <i class="fa fa-calendar-o"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Day of the Week -->
      <div
        *ngIf="showFilters.includes('week_day')"
        class="filter-items"
      >
        <label>{{ "day_of_the_week" | translate }}</label>
        <div class="row">
          <div class="col-auto mb-2 px-1">
            <div
              class="btn-badge filter-option"
              [ngClass]="{ 'selected': filters[selectedFilterIndex].week_day == null || filters[selectedFilterIndex].week_day == [] }"
              (click)="anyFilterArrayOption('week_day')"
            >
              {{ 'all' | translate }}  
            </div>
          </div>
          <ng-container *ngFor="let day of dropdownListDay">
            <div class="col-auto mb-2 px-1">
              <div
                class="btn-badge filter-option"
                [ngClass]="{ 'selected': filterArrayHasOption('week_day', day) }"
                (click)="toggleFilterArrayOption('week_day', day)"
              >
                {{ day.item_text }}  
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <!-- Duration -->
      <div *ngIf="showFilters.includes('duration')" class="filter-items row align-items-end">
        <div class="col pl-0">
          <label>{{ "duration_hours" | translate }}</label>
          <div class="input-group">
            <input
              [(ngModel)]="filters[selectedFilterIndex].from_duration"
              class="form-control"
              id="duration"
              type="number"
              (blur)="onKeyupSearch()"
              placeholder="Min"
              (change)="onKeyupSearch(); changedRangeFilter('duration', [filters[selectedFilterIndex].from_duration, filters[selectedFilterIndex].to_duration], true)"
            />
          </div>
        </div>
        <div class="col pr-0">
          <div class="input-group">
            <input
              [(ngModel)]="filters[selectedFilterIndex].to_duration"
              class="form-control"
              id="duration"
              type="number"
              (blur)="onKeyupSearch()"
              placeholder="Max"
              (change)="onKeyupSearch(); changedRangeFilter('duration', [filters[selectedFilterIndex].from_duration, filters[selectedFilterIndex].to_duration], true)"
            />
            </div>
        </div>
        <div class="col-12">
          <div class="custom-slider">
            <ngx-slider [(value)]="rangeFilters.duration[0]" [(highValue)]="rangeFilters.duration[1]" [options]="{floor: 0, ceil: 24}" (userChange)="changedRangeFilter('duration')"></ngx-slider>
          </div>
        </div>
      </div>

      <!-- Max late -->
      <div
        *ngIf="showFilters.includes('maxLate')"
        class="filter-items"
      >
        <label>{{ "max_late" | translate }}</label>
        <div class="row">
          <div class="col-auto">
            <div class="form-check">
              <input type="checkbox" class="formCheckInput mr-1" [(ngModel)]="filters[selectedFilterIndex].maxLate" (click)="onKeyupSearch()">
              <label (click)="filters[selectedFilterIndex].maxLate = !filters[selectedFilterIndex].maxLate; onKeyupSearch()" class="formCheckLabel">{{ 'only_max_late_tournaments' | translate }}</label>
            </div>
          </div>
        </div>
      </div>

      <!-- Include closed -->
      <div
        *ngIf="showFilters.includes('include_closed')"
        class="filter-items"
      >
        <label>{{ "include_closed" | translate }}</label>
        <div class="row">
          <div class="col-auto">
            <div class="form-check">
              <input type="checkbox" class="formCheckInput mr-1" [(ngModel)]="filters[selectedFilterIndex].include_closed" (click)="onKeyupSearch()">
              <label (click)="filters[selectedFilterIndex].include_closed = !filters[selectedFilterIndex].include_closed; onKeyupSearch()" class="formCheckLabel">{{ 'include_closed_tournaments' | translate }}</label>
            </div>
          </div>
        </div>
      </div>

      <!-- Scheduled -->
      <div
        *ngIf="showFilters.includes('scheduled')"
        class="filter-items"
      >
        <label>{{ "scheduling" | translate }}</label>
        <div class="row">
          <div class="col-auto">
            <div class="form-check">
              <input type="radio" (click)="filterScheduled = []" class="formCheckInput mr-1" [checked]="filterScheduled.length == 0" (change)="onKeyupSearch()">
              <label (click)="filterScheduled = []; onKeyupSearch()" class="formCheckLabel">{{ 'all' | translate }}</label>
            </div>
          </div>
          <ng-container *ngFor="let sch of dropdownListScheduled">
            <div class="col-auto">
              <div class="form-check">
                <input type="radio" class="formCheckInput mr-1" (change)="onKeyupSearch()" [checked]="filterScheduled.length > 0 && filterScheduled[0].item_id == sch.item_id" (click)="filterScheduled = [sch]">
                <label class="formCheckLabel" (click)="filterScheduled = [sch]; onKeyupSearch()">{{ sch.item_text }}</label>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <!-- Currency -->
      <div
        *ngIf="showFilters.includes('currency')"
        class="filter-items"
      >
        <label>{{ "currency" | translate }}</label>
        <div class="row">
          <div class="col-auto mb-2 px-1">
            <div
              class="btn-badge filter-option"
              [ngClass]="{ 'selected': filters[selectedFilterIndex].currency == null || filters[selectedFilterIndex].currency == [] }"
              (click)="anyFilterArrayOption('currency')"
            >
              {{ 'any' | translate }}  
            </div>
          </div>
          <ng-container *ngFor="let currency of dropdownListCurrency">
            <div class="col-auto mb-2 px-1">
              <div
                class="btn-badge filter-option"
                [ngClass]="{ 'selected': filterArrayHasOption('currency', currency) }"
                (click)="toggleFilterArrayOption('currency', currency)"
              >
                {{ currency.item_text }}  
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <!-- Flagged -->
      <div
        *ngIf="showFilters.includes('flagged')"
        class="filter-items"
      >
        <label>{{ "red_flag" | translate }}</label>
        <div class="row">
          <div class="col-auto">
            <div class="form-check">
              <input type="radio" (click)="filterFlagged = []" class="formCheckInput mr-1" [checked]="filterFlagged.length == 0" (change)="onKeyupSearch()">
              <label (click)="filterFlagged = []; onKeyupSearch()" class="formCheckLabel">{{ 'all' | translate }}</label>
            </div>
          </div>
          <ng-container *ngFor="let flag of dropdownListFlagged">
            <div class="col-auto">
              <div class="form-check">
                <input type="radio" class="formCheckInput mr-1" [checked]="filterFlagged.length > 0 && filterFlagged[0].item_id == flag.item_id" (click)="filterFlagged = [flag]; onKeyupSearch()" (change)="onKeyupSearch()">
                <label class="formCheckLabel" (click)="filterFlagged = [flag]">{{ flag.item_text }}</label>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <!-- Disabled -->
      <div
        *ngIf="showFilters.includes('show_disabled')"
        class="filter-items"
      >
        <label>{{ "show_disabled" | translate }}</label>
        <div class="row">
          <div class="col-auto">
            <div class="form-check">
              <input type="checkbox" class="formCheckInput mr-1" [(ngModel)]="filters[selectedFilterIndex].show_disabled" (click)="onKeyupSearch()">
              <label (click)="filters[selectedFilterIndex].show_disabled = !filters[selectedFilterIndex].show_disabled; onKeyupSearch()" class="formCheckLabel">{{ 'only_disabled_tournaments' | translate }}</label>
            </div>
          </div>
        </div>
      </div>

      <!-- Only Fast -->
      <div
        *ngIf="showFilters.includes('onlyFast')"
        class="filter-items"
      >
        <label>{{ "only_fast" | translate }}</label>
        <div class="row">
          <div class="col-auto">
            <div class="form-check">
              <input type="checkbox" class="formCheckInput mr-1" [(ngModel)]="filters[selectedFilterIndex].onlyFast" (click)="onKeyupSearch()">
              <label (click)="filters[selectedFilterIndex].onlyFast = !filters[selectedFilterIndex].onlyFast; onKeyupSearch()" class="formCheckLabel">{{ 'only_fast_tournaments' | translate }}</label>
            </div>
          </div>
        </div>
      </div>

      <!-- Category -->
      <div
        *ngIf="showFilters.includes('category')"
        class="filter-items"
      >
        <label>{{ "category" | translate }}</label>
        <ng-multiselect-dropdown
          [(ngModel)]="filters[selectedFilterIndex].category"
          placeholder="{{ 'category' | translate }}"
          [settings]="dropdownSettings"
          [data]="dropdownListCategory"
          (onSelect)="onKeyupSearch()"
          (onSelectAll)="onKeyupSearch()"
          (onDeSelect)="onKeyupSearch()"
        >
        </ng-multiselect-dropdown>
      </div>

      <!-- Priority -->
      <div
      *ngIf="showFilters.includes('priority')"
      class="filter-items"
      >
      <label>{{ "priority" | translate }}</label>
      <div class="row">
        <div class="col-auto mb-2 px-1">
          <div
            class="btn-badge filter-option"
            [ngClass]="{ 'selected': filters[selectedFilterIndex].priority == null || filters[selectedFilterIndex].priority == [] }"
            (click)="anyFilterArrayOption('priority')"
          >
            {{ 'any' | translate }}  
          </div>
        </div>
        <ng-container *ngFor="let priority of dropdownListPriority">
          <div class="col-auto mb-2 px-1">
            <div
              class="btn-badge filter-option"
              [ngClass]="{ 'selected': filterArrayHasOption('priority', priority) }"
              (click)="toggleFilterArrayOption('priority', priority)"
            >
              {{ priority.item_text }}  
            </div>
          </div>
        </ng-container>
      </div>
      </div>

      <!-- Include Past -->
      <div
        *ngIf="showFilters.includes('include_past')"
        class="filter-items"
      >
        <label>{{ "include_past" | translate }}</label>
        <div class="row">
          <div class="col-auto">
            <div class="form-check">
              <input type="checkbox" class="formCheckInput mr-1" [(ngModel)]="filters[selectedFilterIndex].include_past" (click)="onKeyupSearch()">
              <label (click)="filters[selectedFilterIndex].include_past = !filters[selectedFilterIndex].include_past; onKeyupSearch()" class="formCheckLabel">{{ 'include_past_tournaments' | translate }}</label>
            </div>
          </div>
        </div>
      </div>

      <!-- Only new -->
      <div
        *ngIf="isDevMode && showFilters.includes('onlyNew')"
        class="filter-items"
      >
        <label>{{ "only_new" | translate }}</label>
        <input
          class="form-check-input checkbox mr-1"
          type="checkbox"
          id="filterOnlyNew"
          [(ngModel)]="filters[selectedFilterIndex].onlyNew"
        />
        <label for="filterOnlyNew" class="form-check-label pl-2"
          ><span class="badge badge-warning l-h px-2">{{
            "only_new" | translate
          }}</span></label
        >
      </div>

      <!-- Show Exception -->
      <div
        *ngIf="showFilters.includes('show_exception') && isMaster"
        class="filter-items"
      >
        <label>{{ "show_exception" | translate }}</label>
        <div class="row">
          <div class="col-auto">
            <div class="form-check">
              <input type="checkbox" class="formCheckInput mr-1" [(ngModel)]="filters[selectedFilterIndex].show_exception" (click)="onKeyupSearch()">
              <label (click)="filters[selectedFilterIndex].show_exception = !filters[selectedFilterIndex].show_exception; onKeyupSearch()" class="formCheckLabel">{{ 'show_tournaments_with_exception' | translate }}</label>
            </div>
          </div>
        </div>
      </div>

      <!-- Registration Time -->
      <div
        *ngIf="showFilters.includes('registration_time')"
        class="filter-items row"
      >
        <div class="col pl-0">
          <label for="init_stacks">{{
            "from_registration_time" | translate
          }}</label>
  
          <input
            #from_time
            [(ngModel)]="filters[selectedFilterIndex].from_register_time"
            class="form-control"
            id="from_time"
            type="time"
            (blur)="onKeyupSearch()"
            (change)="onKeyupSearch()"
          />
        </div>
        <div class="col pr-0">
          <label for="init_stacks">{{ "to" | translate }}</label>
          <input
            #to_time
            [(ngModel)]="filters[selectedFilterIndex].to_register_time"
            class="form-control"
            id="to_time"
            type="time"
            (blur)="onKeyupSearch()"
            (change)="onKeyupSearch()"
          />
        </div>
      </div>

      <!-- State -->
      <div
        *ngIf="showFilters.includes('state')"
        class="filter-items"
      >
        <label>{{ "state" | translate }}</label>
        <div class="row">
          <div class="col-auto">
            <div class="form-check">
              <input type="radio" (click)="filters[selectedFilterIndex].state = null" class="formCheckInput mr-1" [checked]="filters[selectedFilterIndex].state == null" (change)="onKeyupSearch()">
              <label (click)="filters[selectedFilterIndex].state = null; onKeyupSearch()" class="formCheckLabel">{{ 'all' | translate }}</label>
            </div>
          </div>
          <ng-container *ngFor="let state of dropdownListState">
            <div class="col-auto">
              <div class="form-check">
                <input type="radio" class="formCheckInput mr-1" [checked]="filters[selectedFilterIndex].state && filters[selectedFilterIndex].state[0] == state" (click)="filters[selectedFilterIndex].state = [state]" (change)="onKeyupSearch()">
                <label class="formCheckLabel" (click)="filters[selectedFilterIndex].state = [state]; onKeyupSearch()">{{ state.item_text }}</label>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <!-- Auto Scheduled -->
      <div
        *ngIf="showFilters.includes('auto_scheduled')"
        class="filter-items"
      >
        <label>{{ "auto_scheduled" | translate }}</label>
        <div class="row">
          <div class="col-auto">
            <div class="form-check">
              <input type="radio" (click)="filterAutoScheduled = []" class="formCheckInput mr-1" [checked]="filterAutoScheduled.length == 0" (change)="onKeyupSearch()">
              <label (click)="filterAutoScheduled == []; onKeyupSearch()" class="formCheckLabel">{{ 'all' | translate }}</label>
            </div>
          </div>
          <ng-container *ngFor="let sch of dropdownListAutoScheduled">
            <div class="col-auto">
              <div class="form-check">
                <input type="radio" class="formCheckInput mr-1" [checked]="filterAutoScheduled.length > 0 && filterAutoScheduled[0].item_id == sch.item_id" (click)="filterAutoScheduled = [sch]; onKeyupSearch()" (change)="onKeyupSearch()">
                <label class="formCheckLabel" (click)="filterAutoScheduled = [sch]; onKeyupSearch()">{{ sch.item_text }}</label>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <!-- Rebalance Filters -->
      <div
        *ngIf="showFilters.includes('rebalance')"
        class="filter-items"
      >
        <label>{{ "state" | translate }}</label>
        <div class="row">
          <div class="col-auto mb-2 px-1">
            <div
              class="btn-badge filter-option"
              [ngClass]="{ 'selected': allRebalanceFiltersChecked() }"
              (click)="checkAllRebalanceFilters()"
            >
              {{ 'all' | translate }}  
            </div>
          </div>
          <div class="col-auto mb-2 px-1">
            <div
              class="btn-badge filter-option"
              [ngClass]="{ 'selected': filters[selectedFilterIndex].auto_only }"
              (click)="filters[selectedFilterIndex].auto_only = !filters[selectedFilterIndex].auto_only; onClickSubmitFilters()"
            >
              {{ 'auto_scheduled' | translate }}  
            </div>
          </div>
          <div class="col-auto mb-2 px-1">
            <div
              class="btn-badge filter-option"
              [ngClass]="{ 'selected': filters[selectedFilterIndex].manual_only }"
              (click)="filters[selectedFilterIndex].manual_only = !filters[selectedFilterIndex].manual_only; onClickSubmitFilters()"
            >
              {{ 'manually_scheduled' | translate }}  
            </div>
          </div>
          <div class="col-auto mb-2 px-1">
            <div
              class="btn-badge filter-option"
              [ngClass]="{ 'selected': filters[selectedFilterIndex].deleted_filter_only }"
              (click)="filters[selectedFilterIndex].deleted_filter_only = !filters[selectedFilterIndex].deleted_filter_only; onClickSubmitFilters()"
            >
              {{ 'deleted_filter' | translate }}  
            </div>
          </div>
          <div class="col-auto mb-2 px-1">
            <div
              class="btn-badge filter-option"
              [ngClass]="{ 'selected': filters[selectedFilterIndex].unchecked_filter_only }"
              (click)="filters[selectedFilterIndex].unchecked_filter_only = !filters[selectedFilterIndex].unchecked_filter_only; onClickSubmitFilters()"
            >
              {{ 'filter_unchecked' | translate }}  
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="extra-filters-footer border-top">
      <div class="row justify-content-between">
        <div class="col-auto">
          <button
            [disabled]="bloquedFunctions"
            style="width: 100%; border: none;"
            class="btn btn-outline-secondary"
            type="button"
            (click)="reset()"
          >
            {{ "clear_filters" | translate }}
          </button>
        </div>
        <div class="col-auto">
          <button class="btn btn-success tour-see-results" [disabled]="isLoading" (click)="extraFiltersVisible = false; onClickSubmit()">
            {{ isLoading ? "loading..." : ('see' | translate) + ' ' + totalTournaments + ' ' + ('results' | translate) }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- /Row-->

<!-- Save Filters -->
<div
  [ngClass]="{ 'bloqued-background': bloquedFunctions }"
  style="padding-left: 1.25rem; padding-right: 1.25rem"
  class="row tour-save-filters"
>

  <div
    bsModal
    #dangerModal="bs-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-danger" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{ "confirm_deletion" | translate }}</h4>
          <button
            type="button"
            class="close"
            (click)="dangerModal.hide()"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>{{ "confirm_deletion_filter_text" | translate }}</p>
        </div>
        <div class="modal-footer">
          <button
            [disabled]="bloquedFunctions"
            type="button"
            class="btn btn-secondary"
            (click)="dangerModal.hide(); openSavedFilters();"
          >
            {{ "cancel" | translate }}
          </button>
          <button
            [disabled]="bloquedFunctions"
            type="button"
            class="btn btn-danger"
            (click)="onClickConfirmDeleteFilter(); dangerModal.hide()"
          >
            {{ "delete" | translate }}
          </button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
  <!-- /.modal -->

  <div
    bsModal
    #autoScheduleModal="bs-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-danger" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{ "confirm_auto_schedule" | translate }}</h4>
          <button
            type="button"
            class="close"
            (click)="autoScheduleModal.hide()"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body py-4 px-3" style="white-space: normal">
          <span>{{
            (isEnableAutoSchedule && !isMassAutoSchedule)
              ? ("confirm_enable_auto_schedule" | translate)
              : (!isEnableAutoSchedule && !isMassAutoSchedule) ? ("confirm_disable_auto_schedule" | translate) : ""
          }}
          {{
            (isEnableAutoSchedule && isMassAutoSchedule)
              ? ("confirm_enable_auto_schedule_mass" | translate)
              : (!isEnableAutoSchedule && isMassAutoSchedule) ? ("confirm_disable_auto_schedule_mass" | translate) : ""
          }}</span>
        </div>
        <div class="modal-footer">
          <button
            [disabled]="bloquedFunctions"
            type="button"
            class="btn btn-secondary"
            (click)="autoScheduleModal.hide(); openAutoSchedule()"
          >
            {{ "cancel" | translate }}
          </button>
          <button
            [disabled]="bloquedFunctions"
            type="button"
            class="btn btn-danger"
            (click)="onClickChangeAutoSchedule(); autoScheduleModal.hide(); openAutoSchedule()"
          >
            {{ "confirm" | translate }}
          </button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
  <!-- /.modal -->

  <!-- Modal Saved Filters -->
  <ng-template #savedFilters>
    <div class="modal-header">
      <div style="width: 100%; margin-right: -40px;">
        <h4 class="modal-title">{{ 'saved_filters' | translate }}</h4>
        <p>{{ 'saved_filters_subtitle' | translate }}</p>
        <input type="text" class="form-control" [(ngModel)]="filterSearch" placeholder="{{ 'search_filter_by_name' | translate }}">
      </div>
      <button type="button" class="close" (click)="toggleModal()" aria-label="Close" style="font-size: 40px;">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body px-4">
      <div class="saved-filters-list border-top border-left border-bottom border-right">
        <div class="row border-bottom m-0">
          <div class="col-auto px-0 border-right">
            <input type="checkbox" class="m-3" [checked]="isAllFiltersChecked()" (change)="checkAllFilters($event.target.checked)">
          </div>
          <div class="col px-3 pt-3 pb-2 bg-dark">{{ 'filters' | translate }}</div>
        </div>
        <div *ngIf="loadingFilters" class="text-center p-3">{{ ('loading' | translate) + '...' }}</div>
        <div *ngIf="openedSaved" class="tour-list-filters">
          <ng-container *ngFor="let entry of filters | keyvalue : asIsOrder">
            <div *ngIf="entry.key != 0 && filterOwnerIsMaster[entry.key] && filterMaster[entry.key] == currentUser.id && !filterIsDeleted[entry.key] && (filterSearch == '' || ((entry.value.name).toUpperCase()).includes(filterSearch.toUpperCase()))" class="border-bottom line-item row m-0">
              <div class="col-auto px-0 border-right" style="background-color: rgba(0,0,0,0.2);">
                <input type="checkbox" class="mx-3 mt-3" (click)="checkFilter(entry.key, $event.target.checked)" [checked]="checkedFilters[entry.key]">
              </div>
              <div class="col px-0" style="background-color: rgba(0,0,0,0.2);">
                <a
                  class="dropdown-item filter-item"
                  style="cursor: pointer; white-space: unset;"
                  (click)="onClickSavedFilter(entry.key); toggleModal()"
                >
                  <span>{{ entry.value.name }}</span>
                  <span *ngIf="filterIsAutoSchedule[entry.key]" class="badge badge-scheduled"><i class="fa fa-android"></i>{{ 'auto_schedule' | translate }}</span>
                  <span class="saved-filter-apply">{{ 'apply_filter' | translate }}<i class="fa fa-long-arrow-up ml-2"></i></span>
                </a>
              </div>
              <div class="col-12 px-2 py-2 border-top">
                <div class="row m-0">
                  <ng-container *ngFor="let filter of entry.value | keyvalue : asIsOrderFilters">
                    <div *ngIf="getFilterBadgeText(filter)" class="col-auto px-1 mb-1">
                      <div class="applied-filter-badge">
                        {{ getFilterBadgeText(filter) }}
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="row m-0 pt-2 pb-4 justify-content-evenly">
      <div class="col-4 px-1" style="text-align: center;">
        <label for="file-upload" class="custom-file-upload py-2" style="width: 80%;">
          <i class="fa fa-download mr-1"></i>{{  'import' | translate }}
        </label>
        <input id="file-upload" type="file" (click)= "$event.target.value = ''" (change)="importFiltersJson($event.target)"/>
      </div>
      <div class="col-4 px-1" style="text-align: center;">
        <a class="btn btn-sm btn-success py-2" [href]="downloadJsonHref" [ngClass]="{'link-disabled': isFilterCheckedEmpty()}" download="filters.json" style="width: 80%;"><i class="fa fa-upload mr-1"></i>{{ 'export' | translate }}</a>
      </div>
      <div class="col-4 px-1" style="text-align: center;">
        <button class="btn btn-sm btn-danger py-2" [ngClass]="{'link-disabled': isFilterCheckedEmpty() || bloquedFunctions}" (click)="dangerModal.show(); toggleModal();" style="width: 80%;"><i class="fa fa-trash mr-1"></i>{{ 'delete' | translate }}</button>
      </div>
    </div>
  </ng-template><!-- /.modal -->

    <!-- Modal Auto Schedule -->
    <ng-template #autoSchedule>
      <div class="modal-header">
        <div style="width: 100%; margin-right: -40px;">
          <h4 class="modal-title">{{ 'auto_schedule' | translate }}</h4>
          <p>{{ 'auto_schedule_subtitle' | translate }}</p>
          <div class="row">
            <div [ngClass]="{'col-4 pr-1': showFilterOptions, 'col-12': !showFilterOptions}">
              <input type="text" class="form-control" [(ngModel)]="filterSearch" placeholder="{{ 'search_filter_by_name' | translate }}">
            </div>
            <div class="col-4 px-1" *ngIf="showFilterOptions">
              <ng-multiselect-dropdown
                class="select-filter-master"
                [(ngModel)]="masterSearch"
                [placeholder]="'Master'"
                [settings]="dropdownSettings"
                [data]="dropdownListMasters"
              >
              </ng-multiselect-dropdown>
            </div>
            <div class="col-4 pl-1" *ngIf="showFilterOptions">
              <ng-multiselect-dropdown
                [(ngModel)]="autoScheduleSearch"
                [placeholder]="'auto_scheduled' | translate"
                [settings]="dropdownSettings"
                [data]="dropdownListAutoScheduledSearch"
              >
              </ng-multiselect-dropdown>
            </div>
          </div>
        </div>
        <button type="button" class="close" (click)="toggleModal()" aria-label="Close" style="font-size: 40px;">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body px-3">
        <div class="saved-filters-list border-top border-left border-bottom border-right">
          <div class="row border-bottom bg-dark m-0">
            <div class="px-3 pt-3 pb-2" [ngClass]="{'col-4': isMaster, 'col-6': !isMaster}">{{ 'filter' | translate }}</div>
            <div *ngIf="isMaster" class="col-4 pl-3 pt-3 pb-2">{{ 'created_by' | translate }}</div>
            <div class="col pr-3 pl-3 pt-3 pb-2">{{ 'priority' | translate }}</div>
            <div class="col-auto pl-3 pt-3 pb-2" style="min-width: 190px; padding-right: 22px;">
              <div class="row justify-content-end">
                <div class="col-auto px-1">
                  {{ 'auto_schedule' | translate }}
                </div>
                <div class="col-auto pl-1 pr-4">
                  <label class="switch">
                    <div class="switchCheck" [ngClass]="{'checked': isAllAutoSchedule()}">
                      <span class="slider round" (click)="
                        onClickOpenAutoSchedule(0, !isAllAutoSchedule(), true);
                        toggleModal();
                        autoScheduleModal.show()
                      "></span>
                      <span class="slider-text">{{ isAllAutoSchedule() ? 'on' : 'off' }}</span>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="loadingFilters" class="text-center p-3">{{ ('loading' | translate) + '...' }}</div>
          <div *ngIf="openedAuto" class="tour-list-filters-auto">
            <ng-container *ngFor="let entry of filters | keyvalue : asIsOrder">
              <div *ngIf="entry.key != 0 && filterOwnerIsMaster[entry.key] && !filterIsDeleted[entry.key] && showFilterFromMaster(entry.key) && (filterSearch == '' || ((entry.value.name).toUpperCase()).includes(filterSearch.toUpperCase()))" class="border-bottom line-item row ml-0">
                <div class="px-3 py-2" [ngClass]="{'col-4': isMaster, 'col-6': !isMaster}" style="background-color: rgba(0,0,0,0.3);">
                  <span class="font-weight-bold">{{ entry.value.name }}</span>
                </div>
                <div *ngIf="isMaster" class="col-4 px-3 py-2" style="background-color: rgba(0,0,0,0.3);">
                  {{ filtersByMaster[filterMaster[entry.key]].name }}
                </div>
                <div class="col pt-1" style="background-color: rgba(0,0,0,0.2);">
                  <select *ngIf="filterMaster[entry.key] == currentUser.id; else disabledPriority" [(ngModel)]="filterPriority[entry.key]" class="custom-select text-capitalize" style="height: 30px; padding-top: 4px;" (change)="onClickChangePriority(entry.key)">
                    <option value="0">N/A</option>
                    <option value="1">1 - {{ 'VERY_LOW' | translate }}</option>
                    <option value="2">2 - {{ 'VERY_LOW' | translate }}</option>
                    <option value="3">3 - {{ 'LOW' | translate }}</option>
                    <option value="4">4 - {{ 'LOW' | translate }}</option>
                    <option value="5">5 - {{ 'MEDIUM' | translate }}</option>
                    <option value="6">6 - {{ 'MEDIUM' | translate }}</option>
                    <option value="7">7 - {{ 'MEDIUM' | translate }}</option>
                    <option value="8">8 - {{ 'HIGH' | translate }}</option>
                    <option value="9">9 - {{ 'HIGH' | translate }}</option>
                    <option value="10">10 - {{ 'HIGH' | translate }}</option>
                  </select>
                  <ng-template #disabledPriority>
                    <div class="pt-1 pb-2">
                      {{ getPriorityText(filterPriority[entry.key]) }}
                    </div>
                  </ng-template>
                </div>
                <div class="col-auto" style="padding-top: 6px; background-color: rgba(0,0,0,0.2); min-width: 190px; text-align: right; padding-right: 30px;">
                  <label class="switch" *ngIf="!isMaster || (isMaster && filterMaster[entry.key] == currentUser.id); else disabledSwitch">
                    <div class="switchCheck" [ngClass]="{'checked': filterIsAutoSchedule[entry.key]}">
                      <span class="slider round" (click)="
                        onClickOpenAutoSchedule(entry.key, !filterIsAutoSchedule[entry.key]);
                        toggleModal();
                        autoScheduleModal.show()
                      "></span>
                      <span class="slider-text">{{ filterIsAutoSchedule[entry.key] ? 'on' : 'off' }}</span>
                    </div>
                  </label>
                  <ng-template #disabledSwitch>
                    <span class="px-3" [ngClass]="{'text-success': filterIsAutoSchedule[entry.key], 'text-danger': !filterIsAutoSchedule[entry.key]}">{{ filterIsAutoSchedule[entry.key] ? 'on' : 'off' }}</span>
                  </ng-template>
                </div>
                <div class="col-12 px-2 py-2">
                  <div class="row m-0">
                    <ng-container *ngFor="let filter of entry.value | keyvalue : asIsOrderFilters">
                      <div *ngIf="getFilterBadgeText(filter)" class="col-auto px-1 mb-1">
                        <div class="applied-filter-badge">
                          {{ getFilterBadgeText(filter) }}
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="row m-0 pt-2 pb-4 justify-content-between">
        <div class="col-auto px-3">
          <button class="btn btn-sm btn-outline-secondary py-2 px-4" style="width: 280px;" (click)="onClickExecuteAutoScheduler();" [disabled]="lockedAutoScheduler" [style.opacity]="lockedAutoScheduler ? 0.2 : 1">{{ loadingAutoScheduler ? ( 'loading' | translate ) : ( 'execute_auto_scheduler' | translate ) }}<i class="ml-2 fa fa-refresh" [ngClass]="{'rotate-animation' : loadingAutoScheduler}"></i></button>
        </div>
        <div class="col-auto px-3" style="text-align: center;">
          <button class="btn btn-sm btn-outline-secondary py-2 px-4" (click)="toggleModal();">{{ 'close' | translate }}</button>
        </div>
      </div>
    </ng-template><!-- /.modal -->

</div>
<!-- /Row-->

<!-- Modal Edit Saved Filter-->
<div bsModal #modalEditSavedFilter="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{ 'save_filter' | translate }}</h4>
        <button type="button" class="close tour-close-delete" (click)="modalEditSavedFilter.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <span>{{ 'filter_name' | translate }}</span>
        <input
          [disabled]="bloquedFunctions"
          [(ngModel)]="filterName"
          class="form-control tour-name-filter"
          id="save_filter"
          name="save_filter"
          type="text"
          placeholder="{{ 'filter_name' | translate }}"
        />
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modalEditSavedFilter.hide()">
          {{ 'cancel' | translate }}
        </button>
        <button type="button" class="btn btn-success" (click)="modalEditSavedFilter.hide(); onClickSaveFilter()">
          {{ 'save' | translate }}
        </button>
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
  <!-- /.modal-dialog -->
</div>
<!-- /.modal -->
