import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import posthog from "posthog-js";

import { AppModule } from "./app/app.module";
import { environment } from "./environments";
import { hmrBootstrap } from "./hmr";
import { Native } from "./native";

const bootstrap = () =>
  platformBrowserDynamic()
    .bootstrapModule(AppModule, {
      useJit: true,
      preserveWhitespaces: true,
    })
    .catch((err) => {
      console.log(err);
      return err;
    });

const isProd = environment.production;

function initialize() {
  if (isProd) {
    enableProdMode();
  }

  if (!posthog.__loaded) {
    // @ts-ignore
    window.__ph = posthog.init(environment.POSTHOG_KEY || "dev", {
      api_host: environment.POSTHOG_HOST || "dev",
      person_profiles: "identified_only",
      capture_pageview: false,
      capture_pageleave: true,
      autocapture: {
        url_ignorelist: [".*/(?:on-time-tournaments|grind-mode).*"],
      },
      loaded: function (ph) {
        if (!isProd) {
          ph.opt_out_capturing();
          ph.set_config({
            capture_pageview: false,
            capture_dead_clicks: false,
            capture_heatmaps: false,
            capture_exceptions: false,
            capture_pageleave: false,
            capture_performance: false,
            disable_session_recording: true,
          });
        }
      },
    });
  }

  if (!isProd && module["hot"]) {
    hmrBootstrap(module, bootstrap);
  } else {
    // Standard app entrypoint
    bootstrap();
  }
}

(async () => {
  await Native.nativeSetup();

  initialize();
})();
