import { Directive, ElementRef, HostListener, Input } from "@angular/core";
import { formatToCurrency, formatToMoney } from "../utils/functions.utils";

@Directive({
  selector: "[appCurrencyMask]",
})
export class CurrencyMaskDirective {
  constructor(private el: ElementRef) {}

  @HostListener("keyup", ["$event"])
  addMask(event: any) {
    const value = event.target.value.replace(/\D/g, "");
    event.target.value = formatToMoney(value);
  }
}
