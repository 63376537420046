import { invoke } from "@tauri-apps/api/core";
import { listen, UnlistenFn } from "@tauri-apps/api/event";

export namespace NativeUpdater {
  type ProgressEvent =
    | { type: "progress"; percent: number }
    | { type: "error"; error: string }
    | { type: "complete" };

  export async function listenAvailableUpdate(
    callback: () => void
  ): Promise<UnlistenFn> {
    return await listen("update/update-available", () => {
      callback();
    });
  }

  export async function listenDownloadProgress(
    callback: (ev: ProgressEvent) => void
  ): Promise<UnlistenFn> {
    return await listen<ProgressEvent>("update/download-progress", (e) => {
      callback(e.payload);
    });
  }

  export async function hasUpdateAvailable(): Promise<boolean> {
    return await invoke("update_checker_has_update_available");
  }

  export async function startDownload(): Promise<boolean> {
    return await invoke("update_checker_download_update");
  }

  export async function installUpdate(): Promise<void> {
    return await invoke("update_checker_install_update");
  }
}
