<div id="snackbar" [ngClass]="{ show: showSnackbar }">
  {{ snackbarMessage }}
</div>
<div>
  <button class="btn btn-sm btn-outline-primary" *ngIf="mode != 'schedule'" (click)="openModal(modalTemplate)">
    <i class="fa fa-bullhorn"></i>
    {{ "request_tournament_modal_title" | translate }}
  </button>

  <ng-container *ngIf="mode == 'schedule'">
    <div (click)="openModal(modalTemplate)">
      <i class="fa fa-bullhorn"></i>{{ 'request_tournament_modal_title' | translate }}
    </div>
  </ng-container>

  <ng-template #modalTemplate>
    <div class="modal-header">
      <h4 class="modal-title">
        {{
          alreadySearch
            ? ("request_tournament_modal_title" | translate)
            : ("request_search_tournament_modal_title" | translate)
        }}
      </h4>
      <button
        type="button"
        class="close"
        (click)="isSubmited = false; alreadySearch = false; modalRef.hide()"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body modal-wrapper">
      <form
        [class]="
          isSubmited
            ? 'search-form was-validated'
            : 'search-form needs-validation'
        "
        [ngClass]="{
          'move-form-up': alreadySearch
        }"
        #form="ngForm"
        novalidate
      >
        <label for="site"
          >Site <span class="text-danger" title="Required">*</span></label
        >

        <select
          required
          [(ngModel)]="searchFilters.site"
          name="type"
          class="form-control"
        >
          <option *ngFor="let site of sites" [value]="site.id">
            {{ site.name }}
          </option>
        </select>

        <!-- Date  -->
        <label class="mt-2"
          >Date
          <span class="text-danger" title="Required" *ngIf="!searchFilters.name"
            >*</span
          ></label
        >
        <div class="input-group">
          <!-- input box -->
          <input
            class="form-control"
            name="mydate"
            [required]="!searchFilters.name"
            (click)="dpf.toggleCalendar()"
            angular-mydatepicker
            #dpf="angular-mydatepicker"
            placeholder="--/--/----"
            [(ngModel)]="dateFromModel"
            [options]="dateFromOptions"
            (dateChanged)="onDateChange($event, 'from')"
            [locale]="
              getCurrentLang() == 'en-US'
                ? 'en'
                : getCurrentLang() == 'pt-BR'
                ? 'pt-br'
                : getCurrentLang() == 'es'
                ? 'es'
                : 'ru'
            "
          />
        </div>

        <label class="mt-2" for="registerFrom"
          >Start from
          <span class="text-danger" title="Required" *ngIf="!searchFilters.name"
            >*</span
          ></label
        >
        <input
          type="time"
          [required]="!searchFilters.name"
          class="form-control mb-2 mr-sm-2"
          id="registerFrom"
          name="registerFrom"
          [(ngModel)]="searchFilters.registerFrom"
        />

        <label for="buyIn"
          >Buy-in
          <span class="text-danger" title="Required" *ngIf="!searchFilters.name"
            >*</span
          ></label
        >
        <input
          type="number"
          [required]="!searchFilters.name"
          class="form-control mb-2 mr-sm-2"
          id="buyIn"
          placeholder=""
          name="buyIn"
          [(ngModel)]="searchFilters.buyIn"
        />

        <label for="name">Name </label>
        <input
          type="text"
          class="form-control mb-2 mr-sm-2"
          id="name"
          placeholder=""
          name="name"
          [(ngModel)]="searchFilters.name"
        />
      </form>

      <div *ngIf="alreadySearch" class="text-warning pb-3" style="width: 100%; text-align: center;"><i class="fa fa-warning mr-1 glow"></i>{{ "avoid_select_duplicate" | translate }}</div>
      <table
        class="table table-striped search-table"
        [ngClass]="{
          'move-table-up': alreadySearch
        }"
      >
        <thead>
          <tr>
            <th></th>
            <th>Site</th>
            <th>Start</th>
            <th>Buy-in</th>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let tournament of requestTournamentList">
            <th>
              <div style="position: relative">
                <input
                  [disabled]="tournament.scheduled || tournament.requested"
                  type="checkbox"
                  (change)="handleSelectTournament(tournament.id)"
                />
                <div
                  *ngIf="tournament.scheduled"
                  class="ribbon-wrapper-green"
                >
                  <div class="ribbon-green">{{ "scheduled" | translate }}</div>
                </div>
              </div>
            </th>
            <td>{{ sitesMap[tournament.site]?.name }}</td>
            <td>{{ getOnlyTime(tournament.start) }}</td>
            <td>{{ tournament.buy_in }} {{ tournament.currency }}</td>
            <td>
              {{ tournament.name }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="modal-footer">
      <button
        class="btn btn-link"
        *ngIf="alreadySearch"
        (click)="alreadySearch = false"
      >
        {{ "back_to_filters" | translate }}
      </button>
      <button
        type="button"
        class="btn btn-secondary"
        (click)="isSubmited = false; alreadySearch = false; modalRef.hide()"
      >
        {{ "close" | translate }}
      </button>
      <button
        type="button"
        *ngIf="!isLoading"
        [class]="alreadySearch ? 'btn btn-success' : 'btn btn-primary'"
        (click)="
          alreadySearch ? sendTournamentToApprove() : handleSearchTournament()
        "
      >
        {{ (alreadySearch ? "send" : "search") | translate }}
      </button>
      <button
        type="button"
        *ngIf="isLoading"
        [disabled]="isLoading"
        [class]="alreadySearch ? 'btn btn-success' : 'btn btn-primary'"
      >
        {{ "loading" | translate }} <i class="fa fa-spin fa-spinner"></i>
      </button>
    </div>
  </ng-template>
</div>
