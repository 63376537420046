import { posthog } from "posthog-js";
import { BehaviorSubject, Observable, of } from "rxjs";
import { shareReplay } from "rxjs/operators";
import { environment } from "../../environments";

// This is configured in posthog
//
// You can manually toggle using toolbar
// or
// ```
// window.__ph.featureFlags.overrideFeatureFlags({
//     flags: {
//         'feature-name': true,
//         'variant-feature': 'value_a',
//     }
// })
// ```

const cache = new Map<string, Observable<boolean | string>>();

// Get any of the following features (stops on the first truthy value)
export function IsFeatureEnable(
  ...featureName: string[]
): Observable<boolean | string> {
  const cacheKey = featureName.join("|");

  const cachedValue = cache.get(cacheKey);
  if (cachedValue) {
    return cachedValue;
  }

  const f = environment.FEATURES_STATE;
  if (typeof f === "boolean") {
    const r = of(f).pipe(shareReplay());
    cache.set(cacheKey, r);
    return r;
  }

  const subject = new BehaviorSubject<boolean | string>(false);

  const checkFeatures = () => {
    for (const feature of featureName) {
      const v = posthog.getFeatureFlag(feature, {
        send_event: false,
      });
      if (v) {
        subject.next(v);
        return;
      }
    }
    subject.next(false);
  };
  posthog.onFeatureFlags(() => {
    checkFeatures();
  });

  const r = subject.pipe(shareReplay(1));
  cache.set(cacheKey, r);
  return r;
}
