import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map, tap } from "rxjs/operators";
import { environment } from "../../environments";
import { Site, Tag } from "../interfaces/sites";
import Cookies from "../utils/cookies";

export interface SiteHelper{
  sites:Site[]
  sitesMap:{ [key: number]: Site }
  expiry?: Number
}

@Injectable({
  providedIn: "root",
})
export class SiteService {
  sitesHelper: SiteHelper = null;
  baseURL = environment.API_URL + "site/";

  constructor(private http: HttpClient) {}

  getSites(admin:boolean = false): Observable<Site[]> {
    return this.http.get<Site[]>(this.baseURL + "get-sites", {
      params: {
        admin: admin.toString()
      }
    }).pipe(
      map((e) => {
        const u = e.filter((site) => {
          return site.name != "888Poker(ES-PT)"
        })
        return u
      }),
    );
  }

  getSitesHelpers(admin:boolean = false): Observable<SiteHelper> {
    const now = new Date();

    if (localStorage.getItem('sites-' + admin)) {
      if(now.getTime() > JSON.parse(localStorage.getItem('sites-' + admin)).expiry) {
        localStorage.removeItem('sites-' + admin)
      }
    }

    if (this.sitesHelper) {
      return of(this.sitesHelper);
    } else if (localStorage.getItem('sites-' + admin)) {
      this.sitesHelper = JSON.parse(localStorage.getItem('sites-' + admin))
      return of(JSON.parse(localStorage.getItem('sites-' + admin)))
    } else {
      return this.getSites(admin).pipe(
        map((res) => {
          // WORKAROUND - data that comes from getSites() is different from Site interface
          res.forEach((site) => {
            site.tags.forEach((tag) => {
              tag["tag"] = tag["name"];
              delete tag["name"];
            });
          });
          // END OF WORKAROUND
          let sites = res;
          sites.map((site) => {
            site.tags.sort((a, b) => a.priority - b.priority);
          });
          let sitesMap = {};
          res.forEach((site) => {
            sitesMap[site.id] = site;
          });
          this.sitesHelper = { sites, sitesMap, expiry: now.getTime() + (60000 * 60) };
          localStorage.setItem('sites-' + admin, JSON.stringify(this.sitesHelper))
          return this.sitesHelper;
        })
      );
    }
  }

  createSiteTag(siteID: number, body: Tag): Observable<Tag> {
    return this.http.put<Tag>(this.baseURL + "create-site-tag/" + siteID, body);
  }

  updateTag(tagID: number, body: Tag): Observable<Tag> {
    return this.http.put<Tag>(this.baseURL + "update-tag/" + tagID, body);
  }

  deleteTag(tagID: number): Observable<Tag> {
    return this.http.delete<Tag>(this.baseURL + "delete-tag/" + tagID);
  }

  updateSite(siteID: number, body: any): Observable<Site> {
    return this.http.put<Site>(this.baseURL + "update-site/" + siteID, body);
  }

  createSite(body: any) {
    return this.http.post(this.baseURL + "create-site", body);
  }

  getSeriesTag(): Observable<any> {
    return this.http.get<any>(this.baseURL + "get-series-tags");
  }

  updateSeriesTag(siteID: number, body: any): Observable<any> {
    return this.http.put<any>(this.baseURL + "create-series-tag/" + siteID, body);
  }

  deleteSeriesTag(tagID: number): Observable<Tag> {
    return this.http.delete<Tag>(this.baseURL + "delete-series-tag/" + tagID);
  }

  getSitesLabels() {
    return this.http.get<any>(this.baseURL + "get-label", {
      params: {
        'with_id': '1',
      }
    }).toPromise()
  }

  saveSiteLabels(body: {
    id?: number,
    brand: string,
    label: string,
    site: number,
  }) {
    return this.http.put<HttpResponse<any>>(this.baseURL + "update-label", body).toPromise();
  }

  deleteSiteLabel(id: number) {
    return this.http.post<HttpResponse<void>>(this.baseURL + "delete-label", {
      id,
    }).toPromise();
  }
}
