export namespace Currency {
    export const list = [
        { iso: "USD", symbol: "$" },
        { iso: "EUR", symbol: "€" },
        { iso: "CNY", symbol: "¥" },
        { iso: "VND", symbol: "₫" },
        { iso: "THB", symbol: "฿" },
        { iso: "TWD", symbol: "NT$" },
        { iso: "GBP", symbol: "£" },
        { iso: "BRL", symbol: "R$" },
        { iso: "KRW", symbol: "₩" },
        { iso: "PHP", symbol: "₱" },
        { iso: "JPY", symbol: "¥" }
    ];

    export const isoToSym = {
        USD: "$",
	    EUR: "€",
	    CNY: "¥",
	    GBP: "£",
	    VND: "₫",
	    THB: "฿",
	    TWD: "NT$",
	    BRL: "R$",
	    KRW: "₩",
	    PHP: "₱",
        JPY: "¥"
    };
}