import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, publishReplay, refCount } from 'rxjs/operators';
import { environment } from '../../environments';

@Injectable({
    providedIn: 'root'
})

export class CurrencyService {
    baseURL = environment.API_URL + "v2/misc/rate"

    constructor(private httpClient: HttpClient) { }

    getCurrencies() {
        return this.httpClient.get<{
            rates: {
                [key: string]: number
            }
        }>(this.baseURL)
    }

}