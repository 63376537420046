import { Component, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { take } from 'rxjs/operators';
import { FormControl } from "@angular/forms";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
} from "rxjs/operators";
import { AdsService } from "../../app/services/ads.service";

@Component({
  selector: "get-random-banner",
  templateUrl: "./get-random-banner.component.html",
  styleUrls: ["./get-random-banner.component.css"],
})
export class GetRandomBannerComponent implements OnInit, OnDestroy {

  @Input() tag: string;

  public imageSrc;

  constructor(private adsService: AdsService) {}

  async getRandomMedia() {
    await this.adsService.getRandomMedia(this.tag + '.png').pipe(take(1)).subscribe(data => {
        this.createImageFromBlob(data)
    })
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
       this.imageSrc = reader.result;
    }, false);
 
    if (image) {
       reader.readAsDataURL(image);
    }
  }

  ngOnInit(): void {
    this.getRandomMedia()
  }

  ngOnDestroy(): void {}
}
