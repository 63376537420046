import {
  Component,
  Input,
  OnInit,
  HostListener,
  Output,
  EventEmitter,
  ElementRef,
} from "@angular/core";
// Moment
import * as moment from "moment-timezone";
import { Site } from "../../app/interfaces/sites";
import { trigger, state, style, animate, transition } from '@angular/animations';
import { tournament } from "../../app/utils/tournament";
import { TranslateService } from "@ngx-translate/core";
import { ViewChild } from "@angular/core";
import { SiteService } from "../../app/services/site.service";
import { TierService } from "../../app/services/tier.service";
import { CurrencyService } from "../../app/services/currency.service";
import { Tournament } from "../../app/interfaces/tournament";
import { DateTime } from "luxon";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { generateEventCalendar } from "../../app/utils/functions.utils";
import Cookies from "../../app/utils/cookies";
import { Currency } from "../../app/utils/currency";
import { SubSink } from "subsink";
import { NotificationService } from "../../app/services/notification.service";
import { NativeLobbyOpener } from "../../native/lobby_opener";
import { Tournament as ProtoTournament, TournamentGame } from "../../proto/lobbyze/tournament";
import { Native } from "../../native";
import { Timestamp } from "../../proto/google/protobuf/timestamp";

@Component({
  selector: "list-tournaments",
  templateUrl: "./list-tournaments.component.html",
  styleUrls: ["./list-tournaments.component.scss"],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ scale: 0.8, opacity: 0 }),
            animate('0.2s ease-out',
                    style({ scale: 1, opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ scale: 1, opacity: 1 }),
            animate('0.2s ease-in',
                    style({ scale: 0.8, opacity: 0 }))
          ]
        )
      ]
    ),
    trigger(
      'inOutFloatBar',
      [
        transition(
          ':enter',
          [
            style({ bottom: '-40px' }),
            animate('0.1s ease-out',
                    style({ bottom: '80px' }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ bottom: '80px' }),
            animate('0.1s ease-in',
                    style({ bottom: '-40px' }))
          ]
        )
      ]
    ),
  ]
})
export class ListTournamentsComponent implements OnInit {

  @Input() tournaments = [];
  @Input() playList;
  @Input() waitingList;
  @Input() showColumns = ['checkbox','site', 'start', 'buy_in', 'name', 'prize_pool', 'blinds', 'speed', 'state', 'field', 'duration', 'priority', 'ability', 'max_rebuy', 'registration_time', 'group_id', 'end_time', 'game']

  @Input() tableMode = "tournaments"
  @Input() virtualScroll = false
  @Input() height = "unset"
  @Input() width = "unset"
  @Input() order;
  @Input() weekChecked;
  @Input() isMaster: boolean = false;
  @Input() hasTeam: boolean = false;
  @Input() playingDisabled: boolean = false;
  @Input() isPlayingList: boolean = false;
  @Input() isOldPlayingList: boolean = false;
  @Input() licenseType: string = "free";
  @Input() requestUserId: number = null;
  @Input() requestTierId: number = null;
  @Input() tournamentsById = {};
  @Input() isAdmin = false;
  @Input() isSimpleMode = true;
  @Input() numberOfTiers = 1;
  @Input() countSelected = 0;
  @Input() groupByHour = false;
  @Input() savedFilters: { [key: number]: any } = {};

  @Output() queryDetails = new EventEmitter<object>();
  @Output() checkedTournaments = new EventEmitter<any>();
  @Output() openEdit = new EventEmitter<number>();
  @Output() openEditCopy = new EventEmitter<number>();
  @Output() openDelete = new EventEmitter<number>();
  @Output() openDeleteAdd = new EventEmitter<Array<number>>();
  @Output() openEnable = new EventEmitter<Array<number>>();
  @Output() openReport = new EventEmitter<object>();
  @Output() openSkip = new EventEmitter<object>();
  @Output() bustTournament = new EventEmitter<object>();
  @Output() addToBustLate = new EventEmitter<object>();
  @Output() startPlay = new EventEmitter<object>();
  @Output() removeFromPlaying = new EventEmitter<object>();
  @Output() removeFromPlayingOld = new EventEmitter<object>();
  @Output() skipTournament = new EventEmitter<object>();
  @Output() keepOldTournament = new EventEmitter<object>();
  @Output() decreaseRebuy = new EventEmitter<object>();
  @Output() increaseRebuy = new EventEmitter<object>();
  @Output() hideTournament = new EventEmitter<object>();
  @Output() confirmBust = new EventEmitter<object>();
  @Output() openAcceptRequest = new EventEmitter<object>();
  @Output() openDeclineRequest = new EventEmitter<object>();
  @Output() setFavorite = new EventEmitter<object>();
  @Output() scheduleTournaments = new EventEmitter();
  @Output() massScheduleTournaments = new EventEmitter();
  @Output() ignoreRebalance = new EventEmitter<Array<number>>();
  @Output() removeRebalance = new EventEmitter<Array<number>>();

  private subs = new SubSink();

  public rowsByHour = {};

  onClickIgnoreRebalance(id, isMass = false) {
    if(isMass) {
      const idList: number[] = [];
      idList.push(
        ...Object.entries(this.checkedTorneios)
          .filter(([_, ok]) => ok)
          .map((e) => +e[0])
      );
      this.ignoreRebalance.emit(idList)
    } else {
      this.ignoreRebalance.emit([id])
    }
  }

  onClickRemoveRebalance(id, isMass = false) {
    if(isMass) {
      const idList: number[] = [];
      idList.push(
        ...Object.entries(this.checkedTorneios)
          .filter(([_, ok]) => ok)
          .map((e) => +e[0])
      );
      this.removeRebalance.emit(idList)
    } else {
      this.removeRebalance.emit([id])
    }
  }

  onClickSetFavorite(tor){
    tor.metadata.FAVORITE = !tor.metadata.FAVORITE;
    this.setFavorite.emit({schedules: [tor.id], unfavorite: !tor.metadata?.FAVORITE })
  }

  onClickSendQueryDetails() {
    this.queryDetails.emit(this.queryDetailsObject);
  }

  onClickSendCheckedTournaments() {
    if (this.tableMode == 'schedule') {
      this.checkedTournaments.emit({scheduleIds: this.checkedTorneios, tournamentIds: this.checkedScheduleTournamentId})
    } else {
      this.checkedTournaments.emit(this.checkedTorneios);
    }
  }

  onClickOpenEdit(id: number) {
    this.openEdit.emit(id);
  }

  onClickOpenDelete(id: number) {
    this.openDelete.emit(id);
  }

  onClickOpenDeleteAdd(checked: boolean, id?: number) {
    if (checked) {
      const idList: number[] = [];
      idList.push(
        ...Object.entries(this.checkedTorneios)
          .filter(([_, ok]) => ok)
          .map((e) => +e[0])
      );
      this.openDeleteAdd.emit(idList)
    } else {
      this.openDeleteAdd.emit([id]);
    }
  }

  onClickOpenEnable(checked: boolean, id?: number) {
    if (checked) {
      const idList: number[] = [];
      idList.push(
        ...Object.entries(this.checkedTorneios)
          .filter(([_, ok]) => ok)
          .map((e) => +e[0])
      );
      this.openEnable.emit(idList)
    } else {
      this.openEnable.emit([id]);
    }
  }

  onClickOpenEditCopy(id: number) {
    this.openEditCopy.emit(id);
  }

  onClickOpenReport(tor: object) {
    this.openReport.emit(tor);
  }

  onClickOpenSkip(tor: object) {
    this.openSkip.emit(tor);
  }

  onClickBustTournament(tor: object) {
    this.bustTournament.emit(tor);
  }

  onClickAddToBustLate(tor: object) {
    this.addToBustLate.emit(tor);
  }

  onClickStartPlay(tor: object) {
    this.startPlay.emit(tor);
  }

  onClickRemoveFromPlaying(tor: object) {
    this.removeFromPlaying.emit(tor);
  }

  onClickRemoveFromPlayingOld(tor: object) {
    this.removeFromPlayingOld.emit(tor);
  }

  onClickSkipTournament(tor: object) {
    this.skipTournament.emit(tor);
  }

  onClickKeepOldTournament(tor: object) {
    this.keepOldTournament.emit(tor);
  }

  onClickDecreaseRebuy(tor: object) {
    this.decreaseRebuy.emit(tor);
  }

  onClickIncreaseRebuy(tor: object) {
    this.increaseRebuy.emit(tor);
  }

  onClickHideTournament(tor: object) {
    this.hideTournament.emit(tor);
  }

  onClickConfirmBust(tor: object, rebuy: number, itm: string) {
    this.confirmBust.emit({ tor, rebuy, itm });
  }

  onClickOpenAcceptRequest(tor: object, user: number, tier: number) {
    this.openAcceptRequest.emit({tor, user, tier});
  }

  onClickOpenDeclineRequest(tor: object, user: number, tier: number) {
    this.openDeclineRequest.emit({tor, user, tier})
  }

  onClickScheduleTournaments() {
    this.scheduleTournaments.emit()
  }

  onClickSelectUserTier() {
    this.massScheduleTournaments.emit()
  }

  @ViewChild('scrollContainer') scrollContainer: ElementRef;
  @ViewChild('myTable') table: any;
  @ViewChild('tableElement') tableElement: ElementRef;
  @ViewChild('divToScroll') divToScroll: ElementRef;

  private sites: Site[];
  private sitesMap: { [key: number]: Site } = {};

  public flagged: Array<boolean> = [];
  public allFlagged: boolean = false;

  public queryDetailsObject: any = {
    order: window.location.pathname == "/rebalance" ? { start: 1 } : { state: 1 },
    limit: 50,
    page: 1,
  };

  public readonly tournament = tournament;

  public innerWidth: any;
  public tableHasHorizontalScroll = true;

  public extraFiltersVisible = false;

  public selectedFilterIndex: number = 0;

  public openComment = null;

  public isAllEnabled = null;

  // Snackbar Message
  public showSnackbar = false;
  public snackbarMessage = "";

  public today = new Date();

  public myTimeZone = moment.tz.guess();

  public checkedTorneios: Array<boolean> = [];
  public checkedScheduleTournamentId: Array<boolean> = [];

  public currentPag = 1;

  public priority: string = "1";

  public isoToSym = Currency.isoToSym;

  public currencyRates: { [key: string]: number } = {};

  public readonly formatMoney = tournament.formatMoney;

  public selectedRowRebuy = 0;

  public tableOrder: any = window.location.pathname == "/rebalance" ? { start: 1 } : { state: 1 };
  currentOrder = 1;

  columns = [
    { name: 'site', i18n: 'site' },
    { name: 'priority', i18n: 'priority' },
    { name: 'start', i18n: 'start' },
    { name: 'registration_time', i18n: 'reg_time' },
    { name: 'buy_in', i18n: 'buy_in' },
    { name: 'name', i18n: 'name' },
    { name: 'prize_pool', i18n: 'prize_pool' },
    { name: 'max_rebuy', i18n: 'max_rebuy' },
    { name: 'blinds', i18n: 'number_blinds_capital' },
    { name: 'speed', i18n: 'speed' },
    { name: 'state', i18n: 'state' },
    { name: 'field', i18n: 'field' },
    { name: 'duration', i18n: 'end_time' },
    { name: 'ability', i18n: 'ability' },
    { name: 'group_id', i18n: 'group_id' },
    { name: 'variant', i18n: 'variant' },
    { name: 'game', i18n: 'game' }
  ];

  allColumns = [
    { name: 'site', i18n: 'site' },
    { name: 'priority', i18n: 'priority' },
    { name: 'start', i18n: 'start' },
    { name: 'registration_time', i18n: 'reg_time' },
    { name: 'buy_in', i18n: 'buy_in' },
    { name: 'name', i18n: 'name' },
    { name: 'prize_pool', i18n: 'prize_pool' },
    { name: 'max_rebuy', i18n: 'max_rebuy' },
    { name: 'blinds', i18n: 'number_blinds_capital' },
    { name: 'speed', i18n: 'speed' },
    { name: 'state', i18n: 'state' },
    { name: 'field', i18n: 'field' },
    { name: 'duration', i18n: 'end_time' },
    { name: 'ability', i18n: 'ability' },
    { name: 'group_id', i18n: 'group_id' },
    { name: 'variant', i18n: 'variant' },
    { name: 'game', i18n: 'game' }
  ];

  columnsOptions = false;

  public isDarkTheme = document.body.classList.contains("dark-theme");

  public selectedAllSch = false;

  public siteCanOpenLobby = new Set<number>();

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 1366) {
      this.tableHasHorizontalScroll = true;
    } else {
      this.tableHasHorizontalScroll = false;
    }
  }

  constructor(
    private translateService: TranslateService,
    private siteService: SiteService,
    private tierService: TierService,
    private currencyService: CurrencyService,
    private notificationService: NotificationService,
    private elem: ElementRef
  ) {}

  isAllChecked() {
    const size = this.checkedTorneios.length === this.tournaments.length;
    if (!size) {
      return false;
    }
    return this.checkedTorneios.every((e) => e === true);
  }

  isAllFlagged() {
    var all = true;

    if(this.tableMode == 'tournaments') {
      this.tournaments.map((tor) => {
        if(this.checkedTorneios[tor.id]){
          if(!this.flagged[tor.id]){
            all = false;
          }
        }
      })
    } else {
      this.tournaments.map((tor) => {
        if(!this.flagged[this.tableMode == 'schedule' ? tor.tournament_id : tor.id]){
          all = false;
        }
      })
    }

    this.allFlagged = all;
  }

  checkIfAllEnabled() {

    let allEnabled = true;
    let allDisabled = true;

    const idList: number[] = [];
      idList.push(
        ...Object.entries(this.checkedTorneios)
          .filter(([_, ok]) => ok)
          .map((e) => +e[0])
      );

    if(idList.length == 0) {
      this.isAllEnabled = null
    } else {
      idList.map((e) => {
        if(this.tournamentsById[e]?.is_disabled){
          allEnabled = false;
        } else {
          allDisabled = false;
        }
      })

      this.isAllEnabled = allEnabled ? true : allDisabled ? false : null
    }
  }

  checkedMe(tor) {
    const id = tor.id;
    const tournament_id = tor.tournament_id ?? null;
    if (this.checkedTorneios[id]) {
      this.checkedTorneios[id] = !this.checkedTorneios[id];
      if(tournament_id && this.checkedScheduleTournamentId[tournament_id]) {
        this.checkedScheduleTournamentId[tournament_id] = !this.checkedScheduleTournamentId[tournament_id]
      }
    } else {
      this.checkedTorneios[tor.id] = true;
      if(tournament_id) {
        this.checkedScheduleTournamentId[tournament_id] = true;
      }
    }
    if(this.tableMode == 'addTournaments' || this.tableMode == 'addTournamentsTools' || this.tableMode == 'addTournamentsTier') {
      this.checkIfAllEnabled();
    }
    if(this.tableMode == 'tournaments') {
      this.isAllFlagged();
      this.getSelectedIsSchedule();
    }
    this.getCountSelected();
    this.onClickSendCheckedTournaments();
  }

  flaggedMe(tor) {
    const id = this.tableMode == 'schedule' ? tor.tournament_id : tor.id;
    if (this.flagged[id]) {
      this.flagged[id] = !this.flagged[id];
    } else {
      this.flagged[id] = true;
    }
    this.isAllChecked();
    this.saveRedFlags();
  }

  checkAllTournaments(ev) {
    const checkAll = ev.target.checked;
    if (checkAll) {
      if(this.tableMode == 'addTournamentsTier' || (this.tableMode == 'addTournamentsTools' && !this.isAdmin)) {
        const tournamentsToCheck = this.tournaments.filter((e) => e.owner_id)

        this.checkedTorneios = tournamentsToCheck.reduce((acc, tor) => {
          acc[tor.id] = true;
          return acc;
        }, []);

        this.checkedScheduleTournamentId = tournamentsToCheck.reduce((acc, tor) => {
          acc[tor.tournament_id] = true;
          return acc;
        }, []);
      } else {
        this.checkedTorneios = this.tournaments.reduce((acc, tor) => {
          acc[tor.id] = true;
          return acc;
        }, []);

        this.checkedScheduleTournamentId = this.tournaments.reduce((acc, tor) => {
          acc[tor.tournament_id] = true;
          return acc;
        }, []);
      }
    } else {
      this.checkedTorneios.splice(0, this.checkedTorneios.length);
      this.checkedScheduleTournamentId.splice(0, this.checkedScheduleTournamentId.length)
    }
    if(this.tableMode == 'addTournaments' || this.tableMode == 'addTournamentsTools' || this.tableMode == 'addTournamentsTier') {
      this.checkIfAllEnabled();
    }
    if(this.tableMode == 'tournaments') {
      this.isAllFlagged();
      this.getSelectedIsSchedule();
    }
    this.getCountSelected();
    this.onClickSendCheckedTournaments();
  }

  uncheckTournaments() {
    this.checkedTorneios = [];
    this.checkedScheduleTournamentId = [];
    this.onClickSendCheckedTournaments();
    this.getCountSelected();
  }

  flagAllTournaments(check) {
    if(this.tableMode == 'tournaments'){
      var idList = [];
      idList.push(
        ...Object.entries(this.checkedTorneios)
          .filter(([_, ok]) => ok)
          .map((e) => +e[0])
      );
      this.tournaments.map((e) => {
        if(idList.includes(e.id)) {
          const fId = this.tableMode == 'schedule' ? e.tournament_id : e.id
          this.flagged[fId] = check;
        }
      })
    } else {
      this.tournaments.map((e) => {
        const fId = this.tableMode == 'schedule' ? e.tournament_id : e.id;
        this.flagged[fId] = check;
      })
    }
    this.isAllFlagged();
    this.saveRedFlags();
  }

  onClickOrder(item: string) {
    const currentOrder = Object.keys(this.queryDetailsObject.order)[0];

    if (currentOrder == item) {
      this.queryDetailsObject.order = {
        [item]: this.queryDetailsObject.order[item] * -1,
      };
    } else {
      this.queryDetailsObject.order = { [item]: 1 };
    }

    this.queryDetailsObject.page = 1;

    this.onClickSendQueryDetails();
  }

  getSortClass(item: string) {
    if (this.queryDetailsObject.order[item] == 1) {
      return "fa fa-sort-up";
    } else if (this.queryDetailsObject.order[item] == -1) {
      return "fa fa-sort-down";
    } else {
      return "fa fa-sort";
    }
  }

  getCellBlindClass({ row, column, value }): any {
    return {
      "red-blind": row.current_blind > -1 && row.current_blind <= 10,
    };
  }

  getCellAbilityClass({ row, column, value }): any {
    return {
      "red-blind": row.av_ability >= 80,
    };
  }

  saveColumns(columns) {
    Cookies.set(
      "tourColumnsSaved-" + this.tableMode,
      JSON.stringify(columns),
      { expires: 365 }
    );
  }

  getMaxLate() {
    return localStorage.getItem("timeMaxLate") ? (' (' + localStorage.getItem("timeMaxLate") + ' min)') : "";
  }

  utcToLocal(date: number, showDay = true) {

    var format = ""
    switch(this.tableMode){
      case "tournaments":
        format = "ddd D, hh:mm A";
        break;
      case "addTournaments":
        format = "Y-MM-DD (dddd), hh:mm A"
        break;
      case "addTournamentsTools":
        format = "Y-MM-DD (dddd), hh:mm A"
        break;
      case "addTournamentsTier":
        format = "Y-MM-DD (dddd), hh:mm A"
        break;
      case "rebalance":
        format = "Y-MM-DD, hh:mm A"
        break;
      default:
        format = `${showDay ? 'D, ' : ''} hh:mm A`
    }

    const locale = 'en-US';
    moment.locale(locale)
    const dt = moment(date).format(format);
    return dt;
  }

  getHoursAndMinutes(start: string, min: number) {
    const startDate = new Date(start);
    const endTimeMil = (startDate.getTime() / 1000) + Math.floor(min) * 60;

    const endTimeDate = new Date(endTimeMil * 1000);

    const hour = endTimeDate.getHours().toString();
    const minute = endTimeDate.getMinutes().toString();

    const startDay = startDate.getDay();
    const endTimeDay = endTimeDate.getDay();

    const daysi18n = [
      "letter_sunday",
      "letter_monday",
      "letter_tuesday",
      "letter_wednesday",
      "letter_thursday",
      "letter_friday",
      "letter_saturday"
    ]

    var day = ""

    if(startDay != endTimeDay) {
      day = this.translateService.instant(daysi18n[endTimeDay]) + ", "
    }

    return (
      day +
      (hour.length == 1 ? '0' + hour : hour)
      + ':' +
      (minute.length == 1 ? '0' + minute : minute)
    );
  }

  getNotificationTime(unix) {
    const milliseconds = Math.abs(unix) * 1000;

    const dateObject = new Date(milliseconds);

    const hours = dateObject.getHours();

    const minutes = "0" + dateObject.getMinutes();

    return hours + ":" + minutes.substr(-2);
  }

  getCurrentBlind(blind: number): string {
    if (blind <= 0) {
      return "-";
    } else {
      return Number.isInteger(blind)
        ? blind.toString()
        : Number(blind).toFixed(1);
    }
  }

  minutesLeft(start: string, late_register: number): any {
    const lateRegisterDate = DateTime.fromISO(start).plus({
      minute: late_register,
    });
    const { minutes } = lateRegisterDate.diffNow("minute").toObject();
    return Math.trunc(minutes);
  }

  calcLateTime(tournament: any): any {
    const { start, late_register, playing } = tournament;
    const lateRegisterDate = DateTime.fromISO(start).plus({
      minute: late_register,
    });
    const { hours, minutes, seconds } = lateRegisterDate
      .diffNow(["hours", "minute", "seconds"])
      .toObject();

    if (playing && seconds >= 0) {
      return "Playing";
    }

    if (seconds < 0) {
      this.onClickHideTournament(tournament);
      return "Reg. closed";
    }

    return `${Math.trunc(hours).toFixed().padStart(2, "0")}:${Math.trunc(
      minutes
    )
      .toFixed()
      .padStart(2, "0")}:${Math.trunc(seconds).toFixed().padStart(2, "0")}`;
  }

  isClosed(tournament: any): boolean {
    const { start, late_register } = tournament;
    const lateRegisterDate = DateTime.fromISO(start).plus({
      minute: late_register,
    });
    const { seconds } = lateRegisterDate
      .diffNow(["seconds"])
      .toObject();

    if (seconds < 0) {
      return true
    }

    return false
  }

  getRowMaxRebuy(tor) {
    if (tor.max_rebuy != null && Number(tor.max_rebuy) >= 0) {
      return Number(tor.max_rebuy)
    } else if (tor.metadata?.REBUY != null && Number(tor.metadata?.REBUY) >= 0) {
      return Number(tor.metadata.REBUY)
    } else {
      return null
    }
  }

  checkValidRebuy(tor) {
    if(this.getRowMaxRebuy(tor) != null && tor.rebuy >= this.getRowMaxRebuy(tor)) {
      return this.getRowMaxRebuy(tor);
    } else {
      return tor.rebuy;
    }
  }

  checkValidRebuyInput(tor) {
    setTimeout(() => {
      tor['max_rebuy'] = tor['max_rebuy'].replace(/\D/g, '');
    }, 1000)
  }

  getDollar(tor, field) {
    if (field == 'buy_in') {
      const buyInC = (Number(tor['buy_in']) / Number(this.currencyRates[tor['currency']])).toFixed(2)
      return isNaN(Number(buyInC)) ? '...' : buyInC;
    } else if (field == 'prize_pool') {
      const prizePoolC = (Number(tor['prize_pool']) / Number(this.currencyRates[tor['currency']])).toFixed(2)
      return isNaN(Number(prizePoolC)) ? '...' : prizePoolC;
    }
  }

  styleTournamentName(tournamentName: string, tournamentSite: string, preDeterminedColor: string) {
    var color = "unset";
    var weight = "unset";

    if(preDeterminedColor && preDeterminedColor != "") {
      if(preDeterminedColor.indexOf('#') > -1) {
        color = preDeterminedColor;
      } else {
        color = '#' + preDeterminedColor;
      }
      weight = "bold";
    } else {
      var tag: any;

      try {
        tag = this.sites
          .filter((f) => {
            return f.id == parseInt(tournamentSite);
          })
          .map((m) => m.tags)[0]
          .filter((f2) => {
            if (f2.color == "#000000" && this.isDarkTheme) {
              f2.color = "#a2a2a2";
            }
            return (
              tournamentName.toUpperCase().indexOf(f2.tag.trim().toUpperCase()) >=
              0
            );
          })[0];
      } catch (error) {
        // console.log(error)
      }

      if (tag) {
        color = tag.color;
        weight = "bold";
      }
    }

    return { color: color, "font-weight": weight };
  }

  getTournamentPriorityColor(tour: Tournament) {
    return this.tournament.priorityColor(tour.priority);
  }

  getTournamentPriorityName(tour: Tournament) {
    return this.tournament.priorityName(tour.priority);
  }

  styleSiteBackground(value) {
    return tournament.styleSiteBackground(this.sitesMap, value);
  }

  getRowClass = (row) => {
    let today = new Date();
    let dayToday = today.getDay();

    let startDate = new Date(row.start);
    let startDay = startDate.getDay();

    let isDisabled = false;

    if (
      tournament.getState(row, this.tableMode == 'addTournaments' || this.tableMode == 'addTournamentsTier' || this.tableMode == 'addTournamentsTools' ? false : true) == "Late Reg. Closed" &&
      dayToday >= startDay
    ) {
      isDisabled = true;
    }

    return {
      'disabled-schedule': isDisabled,
      'checked-row': this.checkedTorneios[row.id] ?? false
    }
  }

  getDisabledRowClass = (row) => {
    let isDisabled = false;

    if (
      tournament.getState(row, this.tableMode == 'addTournaments' || this.tableMode == 'addTournamentsTier' || this.tableMode == 'addTournamentsTools' ? false : true) == 'Disabled'
    ) {
      isDisabled = true;
    }

    return {
      'disabled-tournament': isDisabled,
      'checked-row': this.checkedTorneios[row.id] ?? false
    }
  }

  getPlayingRowClass = (row) => {
    return {
      'list-group-item-info': row.isPlayList,
      'list-item-playing': row.playing,
      'checked-row': this.checkedTorneios[row.id] ?? false,
      'list-item-grouped': this.groupByHour && !row.playing && !row.isPlayList
    }
  }

  getCheckedRowClass = (row) => {
    return {
      'checked-row': this.checkedTorneios[row.id] ?? false
    }
  }

  updateTorneioState() {
    this.tournaments = this.tournaments.map((e) => {
      e.late_state = tournament.getState(e, this.tableMode == 'addTournaments' || this.tableMode == 'addTournamentsTier' || this.tableMode == 'addTournamentsTools' ? false : true);
      return e;
    });
  }

  showSnackMessage(msg: string) {
    this.snackbarMessage = msg;
    this.showSnackbar = true;
    setTimeout(() => {
      this.showSnackbar = false;
    }, 3000);
  }

  copyText(text: string) {
    navigator.clipboard.writeText(text).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  }

  toggle(col) {
    const isChecked = this.isChecked(col);

    if (isChecked) {
      this.columns = this.columns.filter(c => {
        return c.name !== col.name;
      });
    } else {
      this.columns = [...this.columns, col];
    }

    this.saveColumns(this.columns);
  }

  toggleAllCol() {
    if(this.isCheckedAllCol()) {
      this.columns = [];
    } else {
      this.columns = this.allColumns;
    }
    this.saveColumns(this.columns);
  }

  isChecked(col) {
    return (
      this.columns.find(c => {
        return c.name === col.name;
      }) !== undefined
    );
  }

  isCheckedAllCol() {
    var cols = this.columns.filter((col) => {
      return this.showColumns.includes(col.name)
    })
    return this.showColumns.length - 1 <= cols.length;
  }

  reordered(e){
    var cols = this.columns.filter((col) => {
      return this.showColumns.includes(col.name)
    })

    cols = this.array_move(cols, e.prevValue - 1, e.newValue -1)
    this.columns = cols;

    this.saveColumns(cols);
  }

  array_move(arr, old_index, new_index) {
    while (old_index < 0) {
        old_index += arr.length;
    }
    while (new_index < 0) {
        new_index += arr.length;
    }
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  };

  toggleCollapse(): void {
    this.extraFiltersVisible = !this.extraFiltersVisible;
  }

  toggleExpandRow(row) {
    console.log("Toggled Expand Row!", row);
    this.table.rowDetail.toggleExpandRow(row);
  }

  onDetailToggle(event: any) {
    console.log("Detail Toggled", event);
    this.selectedRowRebuy = Number(event.value.rebuy);
  }

  public getCurrentLang() {
    return this.translateService.currentLang;
  }

  public getColumnsOptionsWidth(item: string) {
    const height = this.tableElement.nativeElement.offsetHeight;
    if (height > 500){
      return item == 'column' ? 'col-12' : '200px'
    } else if (height > 400){
      return item == 'column' ? 'col-6' : '300px'
    } else if (height > 200){
      return item == 'column' ? 'col-3' : '560px'
    } else {
      return item == "column" ? "col-auto" : "1150px";
    }
  }

  public getCheckboxColumndWidth(tableMode, licenseType, isMaster, isSimpleMode) {
    if(tableMode == 'tournaments') {
      return 35;
    } else if (tableMode == 'rebalance') {
      return 90;
    } else if (tableMode == 'schedule') {
      if(isMaster) {
        return 200;
      } else if (licenseType != 'free') {
        return 200;
      } else {
        return 170;
      }
    } else if (tableMode == 'addTournaments' || tableMode == 'addTournamentsTier') {
      return 155;
    } else if (tableMode == 'addTournamentsTools') {
      return 90;
    } else if (tableMode == 'requested') {
      return 30;
    } else if (tableMode == 'grind') {
      if (isSimpleMode) {
        if(isMaster) {
          return 134;
        } else {
          return 120;
        }
      } else {
        if(isMaster) {
          return 284;
        } else {
          return 270;
        }
      }
    } else if(licenseType != 'free' || isMaster) {
      return 350;
    } else {
      return 320;
    }
  }

  public getStartColumndWidth(tableMode, range) {
    if(range == 'min') {
      if(tableMode == 'tournaments') {
        return 150;
      } else if (tableMode == 'addTournaments' || tableMode == 'addTournamentsTier' || tableMode == 'addTournamentsTools') {
        return 220;
      } else if (tableMode == 'rebalance') {
        return 110;
      } else {
        return 75;
      }
    }
    if(range == 'width') {
      if(tableMode == 'tournaments') {
        return 150;
      } else if (tableMode == 'rebalance') {
        return 110;
      } else if (tableMode == 'addTournaments'|| tableMode == 'addTournamentsTier' || tableMode == 'addTournamentsTools') {
        return 220;
      } else {
        return 75;
      }
    }
    if(range == 'max') {
      if(tableMode == 'tournaments') {
        return 170;
      } else if (tableMode == 'rebalance') {
        return 140;
      } else if (tableMode == 'addTournaments'|| tableMode == 'addTournamentsTier' || tableMode == 'addTournamentsTools') {
        return 250;
      } else {
        return 90;
      }
    }
  }

  async getCurrencies(){
    const data = await this.currencyService.getCurrencies().toPromise()
    this.currencyRates = data.rates;
  }

  async getSites() {
    this.subs.sink = this.siteService.getSitesHelpers(this.isAdmin).subscribe((data) => {
      this.sites = data.sites;
      this.sitesMap = data.sitesMap;
      if(Native.IsNative()) {
        NativeLobbyOpener.availableLobbies().then((available) => {
          const set = new Set<number>();
          for (const siteName of available) {
            const s = data.sites.find(e => e.name == siteName);
            if (s) set.add(s.id);
          }
          this.siteCanOpenLobby = set;
        })
      }
    });
  }

  async getRedFlags(){
    const data = await this.tierService.getRedFlag().toPromise();

    data.flagged_tournaments.map((e) => {
      this.flagged[e] = true;
    })

    this.isAllFlagged();

  }

  async saveRedFlags() {

    const idList: number[] = [];

    idList.push(
      ...Object.entries(this.flagged)
        .filter(([_, ok]) => ok)
        .map((e) => +e[0])
    );

    const payload = {
      tournaments: idList
    }

    await this.tierService.putTierFlag(payload).toPromise()

  }

  getFavorite(tor) {
    return tor.metadata ? tor.metadata.FAVORITE ?? false : false
  }

  getNameScrollClass(container: HTMLTableRowElement, text: HTMLTableRowElement) {
    const difference = container.offsetWidth - text.scrollWidth;
    if (difference < -40) {
      return 'scroll-text-50'
    } else if(difference < -30) {
      return 'scroll-text-40'
    } else if(difference < -20) {
      return 'scroll-text-30'
    } else if(difference < -10) {
      return 'scroll-text-20'
    } else if(difference < 0) {
      return 'scroll-text-10'
    } else {
      return null
    }
  }

  getLastFourGroupId(id: number) {
    if(id > 0) {
      return '...' + id.toString().slice(-4)
    } else {
      return '-'
    }
  }

  getCountSelected() {
    var idList = []
    idList.push(
      ...Object.entries(this.checkedTorneios)
        .filter(([_, ok]) => ok)
        .map((e) => +e[0])
    );

    this.countSelected = idList.length;
  }

  getSelectedIsSchedule() {
    var idList = []
    idList.push(
      ...Object.entries(this.checkedTorneios)
        .filter(([_, ok]) => ok)
        .map((e) => +e[0])
    );

    var allSch = true;
    idList.map((e) => {
      if(!this.tournamentsById[e]?.schedule_id) {
        allSch = false;
      }
    })

    this.selectedAllSch = allSch;
  }

  nameContainsMB(name: string) {
    return name.toUpperCase().includes("MYSTERY") || name.toUpperCase().includes("MB*");
  }

  getTorHour(start) {
    const date = new Date(start);
    return date.getHours();
  }

  isTorNull(tor) {
    if (!tor) {
      return true
    }

    if(tor.start == "0001-01-01T00:00:00Z" && tor.prize_pool == 0 && tor.buy_in == 0) {
      return true
    }

    return false
  }

  getGameText(value: number) {
    if(value == null) {
      return 'Unknown';
    } else {
      if(value == 1) {
        return 'NLH';
      } else if(value == 2) {
        return 'PLO4';
      } else if(value == 3) {
        return 'Other';
      } else if(value == 4) {
        return 'PLO5';
      } else if(value == 5) {
        return 'PLO6';
      } else {
        return value;
      }
    }
  }

  async ngOnInit() {
    document.addEventListener('scroll', this.scroll, true);
    this.getSites()
    this.getCurrencies();

    if(this.tableMode == 'grind') {
      this.tournaments = this.tournaments.filter((e) => {
        return !e.hide
      })
    }

    if(this.playList && this.waitingList) {
      this.playList.map((e) => {
        e.isPlayList = true
      })
      this.waitingList.map((e) => {
        e.isPlayList = false
      })
      this.tournaments = [...this.playList, ...this.waitingList]
    }

    this.queryDetailsObject.order = this.order
      ? this.order
      : this.queryDetailsObject.order;

    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 1366) {
      this.tableHasHorizontalScroll = true;
    } else {
      this.tableHasHorizontalScroll = false;
    }

    setInterval(() => {
      this.updateTorneioState();
    }, 1000);

    if(this.groupByHour){
      this.rowsByHour = [];
      var atachTo = null;
      for(let i = 0; i < this.tournaments.length; i++) {
        const tor = this.tournaments[i];

        const start = new Date(tor.start);
        const hour = start.getHours();

        if(i == 0) {
          this.rowsByHour[tor.id] = {
            hour: hour,
            rows: 1
          }
          atachTo = tor.id;
        } else {
          const prevStart = new Date(this.tournaments[i-1].start);
          const prevHour = prevStart.getHours();

          if(prevHour == hour) {
            this.rowsByHour[atachTo].rows += 1;
          } else {
            atachTo = tor.id;
            this.rowsByHour[tor.id] = {
              hour: hour,
              rows: 1
            }
          }
        }
      }
    }
  }

  ngOnChanges() {
    if(this.isMaster && (this.tableMode == 'schedule' || this.tableMode == 'tournaments')) {
      this.getRedFlags();
    }
    if(this.tableMode == 'grind') {
      this.tournaments = this.tournaments.filter((e) => {
        return !e.hide
      })
    }
    if(this.playList && this.waitingList) {
      this.playList.map((e) => {
        e.isPlayList = true
      })
      this.waitingList.map((e) => {
        e.isPlayList = false
      })
      this.tournaments = [...this.playList, ...this.waitingList]
    }
    if(this.tableMode != "embedded" && Cookies.get('tourColumnsSaved-' + this.tableMode)) {
      this.columns = (JSON.parse(Cookies.get('tourColumnsSaved-' + this.tableMode)));
    } else {
      this.columns = this.allColumns;
    }
    this.checkedTorneios =
      this.tableMode == "schedule" && this.weekChecked
        ? this.weekChecked
        : this.checkedTorneios;

    this.getCountSelected();

    if(this.groupByHour){
      this.rowsByHour = {};
      var atachTo = null;
      for(let i = 0; i < this.tournaments.length; i++) {
        const tor = this.tournaments[i];

        const start = new Date(tor.start);
        const hour = start.getHours();

        if(i == 0) {
          this.rowsByHour[tor.id] = {
            hour: hour,
            rows: 1
          }
          atachTo = tor.id;
        } else {
          const prevStart = new Date(this.tournaments[i-1].start);
          const prevHour = prevStart.getHours();

          if(prevHour == hour) {
            this.rowsByHour[atachTo].rows += 1;
          } else {
            atachTo = tor.id;
            this.rowsByHour[tor.id] = {
              hour: hour,
              rows: 1
            }
          }
        }
      }
    }
  }

  public redirectToCalendar(tournament: Tournament): void {
   const url = generateEventCalendar(tournament,this.sitesMap)
   window.open(url,'_blank')
  }

  async openLobby(e: MouseEvent, row: Tournament) {
    e.stopPropagation()
    if(Native.IsNative() && this.siteCanOpenLobby.has(Number(row['site']))) {
      const siteName = this.sitesMap[row['site']]?.name || '';
      const id: number = row['tournament_id'] || row['id'] || 0
      const t: ProtoTournament = {
        iD: id,
        nativeID: row.native_id || '',
        currency: row.currency,
        siteID: Number(row.site),
        lateRegister: row.late_register,
        game: TournamentGame.TournamentGame_Unknown,
        buyIn: row.buy_in,
        rake: row.rake,
        name: row.name,
        speed: row.speed,
        type: row.type,
        players: row.players,
        variant: row.variant,
        state: row.state,
        blind: row.blind,
        start: Timestamp.fromDate(new Date(row.start)),
        prizePool: row.prize_pool,
        overlay: 0,
        averageDuration: 9,
        initialStack: 9,
        currentBlind: 0,
        field: 0,
        color: 0,
      };
      await NativeLobbyOpener.openLobby(t, siteName);
    } else if(row['can_open_lobby']) {
      const id = row['tournament_id'] || row['id']
      this.notificationService.openLobby(id)
    }
    this.copyText(row['name'])
    return false
  }

  getHourGroupHeight(height: string) {
    const heightInNumber = Number(height.split('px')[0]);

    return (heightInNumber - 57.28).toString() + 'px';
  }

  scroll = () => {
    if(this.groupByHour) {
      let tableBody = this.elem.nativeElement.querySelectorAll('.datatable-body')[0];

      this.divToScroll.nativeElement.scrollTop = tableBody.scrollTop;
    }
  };

  getRowHeight(): number {
    if(localStorage.getItem('row-height')) {
      return Number(localStorage.getItem('row-height'));
    } else {
      if(this.tableMode == 'grind') {
        return 40;
      } else {
        return 46;
      }
    }
  }

  getFontSize(): string {
    if(localStorage.getItem('font-size')) {
      return localStorage.getItem('font-size');
    } else {
      if(this.tableMode == 'grind') {
        return "12px";
      } else {
        return "13px";
      }
    }
  }

  getFilterBadgeText(filter: any, isTooltip: boolean = false) {
    var filtersToIgnore = ['order', 'timezone', 'order_state', 'name', 'onlyNew', 'order_av_ability', 'order_buy_in', 'order_duration', 'order_field', 'order_game', 'order_grindstate', 'order_name', 'order_prize_pool', 'order_priority', 'order_register_time', 'order_site', 'order_speed', 'order_start', 'from_duration', 'to_duration', 'limit', 'id']

    if(window.location.pathname != "/rebalance" || isTooltip) {
      filtersToIgnore.push('auto_only', 'manual_only', 'deleted_filter_only', 'unchecked_filter_only');
    }

    var filtersBoolean = ['maxLate', 'only_favorites', 'show_exception', 'onlyFast', 'show_disabled', 'include_past']

    var i18n = {
      week_day: "day_of_the_week",
      av_ability: "max_ability",
      site: "site",
      game: "game",
      game2: "game",
      buy_in_min: "buy_in_min",
      buy_in_max: "buy_in_max",
      speed: "speed",
      type: "type",
      players: "players",
      variant: "variant",
      state: "state",
      prize_pool_min: "prize_pool_min",
      prize_pool_max: "prize_pool_max",
      init_stacks_min: "init_stacks_min",
      init_stacks_max: "init_stacks_max",
      from_date: "start",
      to_date: "to_date",
      from_time: "from_time",
      to_time: "to_time",
      from_datetime: "from_time",
      to_datetime: "to_time",
      search: "search_by_name",
      scheduled: "scheduled",
      maxLate: "only_max_late_tournaments",
      field: "max_participants",
      priority: "priority",
      include_closed: "include_closed",
      onlyFast: "only_fast",
      from_register_time: "from_registration_time",
      to_register_time: "to",
      exclude: "exclude",
      flagged_only: "flagged_only",
      auto_scheduled_only: "auto_scheduled",
      show_exception: "show_exception",
      category: "category",
      only_favorites: "favorite_schedules",
      currency: "currency",
      min_participants: "min_participants",
      from_end_time: "end_time",
      to_end_time: "end_time",
      show_disabled: "only_disabled_tournaments",
      include_past: "include_past_tournaments",
    }

    if(!filter.value || filtersToIgnore.includes(filter.key)) {
      return null;
    }

    const key = filter.key;

    if(filter.value.constructor === Array) {
      if (filter.value.length > 0) {
        const values = filter.value.map((val) => {
          return val.item_text ?? val
        })
        return this.translateService.instant(i18n[key]) + ": " + values.join(', ');
      } else {
        return null;
      }
    } else if (key == 'search' || key == 'exclude') {
      const values = filter.value.split('|');
      return this.translateService.instant(i18n[key]) + ": " + values.join(', ');
    } else if (filtersBoolean.includes(key)){
      return this.translateService.instant(i18n[key]);
    } else if (key == 'from_date' || key == 'to_date') {
      return this.translateService.instant(i18n[key]) + ": " + filter.value.split('T')[0];
    } else if (filter.value.toString() == 'true' || filter.value.toString() == 'false') {
      return this.translateService.instant(i18n[key]) + ": " + this.translateService.instant('filter_' + filter.value.toString());
    } else if (i18n[key]) {
      return this.translateService.instant(i18n[key]) + ": " + filter.value;
    } else {
      return key.toString() + ": " + filter.value;
    }
  }

  isFilterDifferent(tor, savedFilter) {
    const filter = savedFilter ? savedFilter.data : null;
    const currency = tor.currency;
    var differences = {
      buy_in_min: false,
      buy_in_max: false,
      prize_pool_min: false,
      prize_pool_max: false,
      players: false,
      speed: false,
      variant: false,
      from_time: false,
      to_time: false,
      min_participants: false,
      field: false,
      exclude: false
    }

    if(filter) {

      //BUY-IN
      var buyInC = (Number(tor.buy_in) / Number(this.currencyRates[currency])).toFixed(2);
      if(filter.buy_in_min && Number(buyInC) < Number(filter.buy_in_min)) {
        differences.buy_in_min = true;
      }
      if(filter.buy_in_max && Number(buyInC) > Number(filter.buy_in_max)) {
        differences.buy_in_max = true;
      }

      //PRIZE-POOL
      var prizePoolC = (Number(tor.prize_pool) / Number(this.currencyRates[currency])).toFixed(2);
      if(filter.prize_pool_min && Number(prizePoolC) < Number(filter.prize_pool_min)) {
        differences.prize_pool_min = true;
      }
      if(filter.prize_pool_max && Number(prizePoolC) > Number(filter.prize_pool_max)) {
        differences.prize_pool_max = true;
      }

      //PLAYERS
      var valueToFilter = new Map<string, string>([
        ["10-max", "Full Ring"],
        ["9-max", "Full Ring"],
        ["8-max", "Full Ring"],
        ["7-max", "Full Ring"],
        ["6-max", "6-Max"],
        ["5-max", "3 to 5-Max"],
        ["4-max", "3 to 5-Max"],
        ["3-max", "3 to 5-Max"],
        ["2-max", "2 (Heads Up)"],
        ["1-max", "2 (Heads Up)"]
      ])
      if(filter.players) {
        var hasPlayers = false;
        filter.players.map((e) => {
          if(valueToFilter.get(tor.players) == e.item_id) {
            hasPlayers = true;
          }
        })
        if(!hasPlayers) {
          differences.players = true;
        }
      }

      //SPEED
      if(filter.speed) {
        var hasSpeed = false;
        filter.speed.map((e) => {
          if(tor.speed == e.item_id) {
            hasSpeed = true;
          }
        })
        if(!hasSpeed) {
          differences.speed = true;
        }
      }

      //VARIANT
      if(filter.variant) {
        var hasVariant = false;
        filter.variant.map((e) => {
          if(tor.variant == e.item_id) {
            hasVariant = true;
          }
        })
        if(!hasVariant) {
          differences.variant = true;
        }
      }

      //FROM TIME / TO TIME
      if(filter?.from_time) {
        const filterTime = new Date();
        filterTime.setHours(filter.from_time.split(':')[0], filter.from_time.split(':')[1], 0, 0);

        let dataStart = new Date(tor.start);
        let timeStart = new Date();
        timeStart.setHours(dataStart.getHours(), dataStart.getMinutes(), 0, 0)

        if(timeStart.getTime() < filterTime.getTime()) {
          differences.from_time = true;
        }
      }

      if(filter?.to_time) {
        const filterTime = new Date();
        filterTime.setHours(filter.to_time.split(':')[0], filter.to_time.split(':')[1], 0, 0);

        let dataStart = new Date(tor.start);
        let timeStart = new Date();
        timeStart.setHours(dataStart.getHours(), dataStart.getMinutes(), 0, 0)

        if(timeStart.getTime() > filterTime.getTime()) {
          differences.to_time = true;
        }
      }
        
      //PARTICIPANTS
      if(filter.min_participants) {
        if(Number(tor.field) < Number(filter.min_participants)) {
          differences.min_participants = true;
        }
      }
      if(filter.field) {
        if(Number(tor.field) > Number(filter.field)) {
          differences.field = true;
        }
      }

      //EXCLUDE
      if(filter.exclude) {
        const words = filter.exclude.split("|");
        words.map((e) => {
          if(tor.name.toUpperCase().includes(e.toUpperCase())){
            differences.exclude = true;
          }
        })
      }
    }

    return differences;
  }

  hasTorChanged(newTor, oldTor) {
    if(oldTor) {
      if(oldTor.buy_in) {
        if(newTor.buy_in != oldTor.buy_in) {
          return true;
        }
      }
      if (oldTor.prize_pool) {
        if(newTor.prize_pool != oldTor.prize_pool) {
          return true;
        }
      } 
      if (oldTor.speed) {
        if(newTor.speed != oldTor.speed) {
          return true;
        }
      } 
      if (oldTor.variant) {
        if(newTor.variant != oldTor.variant) {
          return true;
        }
      } 
      if (oldTor.start && oldTor.start != "0001-01-01T00:00:00Z") {
        if(this.isTimeDifferent(newTor.start, oldTor.start)) {
          return true;
        }
      } 
      if (oldTor.field) {
        if(newTor.field != oldTor.field) {
          return true;
        }
      }
      if (oldTor.name) {
        if(newTor.name != oldTor.name) {
          return true;
        }
      } 
    }
    return false;
  }

  isTimeDifferent(a, b) {
    const timeA = new Date(a);
    const timeB = new Date(b);

    return ((timeA.getHours().toString() + timeA.getMinutes().toString()) != (timeB.getHours().toString() + timeB.getMinutes().toString()))
  }

  getRebalanceCause(tor): String {
    const oldTournament = tor["old_tournament"]
    const filterID = tor["scheduled_filter_id"]
    const filter = this.savedFilters[filterID]

    if(this.isTorNull(oldTournament)) {
      if(filterID) {
        if(filter?.auto_schedule === false) {
          return this.translateService.instant("filter_unchecked");
        }
        return this.translateService.instant("filter_changed");
      } else {
        return "";
      }
    } else {
      if (this.hasTorChanged(tor, tor.old_tournament)) {
        if(!filter?.deleted) {
          var differencesNew = this.isFilterDifferent(tor, filter);
          var differencesOld = this.isFilterDifferent(oldTournament, filter);
          if((Object.values(differencesNew).some(val => val === true)) && (Object.values(differencesOld).some(val => val === true))) {
            return this.translateService.instant("filter_changed");
          } else if (filter?.auto_schedule === false) {
            return this.translateService.instant("filter_unchecked");
          } else {
            return this.translateService.instant("tournament_changed");
          }
        } else {
          if(filter?.auto_schedule === false) {
            return this.translateService.instant("filter_unchecked");
          }
          return this.translateService.instant("deleted_filter");
        }
      } else {
        if(filter?.deleted) {
          return this.translateService.instant("deleted_filter");
        } else if (filter?.auto_schedule === false) {
          return this.translateService.instant("filter_unchecked");
        } else {
          return this.translateService.instant("filter_changed");
        }
      }
    }
  }

  getRowName(tour): string {
    const brand = tour['brand'];
    if (brand && this.isAdmin) {
      return `[${brand}] ${tour['name']}`;
    }
    return tour['name'];
  }

  ngOnDestroy(): void {
    document.removeEventListener('scroll', this.scroll);
    this.subs.unsubscribe();
  }
}
