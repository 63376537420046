import { Site } from "../interfaces/sites";
import { Tournament } from "../interfaces/tournament";
import { DateTime } from "luxon";

export namespace tournament {

    export function isOld(tour: Tournament): boolean {
        return tour.oldness == -1
    }

    export function isNew(tour: Tournament): boolean {
        if (tour.oldness != 1) {
            return false;
        }
        const date = new Date(tour.firstUpload)
        const now = new Date().getTime()
        const diff = now - date.getTime()

        const days = diff / (1000 * 60 * 60 * 24)

        return days <= 7
    }

    export function getPartyPokerLateReg(tour: Tournament): number {
      let { late_register } = tour
      const time = Math.floor(late_register / 60); //DIVIDE BY 60 TO FIND OUT HOW MANY TIMES SHOULD DISCOUNT 5 MINUTES 
    
      for(var count: number = 0; count < time; count++) {
        late_register = late_register - 5;
      }

      return late_register;
    }

    export function getState(tour: Tournament, addBreaks = true, onlyMinutes = false): any {
        const nowDate = new Date();
        const startDate = new Date(tour.start);
        const now = new Date().getTime()
        const start = new Date(tour.start).getTime()
        let discount = 0;

        if(now < start && !onlyMinutes) {
          return 'Announced'
        }

        if(((nowDate.getFullYear() - startDate.getFullYear()) > 27) && !onlyMinutes) {
          return 'Disabled'
        }

        if(tour.site == '3' && addBreaks) { //CHECK IF PARTYPOKER

          if (nowDate.getMinutes() >= 55) {
            discount = (nowDate.getMinutes() - 55) * 60000
          } else {
            const starth = new Date(tour.start)
            const start_minutes = starth.getMinutes();

            var differenceValue = (now - start) / 1000;
            differenceValue /= 60;
            const late_reg_passed = differenceValue
            
            if(late_reg_passed >= 0) {

              const minutes_from_start = start_minutes + late_reg_passed

              const time = Math.floor(minutes_from_start / 60); //DIVIDE BY 60 TO FIND OUT HOW MANY TIMES SHOULD DISCOUNT 5 MINUTES 
      
              for(var count: number = 0; count < time; count++) {
                discount += 300000;
              }
            
            }
            
          }
        }

        const minutes = (start - now + discount) / 1000 / 60
        const minLate = minutes + tour.late_register

        if(onlyMinutes) {
          return minLate;
        } else {
          if(minLate < 0) {
            return 'Late Reg. Closed'
          }

          if (minLate < 5){
            const lateRegisterDate = DateTime.fromISO(tour.start).plus({
              minute: tour.late_register + (discount / 60000),
            });
            const { minutes, seconds } = lateRegisterDate
              .diffNow([ "minute", "seconds"])
              .toObject();

            return `Late Reg. ${Math.trunc(
              minutes
            )
              .toFixed()
              .padStart(2, "0")}:${Math.trunc(seconds).toFixed().padStart(2, "0")}`;
          }

          return `Late Reg. ${Math.floor(minLate)} min`
        }
      }

    export function formatMoney(money: number) {
      if (money < 1e3) {
        return `${money}`
      } else if (money < 1e6) {
        const r = (money / 1e3).toFixed(2)
        return `${+r}`.replace('.', ',') + 'K'
      } else {
        const r = (money / 1e6).toFixed(2)
        return `${+r}`.replace('.', ',') + 'Mi'
      }
    }

    export function styleSiteBackground(sitesMap: { [key: number]: Site }, siteID: number) {
      const site = sitesMap[siteID]?.name || ''

      switch (site) {
        case "PokerStars.ES":
          return 'site-pokerstarts-es';
        case "PokerStars":
          return 'site-pokerstars';
        case "888poker.PT":
          return 'site-888poker-pt';
        case "GG Network":
          return 'site-gg-network';
        case "PartyPoker":
          return 'site-party-poker';
        case "WPN Network":
          return 'site-wpn-network';
        case "Bodog":
          return 'site-bodog';
        case "Winamax":
          return 'site-winamax';
        case "iPoker":
          return 'site-ipoker';
        case "Chico Network":
          return 'site-chico';
        case "888poker":
          return 'site-888poker';
        case "WPT Global":
          return "site-wpt-global";
        case "Suprema":
          return "site-suprema";
        case "Coin Poker":
          return "site-coin-poker";
        default:
          return "";
      }
    }

    export function priorityName(value: number): string {
      switch (value) {
        case 0:
          return "N/A";
        case 1:
          return "VERY LOW";
        case 2:
          return "VERY LOW";
        case 3:
          return "LOW";
        case 4:
          return "LOW";
        case 5:
          return "MEDIUM";
        case 6:
          return "MEDIUM";
        case 7:
          return "MEDIUM";
        case 8:
          return "HIGH";
        case 9:
          return "HIGH";
        case 10:
          return "HIGH";
      }
      return "N/A";
    }

    export function priorityColor(value: number): string {
      switch (value) {
        case 0:
          return "unset";
        case 1:
          return "#00b300";
        case 2:
          return "#008000";
        case 3:
          return "#4d4dff";
        case 4:
          return "#0000ff";
        case 5:
          return "#ffaf1a";
        case 6:
          return "#e69500";
        case 7:
          return "#ff6600";
        case 8:
          return "#ff4700";
        case 9:
          return "#ff1f00";
        case 10:
          return "#ff0000";
      }
      return "unset";
    }

}
