import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { FilterTournamentsComponent } from "./filter-tournaments.component";
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";

import { TooltipModule } from "ngx-bootstrap/tooltip";

import { NgxSpinnerModule } from "ngx-spinner";

// Dropdowns Component
import { BsDropdownModule } from "ngx-bootstrap/dropdown";

import { ModalModule } from "ngx-bootstrap/modal";

import { SharedModule } from "../../app/shared/shared.module";

import { NgxSliderModule } from '@angular-slider/ngx-slider';

@NgModule({
  declarations: [FilterTournamentsComponent],
  exports: [FilterTournamentsComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    BsDropdownModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    //HttpClientModule,
    NgxSpinnerModule,
    NgSelectModule,
    NgxDatatableModule,
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    SharedModule,
    AngularMyDatePickerModule,
    NgxSliderModule
  ]
})
export class FilterTournamentsModule {}
