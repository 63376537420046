import { Profile } from "./v2/user"

interface interval {
    item_id: {min: number, max: number}
    item_text: string
}
interface single {
    item_id: string
    item_text: string
}

export interface Filter {
    id?: number
    name?: string
    site: single[]
    game2: number[]
    buy_in_min: string
    buy_in_max: string
    speed: single[]
    type: single[]
    players: single[]
    variant: single[]
    state: single[]
    prize_pool_min: number
    prize_pool_max: number
    from_date: string
    to_date: string
    from_time: string
    to_time: string
    search: string
    order: {},
    onlyNew?: boolean,
    scheduled: boolean,
    limit: number | null,
    timezone: number | null,
    exclude?: string,
    include_past?: boolean,
    show_disabled?: boolean,
    flagged_only?: boolean,
    auto_scheduled_only?: boolean,
    order_start?: number,
    order_site?: number,
    order_game?: number,
    order_buy_in?: number,
    order_name?: number,
    order_prize_pool?: number,
    order_speed?: number,
    order_state?: number,
    order_av_ability?: number,
    order_field?: number,
    order_duration?: number,
    order_register_time?: number,
    order_grindstate?: number,
    order_priority?: number,
    category?: number[],
    av_ability?: number,
    currency?: single[],
    min_participants?: number,
    field?: number,
    week_day?: number[],
    from_end_time?: string,
    to_end_time?: string,
    from_duration?: number,
    to_duration?: number,
    include_closed?: boolean,
    auto_only?: boolean,
    manual_only?: boolean,
    deleted_filter_only?: boolean,
    unchecked_filter_only?: boolean,
}
export interface User {
    id: number
    team: string
    tier_id?: number;
    role: string
    schedules: {
        id: number,
        notification: any
        tournament_id: number,
        name: string,
        auto_scheduled?: boolean
    }[],
    profile: Profile,
    has_pending_requests?: boolean,
    user_count?: number
}

export interface UserData {
    id: number,
    name: string,
    email: string,
    is_verified: boolean,
    exp: number,
    iat: number,
    iss: string,
}

export type CompleteUser = User & UserData

export interface UserLicense {
    license: string,
    slots: number,
    expiration: string,
    self: boolean,
}

export interface UserSubscription {
    has: boolean,
    license_type: string,
    expiration: string,
    quantity: number,
}
