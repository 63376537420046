import { invoke } from "@tauri-apps/api/core";
import { UnlistenFn } from "@tauri-apps/api/event";
import { Notification } from "../proto/lobbyze/realtime/data/notification";

export namespace NativeNotification {
  export type Entry = Notification;

  export async function listenNotification(
    callback: (notification: Entry) => void
  ): Promise<UnlistenFn> {
    let interval = setInterval(async () => {
      try {
        const res = await invoke<number[][]>("notification_get_displayable");
        for (const array of res) {
          const buf = new Uint8Array(array);
          const entry = Notification.fromBinary(buf);
          callback(entry);
        }
      } catch (e) {
        console.error("Error querying displayable notifications", e);
      }
    }, 1000);
    return () => clearInterval(interval);
  }

  export function addDemoNotification(): Promise<void> {
    return invoke("notification_scheduler_enqueue_mock_entry");
  }

  export function showNotificationWindow(): Promise<void> {
    return invoke("notification_show");
  }

  export function createNotificationWindow() {
    return invoke("notification_create_window");
  }

  export async function getNotificationQueue(): Promise<Notification[]> {
    const result = [];
    const res = await invoke<number[][]>("notification_get_queue");
    for (const array of res) {
      const buf = new Uint8Array(array);
      const entry = Notification.fromBinary(buf);
      result.push(entry);
    }
    return result;
  }
}
