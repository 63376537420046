import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments';
import { Schedule } from '../interfaces/schedule';

@Injectable({
  providedIn: 'root'
})
export class TournamentService {
  baseURL = environment.API_URL + "tournament/"
  baseURLv2 = environment.API_URL + "v2/tournament/"
  baseUpLoadURL = environment.API_URL + "import-excel/"
  baseUploadURLv2 = environment.API_URL + "v2/tour_bus"
  baseEmbedURL = environment.API_URL + "api/tournaments/"

  constructor(private http: HttpClient) { }

  createTournament(body): Observable<any> {
    return this.http.post<any>(this.baseURL + "create-tournament", body)
  }

  getTestTabletoProduction(): Observable<any[]>{
    return this.http.get<any[]>(this.baseURL + "copy-to-test");
  }

  getTournamentFilter(body, pag: number, include_owner: boolean = false): Observable<any> {
    return this.http.post<any>(this.baseURL + "get-tournament-filter/" + pag, { 
      ...body,
      timezone: new Date().getTimezoneOffset(),
    }, 
    {
      params: {
        include_owner: include_owner.toString()
      }
    })
  }

  updateTournament(id, body): Observable<any[]> {
    return this.http.put<any[]>(this.baseURL + `update-tournament/${id}/main`, body)
  }

  deleteAddTournament(id): Observable<any> {
    return this.http.delete<any>(this.baseURL + `delete-tournament/${id}/main`)
  }

  updateTournaments(id, body, custom): Observable<any> {
    return this.http.put<any>(this.baseURL + "update-tournament/" + id, body, {
      params: {
        custom: custom
      }
    })
  }

  toggleTournaments(body): Observable<any> {
    return this.http.put<any>(this.baseURL + "manage-tournaments", body)
  }

  updateUserLogin(id: number): Observable<any> {
    return this.http.post<any>(this.baseURL + "update-user-login", { id: id })
  }

  postFile(fileToUpload: File, checkUpload: number): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('uploadfile', fileToUpload, fileToUpload.name)
    console.info("formData:", formData)
    return this.http.post<any>(this.baseUpLoadURL + `/${checkUpload}/main`, formData)
  }

  postFile2(fileToUpload: File) {
    const formData: FormData = new FormData();
    formData.append('uploadfile', fileToUpload, fileToUpload.name)
    console.info("formData:", formData)
    return this.http.post<{
      msg: string,
      file: string,
      found: number,
      new: number,
    }>(this.baseUploadURLv2 + `/excel_upload`, formData).toPromise()
  }

  listOnTimeTournaments(): Observable<Schedule[]> {
    return this.http.get<Schedule[]>(this.baseURLv2 + "list-on-time")
  }

  addBlinds(body: any): Observable<any> {
    return this.http.post<any>(this.baseURLv2 + "blind", body)
  }

  listBlinds(
    name: string,
    site: number,
    speed: number,
    page: number,
    no_paging: boolean = false
  ): Observable<any> {
    const params: { [key: string]: string } = {
      name: name != "" ? name : undefined,
      site: !isNaN(site) ? site > 0 ? site.toString() : undefined : undefined,
      speed: !isNaN(speed) ? speed > 0 ? speed.toString() : undefined : undefined,
      page: !isNaN(page) ? page.toString() : undefined,
      no_paging: no_paging.toString()
    };

    Object.keys(params).forEach(
      (key) => params[key] === undefined && delete params[key]
    );

    return this.http.get(this.baseURLv2 + "blind", {
      params,
    })
  }

  getBlindByIds(Ids: number[]): Observable<any> {
    return this.http.post(this.baseURLv2 + "blind/list", { tournaments: Ids })
  }

  updateBlinds(id: number, body: any): Observable<any> {
    return this.http.put<any>(this.baseURLv2 + "blind/" + id, body)
  }

  deleteBlinds(id: number): Observable<any> {
    return this.http.delete<any>(this.baseURLv2 + "blind/" + id)
  }

  increaseDecreaseHour(body: any): Observable<any> {
    return this.http.put<any>(this.baseURL + "edit-tools/offset-time", body)
  }

  getEmbeddedTournaments(id: string): Observable<any> {
    return this.http.get<any>(this.baseEmbedURL + "embedded/" + id)
  }

  getTournamentReportUrl() {
    return this.baseUploadURLv2 + "/tournament_report";
  }

}
