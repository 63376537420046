// @generated by protobuf-ts 2.9.4 with parameter long_type_number,output_javascript_es2020
// @generated from protobuf file "lobbyze/realtime/data/notification.proto" (package "lobbyze.realtime.data", syntax proto3)
// tslint:disable
import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { ScheduleEntry } from "../../tournament";
import { Timestamp } from "../../../google/protobuf/timestamp";
/**
 * @generated from protobuf enum lobbyze.realtime.data.NotificationType
 */
export var NotificationType;
(function (NotificationType) {
    /**
     * @generated from protobuf enum value: NotificationType_None = 0;
     */
    NotificationType[NotificationType["NotificationType_None"] = 0] = "NotificationType_None";
    /**
     * @generated from protobuf enum value: NotificationType_WhenStart = 1;
     */
    NotificationType[NotificationType["NotificationType_WhenStart"] = 1] = "NotificationType_WhenStart";
    /**
     * @generated from protobuf enum value: NotificationType_BeforeStart = 2;
     */
    NotificationType[NotificationType["NotificationType_BeforeStart"] = 2] = "NotificationType_BeforeStart";
    /**
     * @generated from protobuf enum value: NotificationType_AfterStart = 3;
     */
    NotificationType[NotificationType["NotificationType_AfterStart"] = 3] = "NotificationType_AfterStart";
    /**
     * @generated from protobuf enum value: NotificationType_BeforeRegister = 4;
     */
    NotificationType[NotificationType["NotificationType_BeforeRegister"] = 4] = "NotificationType_BeforeRegister";
    /**
     * @generated from protobuf enum value: NotificationType_At = 5;
     */
    NotificationType[NotificationType["NotificationType_At"] = 5] = "NotificationType_At";
    /**
     * @generated from protobuf enum value: NotificationType_AtBlind = 6;
     */
    NotificationType[NotificationType["NotificationType_AtBlind"] = 6] = "NotificationType_AtBlind";
})(NotificationType || (NotificationType = {}));
// @generated message type with reflection information, may provide speed optimized methods
class NotificationAction$Type extends MessageType {
    constructor() {
        super("lobbyze.realtime.data.NotificationAction", [
            { no: 1, name: "Type", kind: "scalar", jsonName: "Type", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Value", kind: "scalar", jsonName: "Value", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = globalThis.Object.create((this.messagePrototype));
        message.type = "";
        message.value = "";
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Type = 1 [json_name = "Type"];*/ 1:
                    message.type = reader.string();
                    break;
                case /* string Value = 2 [json_name = "Value"];*/ 2:
                    message.value = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string Type = 1 [json_name = "Type"]; */
        if (message.type !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.type);
        /* string Value = 2 [json_name = "Value"]; */
        if (message.value !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.value);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message lobbyze.realtime.data.NotificationAction
 */
export const NotificationAction = new NotificationAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Notification$Type extends MessageType {
    constructor() {
        super("lobbyze.realtime.data.Notification", [
            { no: 1, name: "ID", kind: "scalar", jsonName: "ID", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "UserID", kind: "scalar", jsonName: "UserID", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "Time", kind: "message", jsonName: "Time", T: () => Timestamp },
            { no: 4, name: "Schedule", kind: "message", jsonName: "Schedule", T: () => ScheduleEntry },
            { no: 5, name: "Type", kind: "enum", jsonName: "Type", T: () => ["lobbyze.realtime.data.NotificationType", NotificationType] },
            { no: 6, name: "TypeModifier", kind: "scalar", jsonName: "TypeModifier", T: 2 /*ScalarType.FLOAT*/ },
            { no: 7, name: "Actions", kind: "message", jsonName: "Actions", repeat: 1 /*RepeatType.PACKED*/, T: () => NotificationAction }
        ]);
    }
    create(value) {
        const message = globalThis.Object.create((this.messagePrototype));
        message.iD = 0;
        message.userID = 0;
        message.type = 0;
        message.typeModifier = 0;
        message.actions = [];
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 ID = 1 [json_name = "ID"];*/ 1:
                    message.iD = reader.uint32();
                    break;
                case /* uint32 UserID = 2 [json_name = "UserID"];*/ 2:
                    message.userID = reader.uint32();
                    break;
                case /* google.protobuf.Timestamp Time = 3 [json_name = "Time"];*/ 3:
                    message.time = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.time);
                    break;
                case /* lobbyze.ScheduleEntry Schedule = 4 [json_name = "Schedule"];*/ 4:
                    message.schedule = ScheduleEntry.internalBinaryRead(reader, reader.uint32(), options, message.schedule);
                    break;
                case /* lobbyze.realtime.data.NotificationType Type = 5 [json_name = "Type"];*/ 5:
                    message.type = reader.int32();
                    break;
                case /* float TypeModifier = 6 [json_name = "TypeModifier"];*/ 6:
                    message.typeModifier = reader.float();
                    break;
                case /* repeated lobbyze.realtime.data.NotificationAction Actions = 7 [json_name = "Actions"];*/ 7:
                    message.actions.push(NotificationAction.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* uint32 ID = 1 [json_name = "ID"]; */
        if (message.iD !== 0)
            writer.tag(1, WireType.Varint).uint32(message.iD);
        /* uint32 UserID = 2 [json_name = "UserID"]; */
        if (message.userID !== 0)
            writer.tag(2, WireType.Varint).uint32(message.userID);
        /* google.protobuf.Timestamp Time = 3 [json_name = "Time"]; */
        if (message.time)
            Timestamp.internalBinaryWrite(message.time, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* lobbyze.ScheduleEntry Schedule = 4 [json_name = "Schedule"]; */
        if (message.schedule)
            ScheduleEntry.internalBinaryWrite(message.schedule, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* lobbyze.realtime.data.NotificationType Type = 5 [json_name = "Type"]; */
        if (message.type !== 0)
            writer.tag(5, WireType.Varint).int32(message.type);
        /* float TypeModifier = 6 [json_name = "TypeModifier"]; */
        if (message.typeModifier !== 0)
            writer.tag(6, WireType.Bit32).float(message.typeModifier);
        /* repeated lobbyze.realtime.data.NotificationAction Actions = 7 [json_name = "Actions"]; */
        for (let i = 0; i < message.actions.length; i++)
            NotificationAction.internalBinaryWrite(message.actions[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message lobbyze.realtime.data.Notification
 */
export const Notification = new Notification$Type();
