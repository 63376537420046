import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { ListTournamentsComponent } from "./list-tournaments.component";
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";

import { TooltipModule } from "ngx-bootstrap/tooltip";

import { NgxSpinnerModule } from "ngx-spinner";

// Dropdowns Component
import { BsDropdownModule } from "ngx-bootstrap/dropdown";

import { ModalModule } from "ngx-bootstrap/modal";

import { SharedModule } from "../../app/shared/shared.module";

@NgModule({
  declarations: [ListTournamentsComponent],
  exports: [ListTournamentsComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    BsDropdownModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    //HttpClientModule,
    NgxSpinnerModule,
    NgxDatatableModule,
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    SharedModule,
    AngularMyDatePickerModule
  ]
})
export class ListTournamentsModule {}
