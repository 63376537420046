import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BugReportComponent } from "./bug-report.component";
import { TranslateModule } from "@ngx-translate/core";
import { ModalModule } from "ngx-bootstrap/modal";
import { FormsModule } from "@angular/forms";
import { MailService } from "../../services/mail.service";

@NgModule({
  declarations: [BugReportComponent],
  exports: [BugReportComponent],
  imports: [CommonModule, TranslateModule, ModalModule.forRoot(), FormsModule],
})
export class BugReportModule {}
