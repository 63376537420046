export const environment = {
  production: false,
  API_URL: "https://testapi.lobbyze.com/",
  BASE_COOKIE: ".lobbyze.com",
  VAPID_PUBLIC:
    "BAf87U2qOzD9ebuQoWARrcuIekdF2CFXOs9Pgpj6fFeDTH-mh9rVRlU3z6PScb5UOBP7g1jxSz0hr8mx_ZUoDAA",
  FEATURES_STATE: 'check',
  POSTHOG_KEY: "phc_pqsULBg5XuCIttIACcbsLxFMWWGUnb57lGp3pu0F1yU",
  POSTHOG_HOST: "https://phrp.lobbyze.com",
};
