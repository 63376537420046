import { invoke } from "@tauri-apps/api/core";
import { Tournament } from "../proto/lobbyze/tournament";

export namespace NativeLobbyOpener {
  export async function openLobby(
    tourney: Tournament,
    siteName: string
  ): Promise<boolean> {
    return await invoke("lobby_opener_open", {
      tourneyBinary: Tournament.toBinary(tourney),
      siteName,
    });
  }

  export async function availableLobbies(): Promise<string[]> {
    return await invoke("lobby_get_sites");
  }
}
