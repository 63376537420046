import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd, Route, ActivatedRoute } from "@angular/router";

import { AuthService } from "./services/auth.service";
/* import { SwPush } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { HttpClient } from "@angular/common/http";
import { UserService } from './services/user.service';
import { NotificationService } from './services/notification.service';
import { InfoService } from './services/info.service'; */
import { Unsubscribable } from "rxjs";
// import { take } from "rxjs/operators";
// import { UpdateService } from "./services/update.service";
import { LicenseService } from "./services/license.service";
import { TranslateService } from '@ngx-translate/core';
import { UserService } from "./services/user.service";
import { environment } from "../environments";
import Cookies from "./utils/cookies";
import posthog from "posthog-js";
import { Native } from "../native";
import { NativeNotification } from "../native/notification";

declare let gtag: Function;

@Component({
  selector: "body",
  template: "<ngx-spinner></ngx-spinner><router-outlet ></router-outlet>",
})

export class AppComponent implements OnInit {
  public userEmail = "";
  message: string;

  private subs: Unsubscribable[] = []

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private auth: AuthService,
    private userService: UserService,
    private licenseService: LicenseService,
    private translate: TranslateService,
  ) {
    if (Native.IsNative()) {
      NativeNotification.createNotificationWindow();
    }
  }

  async ngOnInit() {
    this.setupCookies()

    this.translate.addLangs(['en-US', 'pt-BR', 'es', 'ru']);
    this.translate.setDefaultLang('en-US');

    this.translate.use(Cookies.get('lang') ? Cookies.get('lang') : 'en-US');

    // this.registerPush()

    const s = this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      posthog.capture('$pageview');
      window.scrollTo(0, 0);
    });
    this.subs.push(s);
  }

/*   async registerPush() {
    try {
      const vapid = await this.infoService.getVapid().toPromise();
      const sub = await this.swPush.requestSubscription({
        serverPublicKey: vapid.key
      })
      const result = await this.notifications.subscribe(sub).toPromise()
      console.log(`Push subscription: ${result.statusText}`)
      this.notifications.getRegistrationStatus().next({
        enabled: result.status == 200
      })
    } catch(err) {
      this.notifications.getRegistrationStatus().next({
        enabled: false,
        err: typeof err == 'string' ? err : (err.message || JSON.stringify(err)),
      })
      console.error("Could not subscribe to notifications", err)
      if(err && err.code == 11) {
        console.log("Registering push again")
        await this.swPush.unsubscribe()
        await this.registerPush()
      }
    }
  } */

  setupCookies() {
    Cookies.set('timezone', (-new Date().getTimezoneOffset()).toString())

    const s = this.activatedRoute.queryParams.subscribe(e => {
      const tier = e['tier']
      if(tier) {
        Cookies.set('tier', tier)
      }
    })
    this.subs.push(s)
  }

  ngOnDestroy(): void {
    for(const s of this.subs) {
      s.unsubscribe()
    }
  }
}
