import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgxSpinnerModule } from "ngx-spinner"
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FilterTournamentsModule } from "../../app/../components/filter-tournaments/filter-tournaments.module";
import { ListTournamentsModule } from "../../app/../components/list-tournaments/list-tournaments.module";
import { NgxMaskModule, IConfig } from 'ngx-mask'

import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { MainGuideComponent } from './main-guide-modal.component';

import { ModalModule } from 'ngx-bootstrap/modal';
import { SharedModule } from "../../app/shared/shared.module";

@NgModule({
  declarations: [MainGuideComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgMultiSelectDropDownModule.forRoot(),
    TooltipModule.forRoot(),
    TimepickerModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule,
    NgxMaskModule.forRoot(),
    FilterTournamentsModule,
    ListTournamentsModule,
    NgxSpinnerModule,
    SharedModule
  ],
  exports: [MainGuideComponent]
})

export class MainGuideModule { }