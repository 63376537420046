import { CurrencyMaskDirective } from "./../directives/currency-mask.directive";
import { TranslateModule } from "@ngx-translate/core";
import { GetRandomBannerModule } from "../../components/get-random-banner/get-random-banner.module";
import { NgModule } from "@angular/core";
import { BugReportModule } from "./bug-report/bug-report.module";
import { TrustHtmlPipe } from "../pipe/trust-html.pipe";
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { RequestTournametComponent } from "./request-tournament/request-tournament.component";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";

@NgModule({
  imports: [
    BugReportModule,
    FormsModule,
    CommonModule,
    TranslateModule,
    AngularMyDatePickerModule,
    BsDropdownModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
  ],
  exports: [
    CurrencyMaskDirective,
    TranslateModule,
    GetRandomBannerModule,
    BugReportModule,
    TrustHtmlPipe,
    RequestTournametComponent,
  ],
  declarations: [
    CurrencyMaskDirective,
    TrustHtmlPipe,
    RequestTournametComponent,
  ],
  providers: [],
})
export class SharedModule {}
